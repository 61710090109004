import React from "react";
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import {Alert} from 'antd';
import Spin from 'antd/es/spin';

import {MailOutlined, LockOutlined} from '@ant-design/icons'
import 'pages/public/public.less'

import {service, userAuthActions} from './index'
import {useDispatch} from "react-redux";
import {Link, Redirect} from 'react-router-dom';
import {userPaths} from './routes'
import {PublicHeader} from './Header'
import {actionFromProps, fromLocation} from './utils'
import {getValidToken} from 'api/token'

import {createStatusSelectors} from 'api/redux/selectors'

const title = 'Sign In'
const home = {from: {pathname: '/podcasts'}}

const LoginForm = (props) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinish = values => {
        form.setFieldsValue(values);
        if (userAction) {
            dispatch(service.userSignin({...values, ...userAction}))
        }
        else
        {
            dispatch(service.userSignin(values))
        }
    };

    const userAction = actionFromProps(props.location.state)
    const {from} = fromLocation(userAction, props.location.state)


    return (props.success && getValidToken()) ? (
        <Redirect to={from}/>) : (
        <div style={{height: '100vh', backgroundColor: '#315663'}}>
            <PublicHeader title={title}/>
            {<Login>{props}</Login>}
        </div>
    );


    function Login(props) {
        return (
            <Row type='flex' align='top' justify='center' style={{backgroundColor: 'white', minHeight: '60%'}}>
                <Col flex='350px' align={'center'}>

                    <Form className='public' size='large' onFinish={onFinish} form={form}>
                        {props.children.error &&
                        <Alert message={props.children.error} type="error" className={'alert'} closable/>
                        }

                        <Spin size='large' spinning={props.children.isLoading} delay={500}>
                            <Form.Item name='email'
                                       rules={[{required: true, message: 'Please enter a valid email address.'}]}>
                                <Input
                                    className='rounded'
                                    prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="Email"
                                />
                            </Form.Item>
                        </Spin>
                        <Form.Item name='password' rules={[{required: true, message: 'A password is required.'}]}>
                            <Input.Password
                                className='rounded'
                                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item name='signin'>
                            <Button style={{width: '50%', float: 'left'}} type="primary" htmlType="submit">
                                Sign in
                            </Button>
                            <Link to={userPaths.register}
                                  style={{paddingTop: 10, paddingBottom: 30, width: '45%', float: 'right'}}>Or register
                                now!</Link>
                        </Form.Item>
                        <Form.Item name='remember' valuePropName='checked'>
                            <p>Forgot your password? Click <Link to={userPaths.requestPassword}> <u>here</u> </Link> to
                                reset </p>
                        </Form.Item>
                    </Form>
                </Col>

            </Row>
        );
    }
}

export default createStatusSelectors([userAuthActions.userSignin], LoginForm)



