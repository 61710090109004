const home = {from: {pathname: '/home'}}

export function actionFromProps(state){
    if (state?.from?.pathname?.startsWith('/podcasts/claims/token')) {
        let guid = state.from.pathname.split('/').pop()
        return {claims: guid}
    }

    if (state?.from?.pathname?.startsWith('/podcasts/invitations/token')) {
        let guid = state.from.pathname.split('/').pop()
        return {collaborate: guid}
    }
    return null
}

export function fromLocation(action, path) {
    if (action != null) {
        return home
    }
    else {
        return path || home
    }
}
