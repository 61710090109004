import operations from 'api/superAgentWrapper'
import { contentActionTypes } from './episodeState';
import {serverError} from "api/service";
import { storeToken} from 'api/token';

const current = (content) => {
    return async dispatch => {
        dispatch({type: contentActionTypes.currentContent, payload: content} )
    }
};

const edit = (content)  => {
    return async dispatch => {
        dispatch({type: contentActionTypes.contentUpdateSuccess, payload: content} )
    }
}


const fetch = (episode) => {
    return async dispatch => {
        function onSuccess(res) {
            storeToken(res);
            dispatch({type: contentActionTypes.contentFetchSuccess, payload: res.body} )
        }

        function onError(error) {
            console.log ("'We were unable to retrieve your content", error)
            let errorState = serverError(error, 'We were unable to retrieve your content.');
            dispatch({type: contentActionTypes.contentFetchFailure, payload: { message: errorState.message}} )
        }

        dispatch({type: contentActionTypes.contentFetchRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            onSuccess( await operations.get(`${root(episode)}`) )
        } catch (error) {
            onError(error)
        }
    }
};


const update_all = (episode, contents) => {
    return async dispatch => {
        function onSuccess(res) {
            storeToken(res);
            dispatch({type: contentActionTypes.contentSaveSuccess, payload: { message: 'The episode has been saved'}} )
            return 'success'
        }

        function onError(error) {
            console.log ("Error in update_all content", error)
            let message = 'We were unable to save the episode.'
            let errorState = serverError(error, message);           
            dispatch({type: contentActionTypes.contentRequestFailure, payload: { error: errorState.message}} )
            return 'error'
        }

        dispatch({type: contentActionTypes.contentRequestInProgress})

        try {
            return onSuccess(await operations.post(`admin/podcasts/${episode.podcast_id}/vodacast_episodes/${episode.id}/content/update_all`, { contents: contents } ))
        } catch (error) {
            return onError(error)
        }
    }

}


const destroy = (episode, content) => {
    return async dispatch => {
        function onSuccess(res) {
            storeToken(res);
            dispatch({type: contentActionTypes.contentDeleteSuccess, payload: res.body} )
        }

        function onError(error) {
            console.log ("'Error deleting content", error)
            let errorState = serverError(error, 'We were unable to delete the content.');
            dispatch({type: contentActionTypes.contentDeleteFailure, payload: { message: errorState.message}} )
        }

        dispatch({type: contentActionTypes.contentDeleteRequest})
        try {
            return onSuccess( await operations.httpDelete(`${root(episode)}/${content.id}`))
        } catch (error) {
            return onError(error)
        }

    }
}

async function upsert(episode, content) {
    let updating = content.id
    try {
        let res = updating ? await operations.put(`${root(episode)}/${content.id}`, {content: content}) :
            await operations.post(`${root(episode)}`, {content: content})
        storeToken(res);
        let message = updating ? 'The episode has been updated' : 'The episode has been created'
        return {success: true, message: message, content: res.body}
    } catch (error) {
        console.log('Error upserting the content', error)
        let message = updating ? 'We were unable to update the content' : 'We were unable to create the content'
        let errorState = serverError(error, message);
        return {success: false, message: errorState.message}

    }
}



function root(episode) {
     return  `admin/podcasts/${episode.podcast_id}/vodacast_episodes/${episode.id}/content`
}

export default  {
    edit,
    current,
    fetch,
    destroy,
    upsert,
    update_all
}
