import operations from 'api/superAgentWrapper'
import { podcastCollaborateActionTypes } from './collaborateState';
import {serverError} from "api/service";
import { storeToken} from 'api/token';

const reset = () => {
    return async dispatch => {
        dispatch({type: podcastCollaborateActionTypes.collaborateReset})
    }
}

async function invite (invitation)  {
        try {
            const res = await operations.post('admin/podcasts/invite', {podcast_id: invitation.podcast_id, email: invitation.email, name: invitation.name})
            storeToken(res);
            return { success: true, message: 'The invitation has been sent' }
        } catch (error) {
            console.log ("'We were unable to send the invitation ", error)
            let errorState = serverError(error, 'We were unable to send the invitation.');
            return { success: false, message: errorState.message }
        }
}

const rescind = (podcast_id, email) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: podcastCollaborateActionTypes.collaborateDeleteRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.post('admin/podcasts/rescind', {id: podcast_id, email: email})
            storeToken(res);
            dispatch({type: podcastCollaborateActionTypes.collaborateDeleteSuccess, payload: {message: 'Your invitation was sent'}} )
        } catch (error) {
            console.log ("'We were unable to rescind the invitation ", error)
            let errorState = serverError(error, 'We were unable to rescind the invitation.');
            dispatch({type: podcastCollaborateActionTypes.collaborateDeleteFailure, payload: { message: errorState.message}} )

        }
    }
}


const accept = (invitation) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: podcastCollaborateActionTypes.collaborateAcceptRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
         try {
            const res = await operations.post('podcast_invitations/accept.', {invitation: invitation})
             storeToken(res);
            dispatch({type: podcastCollaborateActionTypes.collaborateAcceptSuccess, payload: { message: 'You should now see the podcats the invitation was issued for'}} )
        } catch (error) {
            console.log ("An error occurred accepting the invitation ", error)
             let errorState = serverError(error, 'An error occurred accepting the invitation .');
             dispatch({type: podcastCollaborateActionTypes.collaborateAcceptFailure, payload: { message: errorState.message}} )
         }
    }
};

const decline = (invitation) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: podcastCollaborateActionTypes.collaborateDeclineRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.post('podcast_invitations/accept.', {invitation: invitation})
            storeToken(res);
            dispatch({type: podcastCollaborateActionTypes.collaborateDeclineSuccess, payload: { message: 'You should now see the podcast the invitation was issued for'}} )
        } catch (error) {
            console.log ("An error occurred declining the invitation ", error)
            let errorState = serverError(error, 'An error occurred declining the invitation .');
            dispatch({type: podcastCollaborateActionTypes.collaborateDeclineFailure, payload: { message: errorState.message}} )
        }
    }
};


export default  {
    reset,
    decline,
    invite,
    accept,
    rescind
}
