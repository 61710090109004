import React from 'react'

import {BrowserRouter as Router, Switch} from 'react-router-dom'


import {userRoutes} from '../pages/public/user'
import {podcastClaimsRoutes} from '../pages/public/claims'
import {homeRoute} from '../pages/private/home'
import {analyticsRoutes} from '../pages/private/analytics'
import {advertiserRoutes} from '../pages/private/advertisers'
import {boardsRoutes} from '../pages/private/boards'
import {podcastsRoutes} from '../pages/private/podcasts'
import {resourcesRoutes} from '../pages/private/resources'
import {userManagementRoutes} from '../pages/private/user-management'
import {settingsRoutes} from '../pages/private/settings'

import {NoMatch} from 'pages/public/errors/NoMatch'

import PrivateLayout from 'pages/private/PrivateLayout'
import PrivateRoute from './PrivateRoute'


// for under construction
// import TestForm from '../pages/test/Test'


const VodacastRoutes = (props) => {

    return (
        <Router history={props.history}>
            <Switch>
                    {podcastClaimsRoutes}
                    {userRoutes}


                <PrivateLayout>
                    <Switch>
                        {homeRoute}
                        {podcastsRoutes}
                        {boardsRoutes}
                        {analyticsRoutes}
                        {advertiserRoutes}
                        {resourcesRoutes}
                        {userManagementRoutes}
                        {settingsRoutes}

                        <PrivateRoute key='404' path='*' component={NoMatch}/>
                    </Switch>
                </PrivateLayout>

            </Switch>

        </Router>
    )

}

export default VodacastRoutes;