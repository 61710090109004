import Upload from 'gcs-browser-upload';

async function uploadfile(url, file, onProgress) {

    let upload = new Upload({
        id: file.name,
        url: url,
        file: file,
        // chunkSize:  2621440,
        contentType: file.type,
        onChunkUpload: (info) => {
            onProgress({percent: (info.uploadedBytes / info.totalBytes) * 100});
        }
    })

    try {
        await upload.start()
        console.log('Upload complete!')
    } catch (e) {
        console.log('Upload failed!', e)
    } finally {
        upload = null
    }
}


export default uploadfile
