import React from "react";
import {Input} from "antd";

export const useFilter = (props) => {
    let {placeholder} = props
    const [filter, setFilter] = React.useState(null)

    return [filter,
        <Input.Search
            style={{width: 300}}
            onSearch={setFilter}
            placeholder={placeholder}
            id='filter'
            name='filter'
            allowClear/>
    ]
}

export default useFilter
