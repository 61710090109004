import operations from 'api/superAgentWrapper'
import {podcastClaimsActionTypes} from './claimsState';
import {serverError, notFound, accepted} from "api/service";

const forEmail = (email) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: podcastClaimsActionTypes.podcastClaimsRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.get('admin/claims/for_owner', {email: email});
            dispatch({
                type: podcastClaimsActionTypes.podcastClaimsSuccess,
                payload: {podcasts: res.body, rssEmail: res.body[0].email}
            })
        } catch (error) {
            console.log("An error occurred retrieving the podcasts ", error)
            let errorState = serverError(error, 'There are no podcasts associated with this email.');
            if (notFound(error)) {
                dispatch({type: podcastClaimsActionTypes.podcastClaimsProgress, payload: {emailNotFound: true}})
                // this clears any existing failure messages
                dispatch({type: podcastClaimsActionTypes.podcastClaimsFailure})
            } else {
                dispatch({type: podcastClaimsActionTypes.podcastClaimsFailure, payload: {message: errorState.message}})
            }


        }
    }
};

const forUrl = (email, rssUrl) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: podcastClaimsActionTypes.podcastClaimsRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.get('admin/claims/for_url', {url: rssUrl, email: email});
            // the discovery service is trying to find this - set the state accordingly then
            // dispatch it s an error message so it appears in the proper spot
            if (accepted(res)) {
                dispatch({
                    type: podcastClaimsActionTypes.podcastClaimsProgress,
                    payload: {emailNotFound: true, rssInProgress: true, message: res.body}
                })
                dispatch({type: podcastClaimsActionTypes.podcastClaimsFailure, payload: {message: res.body.message}})
            } else {
                // the happy path
                dispatch({
                    type: podcastClaimsActionTypes.podcastClaimsSuccess,
                    payload: {podcasts: res.body, rssEmail: res.body[0].email}
                })
            }
        } catch (error) {
            console.log("An error occurred retrieving the podcasts ", error)
            let errorState = serverError(error, 'There are no podcasts associated with this url.');
            dispatch({type: podcastClaimsActionTypes.podcastClaimsFailure, payload: {message: errorState.message}})
        }
    }
};

const assign = (ids, email, originating_email) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: podcastClaimsActionTypes.assignClaimsRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.post('admin/claims/assign', {
                ids: ids,
                email: email,
                originating_email: originating_email
            });
            dispatch({type: podcastClaimsActionTypes.assignClaimsSuccess, payload: {message: res.body}})
        } catch (error) {
            console.log("An error occurred assigning the claim(s) for the podcasts ", error)
            let errorState = serverError(error, 'An error occurred assigning the claim(s).');
            dispatch({type: podcastClaimsActionTypes.assignClaimsFailure, payload: {message: errorState.message}})
        }
    }
};

const synchronize = (ids) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.post('admin/claims/synchronize', {
                ids: ids
            });

        } catch (error) {
            console.log("An error occurred assigning the claim(s) for the podcasts ", error)
            let errorState = serverError(error, 'An error occurred assigning the claim(s).');
        }
    }
};

const claim = (id, email) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: podcastClaimsActionTypes.completeClaimsRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.post('admin/claims/claim', {ids: id, email: email});
            dispatch({type: podcastClaimsActionTypes.assignClaimsSuccess, payload: {message: res.body}})
        } catch (error) {
            console.log("An error occurred retrieving the podcast claims ", error)
            let errorState = serverError(error, 'An error claiming the podcast claim(s).', error);
            dispatch({type: podcastClaimsActionTypes.assignClaimsFailure, payload: {message: errorState.message}})
        }
    }
};

const reset = () => {
    return async dispatch => {
        // reset the state
        dispatch({type: podcastClaimsActionTypes.podcastClaimsReset})
        // this clears any existing failure messages
        dispatch({type: podcastClaimsActionTypes.podcastClaimsFailure})
    }
};


export default {
    forEmail,
    forUrl,
    assign,
    synchronize,
    claim,
    reset
}
