import {message, Switch} from "antd";
import React, {useEffect, useState} from "react";
import settingsService from "../api/settingsService";
import {userAuthActionTypes} from "../../../public/user/api/state";
import {useDispatch, useSelector} from "react-redux";


const AdOptIn = () => {
    const dispatch = useDispatch();
    const user = useSelector(store => store.userState.user);
    const optInStatus = user.ad_opt_in;
    const [switchStatus, setSwitchStatus] = useState('');

    useEffect(() => {
        optInStatus ? setSwitchStatus('IN') : setSwitchStatus('OUT');
    }, [optInStatus]);

    const handleSwitch = (checked) => {
        checked ? updateOptIn(true) : updateOptIn(false);
    };

    const updateOptIn = (decision) => {
        settingsService.optIn(user.id, {
            admin_user: {
                first_name: user.first_name,
                last_name: user.last_name,
                ad_opt_in: decision
            }
        }).then((updated) => {
            if (updated.success) {
                dispatch({type: userAuthActionTypes.userUpdateOptIn, payload: updated.user});
                message.success(updated.message, 1);
            } else {
                message.error(updated.message, 1);
            }
        })
    };

    return (
        <section
            style={{display: 'flex',
            justifyContent:'space-between',
            border: '1px solid black',
            alignItems: 'center'
        }}>
            <section style={{display: 'flex', flexDirection: 'column'}}>
                <h3 style={{padding: '8px 0 0 10px'}}>Opt In to Programmatic Ads</h3>
                <p style={{width: '12rem'}}>Ads will be displayed in the episode feed</p>
            </section>
            <section style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <label style={{paddingRight: '8px'}}>{switchStatus}</label>
                <Switch style={{marginRight: '11px'}}
                        id={'switch-button'}
                        onClick={handleSwitch}
                        defaultChecked={optInStatus}
                />
            </section>
        </section>
    )
};

export default AdOptIn;