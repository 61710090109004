import React from 'react'
import Button from 'antd/es/button'
import { Card, Tooltip } from 'antd'
import {ImageComponent, styles as ImageStyle}  from 'components/Image/Image.component'
import {ReactComponent as EditLogo} from 'assets/Edit.svg'
import { Breadcrumbs } from './Breadcrumb'
import defaultImage from 'assets/default_board.png';


const PodcastDetail = (props) => {

    let { podcast } = props;

    const podcastObj = {
        id: podcast.id,
        image_url: podcast.image_url ?? defaultImage
    }

    return (
        <div style={{width: '60%'}}>
        <Breadcrumbs />
            <div style={{width: '210px', float: 'left', paddingTop: '15px', paddingBottom: '5px'}}>
                <Card
                    size='small'
                    hoverable
                    style={{width: '200px', borderRadius: '2em'}}
                    cover={<ImageComponent imageList ={[podcastObj]} />}
                >
                    <Card.Meta title={podcast.title}/>
                </Card>
            </div>
            <div style={{marginLeft: '215px'}}>
                <p style={{textAlign: 'left', paddingTop: '18px'}}><b><i>{podcast.title}</i></b></p>
                <p style={{textAlign: 'left'}}><b><i>{podcast.description && podcast.description.replace(/(<([^>]+)>)/gi, "")}</i>i</b></p>
                <p style={{textAlign: 'left', paddingTop: '10px'}}><i>{podcast.author}</i></p>
            </div>
        </div>
    )

}

PodcastDetail.whyDidYouRender = true
export default PodcastDetail

