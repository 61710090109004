import React from 'react'
import { PrivateRoute } from 'routes'

import Home from './Home'

export const homePath = {
    home: '/home',
    root: '/'
};

export const homeRoute = (

    [
        <PrivateRoute key={homePath.root} exact path={homePath.root} component={Home} />,
        <PrivateRoute key={homePath.home} exact path={homePath.home} component={Home} />
    ]
);


