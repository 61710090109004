import React, { useRef } from 'react'
import {Card, Tooltip} from 'antd'
import {ImageComponent} from 'components/Image/Image.component'
import {cardStyle, cardBodyStyle as bodyStyle} from 'pages/styles'
import {useHover} from "components/Hooks";
import {useDrag, useDrop} from "react-dnd";
import ItemMenu from 'pages/common/ItemMenu'
import ItemTime from 'pages/common/ItemTime'

const EpisodeContentItem = (props) => {
    const {item, content, index, callbacks} = props

    let ref = useRef()

    const [, drop] = useDrop({
        accept: 'TIMELINE',
        hover(item, monitor) {

            if (!ref.current || !callbacks.handleMove) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            callbacks.handleMove(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });

    const [{isDragging}, drag] = useDrag({
        type: item.source,
        item: item,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()

            if (dropResult?.source === 'LIST') {
                callbacks.handleDelete(item)
            } else if (dropResult?.source === 'TIMELINE') {
                if (item.source === 'CONTENT') {
                    callbacks.handleInsert(item)
                } else if (item.source === 'TIMELINE'){
                    callbacks.handleInsert(item)
                }
            }

            document.querySelector('[data-attr="Save Your Work"]').click()
            console.log('BOARD HAS SAVED!')
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.4 : 1;
    const [hovered, eventHandlers] = useHover();

    drag(drop(ref))

    let action = props.timePicker ? ItemTime({...props, hover: hovered}) : ItemMenu({...props, hover: hovered})

    return (
             <div ref={ref} style={{width: '150px'}}>
                <Card {...eventHandlers}
                      siz='small'
                      hoverable
                      bodyStyle={bodyStyle}
                      style={{...cardStyle, opacity, width: '150px'}}
                      cover={ <ImageComponent imageList={[item]} action={action} /> }
                >
                    <Tooltip title={content.title}>
                        <Card.Meta {...content} />
                    </Tooltip>
                </Card>
             </div>
    )
}

export default EpisodeContentItem

