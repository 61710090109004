module.exports = {
  siteName: 'Vodacast Hub',
  copyright: 'Vodacast Hub ©2020 auddia',
  logoPath: 'assets/favicon.svg',
  apiPrefix: '/api/v1',
  fixedHeader: true, // sticky primary layout header
  host: process.env.REACT_APP_HOST,
  apiKey: process.env.REACT_APP_API_KEY,


  /* Layout configuration, specify which layout to use for route. */
  layouts: [
    {
      name: 'primary',
      include: [/.*/],
      exclude: [/(\/(en|zh))*\/login/],
    },
  ],

  /* I18n configuration, `languages` and `defaultLanguage` are required currently. */
  i18n: {
    /* Countrys flags: https://www.flaticon.com/packs/countrys-flags */
    languages: [
      {
        key: 'pt-br',
        title: 'Português',
        flag: '/portugal.svg',
      },
      {
        key: 'en',
        title: 'English',
        flag: '/america.svg',
      },
      {
        key: 'zh',
        title: '中文',
        flag: '/china.svg',
      },
    ],
    defaultLanguage: 'en',
  },
};
