import React, {useState} from "react";

import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Alert from 'antd/es/alert';

import {service, userAuthActions} from './index'
import {useDispatch} from "react-redux";
import {Link} from 'react-router-dom';
import {LockOutlined} from '@ant-design/icons'

import {createStatusSelectors} from 'api/redux/selectors'
import {PublicHeader} from "./Header";

const ResetPasswordForm = (props) => {
    const [token] = useState(props.location.search.split("=")[1]);
    const dispatch = useDispatch();
    const [form] = Form.useForm();



    const resetPassword = values => {
        form.setFieldsValue(values);
        let info = form.getFieldsValue();
        info.reset_password_token = token
        const resPassword = dispatch(service.resetPassword(info));

        if (resPassword) {
            setTimeout(function(){
                window.location.href = "/login";
            }, 2000);
        }
    };
    const title = 'Reset Your Password'

    return (
        <div style={{height: '100vh', backgroundColor: '#315663'}}>
            <PublicHeader title={title} />
            {<ResetPassword>{props}</ResetPassword>}
        </div>
    );

    function ResetPassword(props) {
        return (
            <Row type='flex' align='top' justify='center' style={{backgroundColor: 'white', minHeight: '60%'}}>
                <Col flex='400px' align={'center'}>
                    <div>
                        <Form className='public' size='large' onFinish={resetPassword} form={form}>
                            {props.children.success &&
                                <Alert message="Your password has been reset." className={'alert'}
                                       type="success" closable />
                            }

                            {props.children.error &&
                                <Alert message={props.children.error} className={'alert'} type="error" closable/>
                            }

                            <Form.Item name='password' rules={[{ required: true, message: 'A password is required.' }]}>
                                <Input.Password
                                    className='rounded'
                                    prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item name='password_confirmation'
                                       rules={[
                                           {
                                               required: true,
                                               message: 'Please confirm your password!',
                                           },
                                           ({getFieldValue}) => ({
                                               validator(rule, value) {
                                                   if (!value || getFieldValue('password') === value) {
                                                       return Promise.resolve();
                                                   }

                                                   return Promise.reject('The passwords do not match!');
                                               },
                                           }),
                                       ]}>
                                <Input.Password
                                    className='rounded'
                                    prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="Re-Enter Password"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button type="link" style={{width: '20%', float: 'left'}} href="/login">
                                    Cancel
                                </Button>

                                <Button type="primary" htmlType="submit">
                                    Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>


        )

    }
}

export default createStatusSelectors([userAuthActions.resetPassword], ResetPasswordForm)



