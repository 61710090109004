import React, {useState} from 'react'
import {PlusOutlined} from "@ant-design/icons";
import {Upload} from "antd";


const uploadButton = (
    <div style={{marginLeft: 30}}>
        <PlusOutlined style={{marginRight: 30}}/>
        <div className="ant-upload-text" style={{marginRight: 30}}></div>
    </div>
);


export const useFileUpload = (props) => {
    const [fileList, setFileList] = useState([])
    const [accept, setAccept] = useState(null)

    const wrapSetAccept = (newAccept) => {
        if (JSON.stringify(accept) !==JSON.stringify(newAccept)) {
            setAccept(newAccept)
        }
    }

    const [previewImage, setPreviewImage] = useState('')
    const [previewVisible, setPreviewVisible] = useState(false)

    const handlePreview = file => {
        setPreviewImage(file.thumbUrl)
        setPreviewVisible(true)
    };

    const { Dragger } = Upload

    const handleUpload = (info) => {
        setFileList(info.fileList)
    }

    const handleDrop = (e) => {
        e.preventDefault()
        const { files } = e.dataTransfer
        setFileList([...files])
    }

    return ( [fileList, setFileList, wrapSetAccept,
        <Dragger style={{marginBottom: 20, borderRadius: 20}}
                listType="picture-card"
                fileList={fileList}
                accept={accept}
                // onPreview={handlePreview}
                onChange={handleUpload}
                onDrop={handleDrop}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
        > Upload
            {fileList.length === 0 ? uploadButton : null}
        </Dragger>]
    )
}

