import {userActionTypes} from "../../../private/user/api/state";
import { clearUserToken } from 'api/token'


const initialState = {
    user: null,
    publisher_id: null,
    loading: false,
    isError: false
};


export const userAuthActions = {
    userSignin: 'USER_SIGNIN',
    forgotPassword: 'FORGOT_PASSWORD',
    resetPassword: 'RESET_PASSWORD',
    userRegistration: 'USER_REGISTRATION'
};


export const  userAuthActionTypes = {
    userSignin: 'USER_SIGNIN_REQUEST',
    userSigninSuccess: 'USER_SIGNIN_SUCCESS',
    userSigninFailure: 'USER_SIGNIN_FAILURE',    
    forgotPasswordRequest: 'FORGOT_PASSWORD_REQUEST',
    forgotPasswordSuccess: 'FORGOT_PASSWORD_SUCCESS',
    forgotPasswordFailure: 'FORGOT_PASSWORD_FAILURE',
    resetPasswordRequest: 'RESET_PASSWORD_REQUEST',
    resetPasswordSuccess: 'RESET_PASSWORD_SUCCESS',
    resetPasswordFailure: 'RESET_PASSWORD_FAILURE',
    userRegistrationRequest: 'USER_REGISTRATION_REQUEST',
    userRegistrationSuccess: 'USER_REGISTRATION_SUCCESS',
    userRegistrationFailure: 'USER_REGISTRATION_FAILURE',
    userUpdateOptIn: 'USER_UPDATE_OPT_IN'

};



const userState = (state = initialState, action) => {
    switch (action.type) {
        case userAuthActionTypes.userSigninSuccess:
            return {
                ...state,
                user: action.payload,
                publisher: action.payload.publisher,
                claiming: action.claiming
            };
        case userAuthActionTypes.userRegistrationSuccess:
            return {
                ...state,
                user: action.payload
            };
        case userAuthActionTypes.forgotPasswordSuccess:
            return {...state, user: action.payload};
        case userAuthActionTypes.resetPasswordSuccess:
            return {...state, user: action.payload};
        case userActionTypes.userSignoutSuccess:
            clearUserToken();
            return {...state, user: null, publisher: null};
        case userAuthActionTypes.userUpdateOptIn:
            return {...state, user: action.payload};
        default:
            return state;
    }
};



export default userState;



