import React from "react";

import {itemStyle} from "../styles";
import {TimePicker} from "antd";
import timeutils from 'pages/helpers/timeutils'

export const ItemTime = (props) => {
    const {item, hover, callbacks} = props
    let styles = hover ? itemStyle : {display: 'none'}

    const setTime = (value) => {
       item.starts_at = timeutils.dateToSeconds(value)
       callbacks.setTime(item)
    }

    return (

        <div style={styles}>
            <TimePicker placeholder='Adjust...' onChange={setTime} className='rounded'/>
        </div>

    )
}


export default ItemTime
