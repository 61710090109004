import React, {useEffect, useState} from 'react';
import {Form, Input, Button, message} from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import settingsService from "../api/settingsService";
import {NavLink} from "react-router-dom";

const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    useEffect(() => {
        toggleError();
        checkPasswords();
    }, [passwordsMatch, newPassword, confirmPassword]);

    const displayError = () => {
        setPasswordsMatch(false);
    };

    const toggleError = () => {
        const error = document.getElementById('password-error');

        if (passwordsMatch) {
            error.style.visibility = 'hidden';
        } else {
            error.style.visibility = 'visible';
        }
    };

    const checkPasswords = () => {
        if (newPassword === confirmPassword) {
            setPasswordsMatch(true);
        } else {
            setPasswordsMatch(false);
        }
    };

    const onSubmit = () => {
        settingsService.update({
            current_password: password,
            password: newPassword,
            password_confirmation: confirmPassword
        }).then((updated) => {
                if (updated.success === true) {
                    message.success(updated.message, 5);
                } else {
                    message.error(updated.message, 5);
                }
            });
    };

    return (
        <section className={'settings-container'} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <h2>Update Password</h2>
            <Form name={'settings'} layout={'vertical'}
                style={{display: 'flex', flexDirection: 'column'}}
            >
                <label>Current Password</label>
                <Input.Password
                    placeholder="current password"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    style={{width: '15rem', marginBottom: '20px'}}
                    onKeyUp={event => setPassword(event.target.value)}
                />
                <label>New Password</label>
                <Input.Password
                    placeholder="new password"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    style={{width: '15rem', marginBottom: '20px'}}
                    onKeyUp={event => setNewPassword(event.target.value)}
                    onKeyDown={displayError}
                />
                <label>Confirm New Password</label>
                <Input.Password
                    placeholder="confirm new password"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    style={{width: '15rem'}}
                    onKeyUp={event => setConfirmPassword(event.target.value)}
                />
                <p style={{color: 'red'}} id={'password-error'}>Passwords do not match</p>
                <section style={{display: 'flex', justifyContent: 'space-between'}}>
                    <NavLink to='/settings'
                             size='large'
                             className='ant-button-modal'
                             style={{padding:'8px 16px', borderRadius:'2em', fontSize: '20px', textShadow:'0 -1 0 rgb(0 0 0/12%)', boxShadow:'0 2px 0 rgb(0 0 0/5%)'}}
                    >Cancel</NavLink>
                    <Button
                        key="submit"
                        size='large'
                        className='ant-button-modal'
                        type="primary"
                        onClick={onSubmit}
                        style={{width: '6rem'}}
                    >
                        Submit
                    </Button>
                </section>
            </Form>
        </section>
    )
}

export default ChangePassword;