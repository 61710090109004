import operations from 'api/superAgentWrapper'
import {episodeActionTypes} from './episodeState';
import {serverError} from "api/service";
import {storeToken} from 'api/token';


const current = (episode) => {
    return async dispatch => {
        dispatch({type: episodeActionTypes.currentEpisode, payload: episode})
    }
};



const fetch = (podcast) => {
    return async dispatch => {
        function onSuccess(response) {
            storeToken(response);
            dispatch({type: episodeActionTypes.episodesFetchSuccess, payload: response.body})
        }

        function onError(error) {
            let errorState = serverError(error, 'We were unable to retrieve the episodes.');
            dispatch({type: episodeActionTypes.episodesFetchFailure, payload: {error: errorState.message}})
        }

        dispatch({type: episodeActionTypes.episodesFetchRequest})
        try {
            onSuccess(await operations.get(`/admin/podcasts/${podcast.id}/vodacast_episodes`))
        } catch (error) {
            onError(error)
        }
    }
};


const content = (episode) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: episodeActionTypes.episodesSyncRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.get(`/admin/podcasts/${episode.podcast_id}/vodacast_episodes/${episode.id}/content`)
            storeToken(res);
            dispatch({type: episodeActionTypes.episodesSyncSuccess, payload: res.body})
        } catch (error) {
            console.log("'Error retrieving episodes", error)
            let errorState = serverError(error, 'We were unable to retrieve your episodes.');
            dispatch({type: episodeActionTypes.episodesSyncFailure, payload: {message: errorState.message}})

        }
    }
}

async function upsert(episode) {
    let updating = episode.id
    try {
        let res = updating ? await operations.put(`admin/podcasts/${episode.podcast_id}/vodacast_episodes/${episode.id}`, {vodacast_episode: episode}) :
            await operations.post(`admin/podcasts/${episode.podcast_id}/vodacast_episodes/`, {vodacast_episode: episode})
        storeToken(res);
        let message = updating ? 'The episode has been updated' : 'The episode has been created'
        return {success: true, message: message, episode: res.body}
    } catch (error) {
        console.log('Error upserting the episode', error)
        let message = updating ? 'We were unable to update the episode' : 'We were unable to create the episode'
        let errorState = serverError(error, message);
        return {success: false, message: errorState.message}
    }
}

const publish = (episode) => {
    return async dispatch => {
        function onSuccess(response) {
            storeToken(response);
            dispatch({type: episodeActionTypes.episodesPublishSuccess,
                payload: { episode: response.body, message: 'The episode has been published'}} )
        }

        function onError(error) {
            console.log('Error publishing the episode', error)
            let errorState = serverError(error, 'We were unable to publish the episode');
            dispatch({type: episodeActionTypes.episodesRequestFailure, payload: { error: errorState.message}} )
        }

        dispatch({type: episodeActionTypes.episodesRequestInProgress})
        try {
            onSuccess(await operations.post(`admin/podcasts/${episode.podcast_id}/vodacast_episodes/${episode.id}/publish`, {vodacast_episode: episode}))
        } catch (error) {
            onError(error)
        }
    }

}


const update = (episode) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: episodeActionTypes.episodeUpdateRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.put(`admin/podcasts/${episode.podcast_id}/vodacast_episodes/${episode.id}`, {vodacast_episode: episode})
            storeToken(res);
            dispatch({type: episodeActionTypes.episodeUpdateSuccess, payload: res.body})
        } catch (error) {
            console.log("An error occurred updating the episode information ", error)
            let errorState = serverError(error, 'An error occurred updating the episode information .');
            dispatch({type: episodeActionTypes.episodeUpdateFailure, payload: {message: errorState.message}})
        }
    }
};



async function audio_url(episode, file) {
    let audio_url_params = {
        location: window.location.hostname,
        podcast_id: episode.podcast_id,
        id: episode.id,
        file: file.name,
        content_type: file.type
    }
    try {
        const res = await operations.post(`admin/gcs/audio_url`, {audio_params: audio_url_params})
        return {resumable_url: res.body.resumable_url}
    } catch (error) {
        console.log("An error occurred updating the episode information ", error)
        return {resumable_url: null}
    }

};

const identify = (episode, identified_id) => {
    return async dispatch => {
        function onSuccess(res) {
            storeToken(res);
            dispatch({type: episodeActionTypes.episodeUpdateSuccess, payload: {episode: res.body, discard: identified_id} })
            return 'success'
        }

        function onError(error) {
            console.log ("Error in identify episode", error)
            let message = 'We were unable to update the episode.'
            let errorState = serverError(error, message);
            dispatch({type: episodeActionTypes.episodeUpdateFailure, payload: { error: errorState.message}} )
            return 'error'
        }

        dispatch({type: episodeActionTypes.episodeUpdateRequest})

        try {
            return onSuccess(await operations.post(`admin/podcasts/${episode.podcast_id}/vodacast_episodes/${episode.id}/identify`, { identified_id: identified_id } ))
        } catch (error) {
            return onError(error)
        }
    }

}


const destroy = (episode) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: episodeActionTypes.episodeDestroyRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.httpDelete(`admin/podcasts/${episode.podcast_id}/vodacast_episodes/${episode.id}`)
            storeToken(res);
            dispatch({type: episodeActionTypes.episodeDestroySuccess, payload: res.body})
        } catch (error) {
            console.log('Error deleting the episode', error)
            let errorState = serverError(error, 'We were unable to delete the episode.');
            dispatch({type: episodeActionTypes.episodeDestroyFailure, payload: {message: errorState.message}})

        }
    }
}

export default {
    audio_url,
    content,
    update,
    publish,
    destroy,
    fetch,
    identify,
    upsert,
    current
}
