const initialState = {
    claims: []

};

export const podcastClaimsActions = {
    podcastClaims: 'PODCAST_CLAIMS',
    assignClaims: 'ASSIGN_CLAIMS',
    completeClaims: 'COMPLETE_CLAIMS',
    deleteClaims: 'DELETE_CLAIM'
};


export const podcastClaimsActionTypes = {
    podcastClaimsRequest: 'PODCAST_CLAIMS_REQUEST',
    podcastClaimsSuccess: 'PODCAST_CLAIMS_SUCCESS',
    podcastClaimsFailure: 'PODCAST_CLAIMS_FAILURE',
    
    podcastClaimsReset: 'PODCAST_CLAIMS_RESET',
    podcastClaimsProgress: 'PODCAST_CLAIMS_PROGRESS',

    assignClaimsRequest: 'ASSIGN_CLAIMS_REQUEST',
    assignClaimsSuccess: 'ASSIGN_CLAIMS_SUCCESS',
    assignClaimsFailure: 'ASSIGN_CLAIMS_FAILURE',

    completeClaimsRequest: 'COMPLETE_CLAIMS_REQUEST',
    completeClaimsSuccess: 'COMPLETE_CLAIMS_SUCCESS',
    completeClaimsFailure: 'COMPLETE_CLAIMS_FAILURE',
    deleteClaimsRequest: 'DELETE_CLAIM_REQUEST',
    deleteClaimsSuccess: 'DELETE_CLAIM_SUCCESS',
    deleteClaimsFailure: 'DELETE_CLAIMS_FAILURE',
}

// reducer
const podcastClaimsState = (state = initialState, action) => {
    switch (action.type) {
        case podcastClaimsActionTypes.assignClaimsSuccess:
            return {
                ...state,
                claims: action.payload, messagesuccess: 'yes'
            };
        case podcastClaimsActionTypes.assignClaimsFailure:
            return {
                ...state,
                notFound: action.payload
            }

        case podcastClaimsActionTypes.podcastClaimsSuccess:
            return {
                ...state,
                claims: action.payload.podcasts,
                rssEmail: action.payload.rssEmail,
                messagesuccess: 'yes'
            };
        case podcastClaimsActionTypes.podcastClaimsReset:
            return {initialState};
        case podcastClaimsActionTypes.podcastClaimsProgress:
            return {
                ...state,
                emailNotFound: action.payload.emailNotFound,
                rssInProgress: action.payload.inProgress,
                message: action.payload.message
            }
        case podcastClaimsActionTypes.deleteClaimsSuccess:
            return {
                ...state,
                claims: action.payload
            };
        default:
            return state;
    }
};


export default podcastClaimsState;






