import React, {useEffect, useState, useRef} from 'react'

import {Button, Col, Row, Spin} from "antd";
import {ReactComponent as AddLogo} from "../../../assets/+_dark.svg";
import {Button as ToolbarButton, Toolbar} from "../../../components/Toolbar/Toolbar.component";
import {PlusOutlined} from "@ant-design/icons";


import {useDispatch, useSelector} from "react-redux";
import service from "./api/boardsService";
import userService from '../user/api/service'
import DndBoardListComponent from './components/DndBoardList/DndBoardList.component'
import BoardModal from './components/BoardModal'

import {boardActionTypes} from "./api/boardsState";
import useFilter from "pages/common/Filter/Filter.component";
import ErrorMessage from 'pages/common/ErrorMessage'
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import { Breadcrumbs } from './components/Breadcrumb';
import BoardCollaborateModal from '../podcasts/components/BoardCollaborateModal';


const PlaningLibrary = (props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [inviteVisible, setInviteVisible] = useState(false)
    const user = useSelector(store => store.userState.user);
    const boards = useSelector(state => state.boardsState.boards);
    let boardStatus = useSelector(state => state.boardsState.status);
    let error = useSelector(state => state.boardsState.error);

    const dispatch = useDispatch();
    let focused = useRef({id: null, name: null, description: null, create: true})

    const onSelect = (board) => {
        focused.current = board;
    }

    const onUpsert = (board) => {
        board.create ? dispatch({type: boardActionTypes.boardCreateSuccess, payload: board}) :
            dispatch({type: boardActionTypes.boardUpdateSuccess, payload: board})
    }

    const onDelete = (board) => {
        dispatch(service.destroy(board))
    }

    const onEdit = (board) => {
        board.create = false;
        focused.current = board
        setModalVisible(true)
    }

    let menu = {edit: onEdit, delete: onDelete}

    const [filterValue, filterComponent] = useFilter('\'Filter Boards by Name or Description\'')

    const doFilter = () => {
        if (!filterValue) {
            return boards
        }
        return boards.filter(board => board.name.toLowerCase().includes(filterValue) || board.description?.toLowerCase().includes(filterValue));

    }
    useEffect(() => {
        if (boardStatus === 'idle') {
            dispatch(service.fetch(user))
            dispatch(userService.collaborators())
        }
    }, [dispatch, boardStatus, user]);


    return (
        <div style={{margin: '10px', height: '100vh'}}>
            <BoardModal id='boardmodal'
                        board={focused.current.create === false ? focused.current : {
                            id: null,
                            name: null,
                            description: null,
                            create: true
                        }}
                        visible={modalVisible}
                        onSuccess={(board) => onUpsert(board)}
                        onCancel={() => setModalVisible(false)}
            />
            <ErrorMessage error={error} action={boardActionTypes.boardRequestClear} />
            <Row>
                <Col>
                    <Breadcrumbs />
                    <p style={{textAlign: 'left', marginTop: '5px', fontSize: '16px'}}>
                        <i>The planning library warehouses your planning boards - boards help organize rich, digital content for your episodes.</i>
                    </p>
                    <p style={{textAlign: 'left', marginTop: '5px', fontSize: '16px'}}>Planning Library</p>
                    <p style={{textAlign: 'left'}}>
                        {filterComponent}
                    </p>
                    <Row>
                        <Button onClick={() => setInviteVisible(true)} style={{marginLeft: 15}}>Invite Collaborator to a Board<PlusOutlined type='right'/></Button>
                    </Row>
                    {inviteVisible && <BoardCollaborateModal
                        boards={boards}
                        visible={inviteVisible}
                        onSuccess={() => {
                            setInviteVisible(false)
                        }}
                        onCancel={() => setInviteVisible(false)}
                    />}
                    <Spin size='large' spinning={boardStatus === 'loading'} delay={100}>
                        <DndProvider backend={HTML5Backend}>
                        <DndBoardListComponent className='board-list' boards={doFilter()} menu={menu} onSelectBoard={onSelect}/>
                        </DndProvider>
                    </Spin>
                </Col>
            </Row>

            <div className='add-board'>
            <Toolbar>
                <ToolbarButton tooltip='Create a New Board'
                   onClick={() => {
                       focused.current.create = true;
                       setModalVisible(true)
                   }}
                   logo={AddLogo}
                />
            </Toolbar>
            </div>

        </div>
    )
}

PlaningLibrary.whyDidYouRender = true
export default PlaningLibrary;
