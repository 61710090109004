import React from "react";
import { Modal, Form, Spin, Input, Button, message, Select } from "antd";
import { MailOutlined, UserAddOutlined } from "@ant-design/icons";
import boardCollaborateService from "pages/public/claims/api/boardCollaborateService";

const BoardCollaborateModal = ({boards = [], visible = false, onCancel, onSuccess}) => {
    const [form] = Form.useForm();
    let spin = false;
    const { Option } = Select;

    const onInvite = () => {
        form.validateFields().then((values) => {
            boardCollaborateService.invite({
                board_id: values.board,
                name: values.contributor,
                email: values.email
            }).then((invitation) => {
                if (invitation.success === true) {
                    message.success(invitation.message, 5, onClose)
                    onSuccess()
                } else {
                    message.error(invitation.message, 5)
                }
            })
        })
            .catch((info) => {
                console.log('Contributor Validation Failed:', info);
            });
    }

    const boardOptions =
        boards.map((board) => {
        return <Option value={board.id}>{board.name}</Option>
        })


    const onClose = () => {
        form.resetFields()
        message.destroy()
        onCancel()
    }

    return <Modal
        className='modal'
        width={400}
        closable={false}
        visible={visible}
        title={<i>Invite a Contributor to a Board</i>}
        footer={[
            <Button key="submit" size='large' className='ant-button-modal' type="primary" onClick={() => onInvite()}>
                Invite
            </Button>,
        ]}
        onCancel={onClose}
        >
            <Form
                form={form}
                size='large'
                layout="vertical"
                name="inviteModal"
            >
                <Spin size='large' spinning={spin} delay={500}>
                    <Form.Item name='contributor'
                            rules={[{required: true, message: 'Please enter the name of your collobarator'}]}>
                        <Input
                            className='rounded'
                            prefix={<UserAddOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder="Name of Contributor"
                        />
                    </Form.Item>

                    <Form.Item name='email'
                           rules={[{required: true, message: 'Please enter a valid email address.'}]}>
                    <Input
                        className='rounded'
                        prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                        placeholder="Email"
                    />
                </Form.Item>

                <Form.Item name='board'
                           rules={[{required: true, message: 'Selection is needed'}]}>
                    <Select
                        className='rounded'
                        placeholder='Select a Board'
                    >
                        {boardOptions}
                    </Select>
                </Form.Item>

                </Spin>
            </Form>
        </Modal>
}
        
BoardCollaborateModal.whyDidYouRender = true
export default BoardCollaborateModal