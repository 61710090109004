import React from 'react'
import {Card, Tooltip} from 'antd'

import {Image} from 'components/Image'
import defaultBoard from "../../../../../assets/default_board.png";
import {cardStyle, cardSelectedStyle, cardBodyStyle as bodyStyle} from 'pages/styles'
import {useHover} from "../../../../../components/Hooks";
import {ItemMenu} from 'pages/common/ItemMenu'
import {useDrag, useDrop} from "react-dnd";
import { useSelector } from 'react-redux'


const selectContentById = (state, id) => {
    return state.metaContentState.contents.find(content => content.id === id)
}

const MetaContentItem = (props) => {
    const {id, selected, moveHandler, index} = props
    const [hovered, eventHandlers] = useHover();

    let item = useSelector(state => selectContentById(state, id))
    item.index = index
    const content = { title: item.title || item.content_name }

    let ref = React.useRef()
    const [, drop] = useDrop({
        accept: 'META_CONTENT',
        hover(item, monitor) {
            if (!ref.current || !moveHandler) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.left - hoverBoundingRect.right) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.x - hoverBoundingRect.right;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveHandler(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });

    const [{isDragging}, drag] = useDrag({
        type: 'META_CONTENT',
        item: item,
        end: (board, monitor) => {
            const dropResult = monitor.getDropResult()
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });


    drag(drop(ref))

    return (
        <div ref={ref} style={{width: '200px'}}>
            <Card
                {...eventHandlers}
                siz='small'
                hoverable
                bodyStyle={bodyStyle}
                style={selected === false ? cardStyle : cardSelectedStyle}
                cover={<Image imageList={[item.image_url, defaultBoard]}
                                       action={ItemMenu({...props, item: item, content: content,  hover: hovered})}/>}
            >
                <Tooltip title={content.title}>
                    <Card.Meta {...content} />
                </Tooltip>
            </Card>
        </div>
    )
}

MetaContentItem.whyDidYouRender=false
export default MetaContentItem

