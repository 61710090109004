import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {withBreadcrumbs} from './withBreadcrumbs';
import { Breadcrumb} from "antd";
import {ReactComponent as HomeLogo} from 'assets/Home.svg'


const routes = [
];


// You can use this as a component like
// <Breadcrumbs />
// But it needs to be initialized with all the routes

const style={fontSize: '16px', fontWeight: 'bold'}

const Breadcrumbs = ({breadcrumbs}) => {
    const history = useHistory()
    return (
    <div>
        <Breadcrumb separator='>'>
            <Breadcrumb.Item key='Home'>
                <Link replace={true} to='/'>
                    <div className='circle_dark'>
                        <HomeLogo className='svg'/>
                    </div>
                </Link>
            </Breadcrumb.Item>
            {breadcrumbs.map(({breadcrumb, path, match}, idx) => (
                idx + 1 === breadcrumbs.length ?
                    <span style={style} key={idx}> {breadcrumb} </span> :
                    <Breadcrumb.Item style={style} key={breadcrumb}>
                        <Link to={path}>{breadcrumb}</Link>
                    </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    </div>
    )
};

export const _breadcrumbs = (routes) => {
    return withBreadcrumbs(routes)(Breadcrumbs)
}

export default withBreadcrumbs(routes)(Breadcrumbs);
