import React from 'react'
import {Redirect} from 'react-router-dom';
import {useDispatch, useSelector, shallowEqual} from "react-redux"
import userService from "pages/private/user/api/service";


const PodcastTokenProcessor = (props) => {
    console.log(props)
    let dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(userService.processToken(props.location.pathname.split('/').pop()))
    }, [])
    return <Redirect to='/podcasts'/>

}

PodcastTokenProcessor.whyDidYouRender = true
export default PodcastTokenProcessor
