export const DEFAULT_META_CONTENT = {
    id: null,
    content_name: null,
    description: null,
    image_url: null,
    video_url: null,
    url: null,
    content_type: null,
    title: null,
    create: true
}

export const fileUploadTypes = {
    IMAGE: {accept: ["image/png, image/jpeg"]},
    VIDEO: {accept: 'video/*'}

}

export const canDropBackground = '#799BAF'




