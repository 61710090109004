import React from 'react'
import { PrivateRoute } from 'routes'

import AdvertisersForm from './Advertisers'

export const advertiserPaths = {
    advertisers: '/advertisers'
};

export const advertiserRoutes = (
     [
        <PrivateRoute key={advertiserPaths.advertisers} path={advertiserPaths.advertisers} component={AdvertisersForm} />
    ]
);


