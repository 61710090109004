import {clearUserToken} from 'api/token'


const initialState = {
    user: {},
    collaborators: []
};

export const userActions = {
    userRefresh: 'USER_REFRESH',
    userSignout: 'USER_SIGNOUT',
    userPassword: 'RESET_PASSWORD',
    userDetail: 'USER_DETAIL',
    userCollaborators: 'USER_COLLABORATORS'
};

export const userActionTypes = {
    userRefresh: 'USER_REFRESH',
    userSignout: 'USER_SIGNOUT_REQUEST',
    userSignoutSuccess: 'USER_SIGNOUT_SUCCESS',
    userDetailUpdate: 'USER_DETAIL_UPDATE_REQUEST',
    userDetailUpdateSuccess: 'USER_DETAIL_UPDATE_SUCCESS',
    userDetailUpdateFailure: 'USER_DETAIL_UPDATE_FAILURE',
    resetPasswordRequest: 'RESET_PASSWORD_REQUEST',
    resetPasswordSuccess: 'RESET_PASSWORD_SUCCESS',
    resetPasswordFailure: 'RESET_PASSWORD_FAILURE',
    userCollaboratorsSuccess: 'USER_COLLABORATOR_SUCCESS'
};

export const tokenActionTypes = {
    tokenActionSuccess: 'TOKEN_ACTION_SUCCESS',
    tokenActionFailure: 'TOKEN_ACTION_FAILURE',
    tokenActionAppend: 'TOKEN_ACTION_APPEND'
}


const userState = (state = initialState, action) => {
    switch (action.type) {
        case userActionTypes.userRefresh:
            return {...state, user: action.payload};
        case userActionTypes.userSignoutSuccess:
            clearUserToken();
            return {...state, user: null, publisher: null};
        case userActionTypes.userDetailUpdateSuccess:
            return {...state, user: action.payload};
        case userActionTypes.resetPasswordSuccess:
            return {...state, user: action.payload};
        case userActionTypes.userCollaboratorsSuccess:
            return {...state, collaborators: action.payload};
        case tokenActionTypes.tokenActionSuccess:
            return { ...state, user: action.payload.tokens}
        default:
            return state;
    }
};



export default userState;



