import React, {useEffect, useRef, useState, useCallback} from 'react'
import {useDispatch, useSelector, batch} from "react-redux"

import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Space from 'antd/es/space'
import Spin from "antd/es/spin";


import {episodeActionTypes, episodeActions, episodeStatus} from './api/episodeState'
import episodeService from './api/episodeService'

import podcastService from './api/podcastService'
import {shallowCopy} from 'pages/helpers/utils'

import PodcastDetail from './components/Podcast'
import EpisodeList from './components/EpisodeList'
import EpisodeCreateModal from './components/EpisodeCreateModal'
import EpisodeDeleteModal from './components/EpisodeDeleteModal'
import SocialMedia from './components/SocialMedia'

import {Toolbar, Button as ToolbarButton} from 'components/Toolbar/Toolbar.component'

import {createStatusSelectors} from 'api/redux/selectors'

import {ReactComponent as AddLogo} from "../../../assets/+_dark.svg";

import {ReactComponent as InviteLogo} from "../../../assets/Add_user.svg";
// import {ReactComponent as EditLogo} from "../../../assets/Edit.svg";
// import {ReactComponent as DeleteLogo} from "../../../assets/Delete.svg";

import {useSmartState} from 'components/Hooks'
import {shallowCopy as episodeDup} from "pages/helpers/utils";
import {createSelector} from "reselect";
import EpisodeListTour from "../../../components/Tour/EpisodeListTour";


const episodesSelector = state => state.episodeState.episodes
const selectPodcastById = (state, id) => {
    return state.podcastState.podcasts.find(podcast => podcast.id === id)
}

const selectEpisodeIds = createSelector(
    episodesSelector,
    (episodes) => episodes.map(episode => episode.id)
)

const selectInprogressEpisodes = createSelector(
    episodesSelector,
    (episodes) => episodes.filter(episode => episode.status === 'IN_PROGRESS')
)

const selectSubmittedEpisodes = createSelector(
    episodesSelector,
    (episodes) => episodes.filter(episode => episode.status === 'PUBLISHED')
)


const selectInprogressEpisodeIds = createSelector(
    selectInprogressEpisodes,
    (episodes) => episodes.filter(episode => episode.status === 'IN_PROGRESS')
)

const selectSubmittedEpisodeIds = createSelector(
    selectSubmittedEpisodes,
    (episodes) => episodes.filter(episode => episode.status === 'PUBLISHED')
)

const selectEpisodeById = (state, id) => {
    return state.episodeState.episodes.find(episode => episode.id === id)
}

const EpisodesList = (props) => {
    const [episodeCreateVisible, setEpisodeCreateVisible] = useSmartState(false)
    const [episodeDeleteVisible, setEpisodeDeleteVisible] = useSmartState(false)
    const [episodesUpdated, setEpisodesUpdated] = useState(Date.now())
    let currentPodcast = useSelector(store => store.podcastState.currentPodcast);
    const inProgressEpisodes = useSelector(selectInprogressEpisodes)
    const submittedEpisodes = useSelector(selectSubmittedEpisodes)
    const inProgressEpisodeIds = useSelector(selectInprogressEpisodeIds)
    const submittedEpisodeIds = useSelector(selectSubmittedEpisodeIds)

    let focused = useRef({id: null, title: null})

    let dispatch = useDispatch();

    // returns the episodes actively being edited
    useEffect(() => {
        batch(() => {
            dispatch(episodeService.fetch(currentPodcast))
        })
    }, [currentPodcast, episodesUpdated, dispatch]);

    const onEditPodcast = (podcast) => {
        dispatch(podcastService.sync(podcast))
    }

    const onEditEpisode = (episode) => {
        focused.current = episodeDup(episode)
        setEpisodeCreateVisible(true)
    }

    const onEpisodeDeleteModal = (episode) => {
        focused.current = episodeDup(episode)
        setEpisodeDeleteVisible(true)
    }

    const onEpisodeDelete = (episode) => {
        dispatch(episodeService.destroy(episode)).then(result => {
            setEpisodesUpdated(Date.now())
            setEpisodeDeleteVisible(false)
        })
    }

    const onEpisodeUpsert = (episode) => {
        episode.create ? dispatch({type: episodeActionTypes.episodeCreateSuccess, payload: episode}) :
            dispatch({type: episodeActionTypes.episodeUpdateSuccess, payload: episode})
        setEpisodesUpdated(Date.now())
    }

    const menu = {edit: onEditEpisode, delete: onEpisodeDeleteModal}


    return (
        <>
        <EpisodeListTour />
        <div style={{margin: '10px', height: '100vh'}}>

            {episodeCreateVisible && <EpisodeCreateModal
                podcast={shallowCopy(currentPodcast)}
                episode={focused.current.create === true ? {id: null, title: null} : focused.current}
                visible={episodeCreateVisible}
                onSuccess={(episode) => onEpisodeUpsert(episode)}
                onCancel={() => setEpisodeCreateVisible(false)}
            />}

            {episodeDeleteVisible && <EpisodeDeleteModal
                podcast={currentPodcast}
                episode={focused.current}
                visible={episodeDeleteVisible}
                onSuccess={(episode) => onEpisodeDelete(episode)}
                onCancel={() => setEpisodeDeleteVisible(false)}
            />}

            <Row>
                <Col>
                    <Space direction='vertical'/>
                    <PodcastDetail podcast={currentPodcast} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <p style={{textAlign: 'left', fontSize: '16px', paddingTop: '5px'}}>Social Media</p>
                    <SocialMedia
                        podcast={shallowCopy(currentPodcast)}
                        onSuccess={(updated) => onEditPodcast(updated)}
                    />

                </Col>
            </Row>

            <Row>
                <Col>
                    <p style={{textAlign: 'left', fontSize: '16px', paddingTop: '5px'}}>Episodes</p>
                    <p style={{textAlign: 'left', fontSize: '16px'}}><i>In Progress</i></p>
                    <Spin size='large' spinning={props.isLoading} delay={500}>
                        <EpisodeList episodes={inProgressEpisodes} menu={menu} image={currentPodcast.image_url}/>
                    </Spin>
                </Col>
            </Row>

            <Row>
                <Col>
                    <p style={{textAlign: 'left', fontSize: '16px'}}><i>Submitted</i></p>
                    <Spin size='large' spinning={props.isLoading} delay={200}>
                        <EpisodeList
                            classname='episode-list'
                            episodes={submittedEpisodes}
                            menu={menu}
                            emptyText='No Episodes Submitted'
                            image={currentPodcast.image_url}
                        />
                    </Spin>
                </Col>
            </Row>


            <Toolbar>
                <div className='add-episode'>
                <ToolbarButton
                    key='episodeCreate'
                    tooltip='Create a new episode or start adding content to an existing one' logo={AddLogo}
                    onClick={() => {
                       focused.current.create = true;
                       setEpisodeCreateVisible(true)
                   }}
                />
                </div>
            </Toolbar>
        </div>
        </>
    )

}

export default createStatusSelectors([episodeActions.episodesFetch, episodeActions.episodeCreate, episodeActions.episodeDestroy], EpisodesList)
