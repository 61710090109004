import React from 'react'
import {Card, Tooltip} from 'antd'
import {ImageComponent} from 'components/Image/Image.component'
import {cardStyle, cardBodyStyle as bodyStyle} from 'pages/styles'
import { useSelector } from 'react-redux'

const selectPodcastById = (state, id) => {
    return state.podcastState.podcasts.find(podcast => podcast.id === id)
}

const PodcastItem = (props) => {
    const { podcastId } = props
    const podcast = useSelector(state => selectPodcastById(state, podcastId))

    return (
        podcast ?
        <Card
            siz='small'
            hoverable
            bodyStyle={bodyStyle}
            style={cardStyle}
            // had to do this awkwardly as the whole podcast to work with the Image.component file
            cover={<ImageComponent imageList={[podcast]}/>}
        >
            <Tooltip title={podcast.title}>
                <Card.Meta {... {title: podcast.title} }/>
            </Tooltip>
        </Card>
            : null
    )
}

// PodcastItem.whyDidYouRender = true
export default PodcastItem

