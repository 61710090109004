
export const append = (state = [], object) => {
    let index = state.findIndex(o => o.id === object.id)
    let collection = state.slice()
    if (index === -1) {
        collection.push(object)
    }
    return collection
}



export const update = (state = [], object) => {
    let index = state.findIndex(p => p.id === object.id)
    let collection = state.slice()
    if (index !== -1) {
        collection[index] = object
    }
    return collection
}

export const destroy = (state = [], object) => {
    let index = state.findIndex(p => p.id === object.id)
    let collection = state.slice()
    if (index !== -1) {
        collection.splice(index, 1)
    }
    return collection
}




