import React from 'react';
import {Result} from 'antd';

export const NoMatch = (props) => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you wanted does not exist."
        />)
}
