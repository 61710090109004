import React from 'react'
import {Modal, Button} from 'antd'

import 'pages/private/modal.less'


const EpisodeDeleteModal = (props) => {

    const { episode, visible, onSuccess, onCancel } = props

   const onClose = () => {
       onCancel()
   }

    return <Modal
        className='modal'
        width={400}
        center
        destroyOnClose={true}
        closeable={false}
        visible={visible}
        title={`Delete ${episode.title} ?`}
        footer={[
            <Button key="submit" size='large' className='ant-button-modal' type="primary" onClick={() => onSuccess(episode)}>
               DELETE
            </Button>,
        ]}
        onCancel={onClose}
    >
        <span style={{color: 'white'}}> Deleting this episode will remove any work completed upon it. Press the 'DELETE' button to continue.</span>

    </Modal>
}

export default EpisodeDeleteModal
