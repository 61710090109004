import { Component } from "react";
import { clearUserToken } from "./api/token";

class IdleTimer extends Component {

    constructor({ token, onTimeOut, onExpired }) {
        super();

        this.onTimeOut = onTimeOut;
        this.timeout = token.exp;

        const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);

        if (expiredTime > 0 && expiredTime < Date.now()) {
            onExpired();

            return;
        }

        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker(expiredTime);
        this.startInterval();
    }

    startInterval() {
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);

            if (expiredTime <= Date.now()) {
                if (this.onTimeOut) {
                    // If we want to return the timeout
                    // this.onTimeOut();

                    window.location.href = '/login';
                    this.cleanUp();
                }
            }
        }, 1000);
    }

    updateExpiredTime() {
        this.timeoutTracker = setTimeout(() => {
            localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
        }, 300);
    }

    tracker = () => {
        window.addEventListener("mouseover", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }

    cleanUp() {
        console.log(`Starting cleanup!`);
        console.log(`Remove the _expiredTime key from localStorage`);
        localStorage.removeItem('_expiredTime');
        clearUserToken();

        clearInterval(this.interval);

        window.removeEventListener("mouseover", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
    }
}

export default IdleTimer;