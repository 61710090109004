import React from 'react';
import { PrivateRoute } from 'routes';

import Settings from './Settings';
import ChangePassword from './components/ChangePassword';


export const settingsPaths = {
    settings: '/settings',
    changePassword: '/change-password'
};

export const settingsRoutes = (
    [
        <PrivateRoute
            key={settingsPaths.settings}
            exact path={settingsPaths.settings}
            path={settingsPaths.settings}
            component={Settings}
        />,
        <PrivateRoute
            key={settingsPaths.changePassword}
            exact path={settingsPaths.changePassword}
            path={settingsPaths.changePassword}
            component={ChangePassword}
        />
    ]
);