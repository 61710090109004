import React from 'react';
import { Img } from 'react-image'
import { Skeleton } from 'antd'
import './Image.styles.css'
import defaultImage from '../../assets/default_board.png'

export const styles = {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: '15px'
}

export const ImageComponent = ({ imageList = [], action }) => {
    const wrapper = imageList.length === 1 ? 'imageWrapper' : 'imagesWrapper'

    if (action && imageList.length > 0) {
        return (
            <div className='images'>
                {imageList && imageList.map( (image) => (
                    <div key={image.id} className={wrapper}>
                        <Img className='image' key={image.id} loader={<Skeleton.Image/>} src={image.image_url} />
                    </div>
                ) )}

                {action}
            </div>
        )
    } else if (action && imageList.length === 0) {
        return (
            <div className='images'>
                <Img className='image' loader={<Skeleton.Image/>} src={defaultImage} />

                {action}
            </div>
        )
    } else if (imageList.length > 0) {
        return (
            <div className='images'>
                {imageList && imageList.map((image) => (
                    <div key={image.id} className={wrapper}>
                        <Img className='image' key={image.id} loader={<Skeleton.Image/>} src={image.image_url}/>
                    </div>
                ))}

                {action}
            </div>
        )

    } else {
        return <Img loader={<Skeleton.Image/>} src={imageList}/>
    }
}
