import React from 'react'
import BoardItem from '../DndBoardItem'
import {List} from "antd";
import service from "../../api/boardsService";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useDrop} from "react-dnd";
import './DndBoardList.styles.css'

const emptyText = {
    emptyText: 'No boards defined'
}

const listStyleDef = { margin: '10px', width: "100%" }

const DndBoardListComponent = (props) => {
    const {boards, menu} = props
    const [items, setItems] = React.useState(boards)
    let history = useHistory()
    const dispatch = useDispatch();

    const onDetail = (board) => {
        if (board) {
            dispatch(service.current(board))
            history.push(`/library/boards/${board.id}`)
        }
    }

    React.useEffect(() => {
        setItems(boards);
    }, [boards])

    const moveHandler = (dragIndex, hoverIndex) => {
        const dragItem = items[dragIndex];
        if (dragItem) {
            setItems((prevState => {
                const copiedStateArray = [...prevState];

                // remove item by "hoverIndex" and put "dragItem" instead
                const prevItem = copiedStateArray.splice(hoverIndex, 1, dragItem);

                // remove item by "dragIndex" and put "prevItem" instead
                copiedStateArray.splice(dragIndex, 1, prevItem[0]);

                return copiedStateArray;
            }));
        }
    };

    const [{canDrop, isOver}, drop] = useDrop({
        accept: 'BOARD',

        drop: () => ({source: 'BOARD'}),
        hover: (item, monitor) => {
        },

        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver() ,
        }),
    })

    return (
        <div ref={drop}>
        <List
            className="board-list"
            locale={emptyText}
            style={listStyleDef}
            grid={{gutter: 20}}
            dataSource={items}
            renderItem={(board, index) => (
                <List.Item onClick={() => onDetail(board)} >
                    <BoardItem content={{title: board.name, description: board.description}}
                               moveHandler={moveHandler} item={{...board, index: index}} index={index} menu={menu} />
                </List.Item>
            )}
        />
        </div>
    )
}

export default DndBoardListComponent

