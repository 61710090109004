import { Layout, Popover } from "antd";

import React, {createContext, useEffect, useState} from "react";
import { Link } from 'react-router-dom'

import {ReactComponent as PlanBuildSVG} from 'assets/Plan_Build.svg'

import {ReactComponent as PodcastSVG} from 'assets/Podcast.svg'
import {ReactComponent as Home} from 'assets/Home.svg'
import {ReactComponent as AnalyticsSVG} from 'assets/Analytics.svg'
import {ReactComponent as SettingsSVG} from 'assets/Settings.svg'

import { service } from "pages/private/user";
import { useDispatch, useSelector } from "react-redux";

import IdleTimer from "../../idleTimer";
import { clearUserToken, getDecodedToken } from "../../api/token";
import Tour from '../../components/Tour/Tour'

import './private.less'

export const TourContext = createContext(null);

const PrivateLayout = (props) => {
    const token = getDecodedToken();

    const [isTimeout, setIsTimeout] = useState(false);

    useEffect(() => {
        const timer = new IdleTimer({
            token: token ? token : 0, // current JWT
            onTimeOut: () => {
                setIsTimeout(true);
            },
            onExpired: () => {
                setIsTimeout(true);
            }
        });

        // TODO: This is causing the token
        //  to be cleared out of localStorage. When component re-renders.
        //  before I remove, will need to test out in staging.
        // return () => {
        //     timer.cleanUp();
        // }

    }, []);

    const user = useSelector(state => state.userState.user);
    const [collapsed, setCollapsed] = useState(false);
    const [tourContextState, setTourContextState] = useState(localStorage.getItem("tour"))

    const onCollapse = collapsed => {
        setCollapsed(collapsed)
    };

    const dispatch = useDispatch();

    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(service.userSignout())
        clearUserToken();
        window.location.href = '/login';
    }

    const toggleCollapse = (e) => {
        if (e.target.id === 'containerCollapsed') {
            onCollapse(!collapsed)
        }
    }
    /* We will control and update styling here for essentially what is the
    page layout for the entire site when authenticated. That way we don't need to
    apply initial padding on every single screen we create. */
    let style = {
        marginLeft: collapsed ? '16px' : '100px',
        paddingTop: '16px',
        paddingBottom: '16px',
        paddingRight: '16px',
        backgroundColor: 'white'
    }

    function resetTour() {
        setTourContextState(null)
        localStorage.removeItem('tour')
    }

    return (
        <TourContext.Provider value={{ tourContextState, setTourContextState, resetTour }}>
            <Layout style={{minHeight: '100vh'}}>
                <Layout.Sider style={{height: '100%', overflow: 'auto', position: 'fixed', left: 0}}
                              collapsible collapsed={collapsed}
                              onCollapse={onCollapse}
                              collapsedWidth={15}
                              onClick={toggleCollapse} width={100}>
                    {collapsed ? <CollapsedMenu user={user} logout={handleLogout}/> :
                        <ExpandedMenu user={user} logout={handleLogout}/>}
                    <Tour />
                </Layout.Sider>
                <Layout.Content style={style}>
                    { isTimeout }
                    {props.children}
                </Layout.Content>
            </Layout>
        </TourContext.Provider>
    );


}

const CollapsedMenu = () => {
    return (
        <div id='containerCollapsed' style={{
            height: '100%'
        }}>

            <div id='menu'
                 style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '0' }}>
            </div>
        </div>
    )
}

const ExpandedMenu = ({user, logout}) => {

    return (
        <div id='container' style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            // height: '100%'
        }}>

            <div id='menu'>
                <Popover
                    content={
                        <a style={{color: 'black'}} onClick={logout}>Sign Out</a>
                    }
                    placement="rightBottom"
                    title={(user && user.name) || 'User'}
                    trigger="hover"
                >
                    <Link to='/' id='home'>
                        <Home className='svg'/>
                    </Link>
                </Popover>
            </div>

            <a style={{marginTop: -70, marginBottom: -50, color: 'white'}}>Home</a>
                <div id='menu'>
                    <Link to='/podcasts' id='podcasts'>
                        <div className='circle'>
                            <PodcastSVG className='svg'/>
                        </div>
                    </Link>
                </div>

            <a style={{marginTop: -70, marginBottom: -50, color: 'white'}}>Podcasts</a>
                <div id='menu' className='library'>
                    <Link to='/library' id='library'>
                        <div className='circle'>
                            <PlanBuildSVG
                                className='svg'/>
                        </div>
                    </Link>
                </div>

            <a style={{marginTop: -70, marginBottom: -50, color: 'white'}}>Library</a>

            <div id='menu'>
                <Link to='/resources' id='resources'>
                    <div className='circle'>
                        <AnalyticsSVG
                            className='svg'/>
                    </div>
                </Link>
            </div>

            <a style={{marginTop: -70, marginBottom: -50, color: 'white'}}>Resources</a>

            <div id='menu'>
                <Link to='/user-management' id='user-management'>
                    <div className='circle'>
                        <SettingsSVG
                            className='svg'/>
                    </div>
                </Link>
            </div>

            <a style={{marginTop: -70, marginBottom: -50, color: 'white'}}>Users</a>

            <div id='menu'>
                <Link to='/settings' id='settings'>
                    <div className='circle'>
                        <SettingsSVG
                            className='svg'/>
                    </div>
                </Link>
            </div>

            <a style={{marginTop: -70, marginBottom: -50, color: 'white'}}>Settings</a>
        </div>
    )
}

export default PrivateLayout;
