import React from 'react';
import { Row, Col, Collapse } from 'antd';
import IntroCard from '../../../components/Home/IntroCard';

const Home = () => {
    return (
        <Row justify='start'>
            <Col span={20}>
                <h2 style={{paddingLeft: '16px'}} className='home-welcome'><b>Welcome Back!</b></h2>
                <IntroCard />
            </Col>
        </Row>
    )

}

export default Home
