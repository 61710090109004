import operations from 'api/superAgentWrapper'
import { podcastActionTypes } from './podcastState';
import {serverError} from "api/service";
import { storeToken} from 'api/token';

const current = (podcast) => {
    return async dispatch => {
        dispatch({type: podcastActionTypes.currentPodcast, payload: podcast} )
    }
};

const recent = (podcasts) => {
    return async dispatch => {
        dispatch({type: podcastActionTypes.recentPodcasts, payload: podcasts} )
    }
};

const sync = (podcast) => {
    return async dispatch => {
        dispatch({type: podcastActionTypes.syncPodcast, payload: podcast} )
    }
}

const fetch = (user) => {
    return async dispatch => {
        function onSuccess(response) {
            storeToken(response);
            dispatch({type: podcastActionTypes.podcastsFetchSuccess, payload: response.body} )
        }

        function onError(error) {
            let errorState = serverError(error, 'We were unable to retrieve your podcasts.');
            dispatch({type: podcastActionTypes.podcastsRequestFailure, payload: { error: errorState.message}} )
        }

        dispatch({type: podcastActionTypes.podcastsRequestInProgress})
        try {
            onSuccess(await operations.get('admin/podcasts', {user: user.id}))
        } catch (error) {
            onError(error)
        }
    }
};

async function update(podcast) {

        try {
            let res = await operations.put(`admin/podcasts/${podcast.id}`, {podcast: podcast})
            storeToken(res);
            return { success: true, message: 'The podcast has been updated', podcast: res.body}
        } catch (error) {
            console.log ("'Error updating podcasts", error)
            let errorState = serverError(error, 'We were unable to update the podcast.');
            return { success: false, message: errorState.message }
        }
};



export default  {
    fetch,
    update,
    current,
    recent,
    sync
}
