import dayjs from 'dayjs'

const secondsToStr= (seconds) => {
    let dt = dayjs(new Date(0, 0, 0, 0, 0, seconds))
    return dt.hour() ? dt.format('hh:mm:ss') : dt.format('mm:ss')
}

const secondsToDate = (seconds) => {
    return dayjs(new Date(0, 0, 0, 0, 0, seconds))
}

const dateToCreated = (dt) => {
    return dayjs(dt * 1000).format(`[Created] MM/DD/YY [at] hh:mm`)
}

const dateToSeconds = (dt) => {
    let seconds = dt.second();
    if (dt.minute() > 0) {
        seconds = seconds + (dt.minute() * 60)
    }
    if(dt.hour() > 0) {
        seconds = seconds + dt.hour() * 3600
    }
    return seconds
}

export default {
    dateToSeconds,
    secondsToStr,
    secondsToDate,
    dateToCreated
}
