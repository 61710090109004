import React from 'react'
import { PrivateRoute } from 'routes'

import Resources from './Resources'

export const resourcesPaths = {
    resources: '/resources'
};

export const resourcesRoutes = (
    [
        <PrivateRoute key={resourcesPaths.resources} exact path={resourcesPaths.resources} component={Resources}/>
    ]
);