const  HTTP_UNAUTHORIZED = 401;
const HTTP_NOTFOUND = 404;
const HTTP_CONFLICT = 409;
// This is an odd one - I needed to show an operations as 'in progress'
// and I couldn't think of anything else that was appropriate
const HTTP_ACCEPTED = 202;

export const serverError = (error, message) => {
    if (!error.status) {
        return {
            ...error,
            message: "We're sorry, we were unable to communicate with the server"
        }
    }
    if (error.status > 500) {
        return {
            ...error,
            message: "We're sorry, the server encountered an error"
        }
    }

    if (error.response.text) {
        return {
            ...error, message: JSON.parse(error.response.text).error
        }
    }

    return {
        ...error,
        message: error.message || message
    }
};


export const unauthorized = (error) => {
    return ( error.status && error.status === HTTP_UNAUTHORIZED)
};

export const notFound = (error) => {
    return ( error.status && error.status === HTTP_NOTFOUND)
};

export const accepted = (error) => {
    return ( error.status && error.status === HTTP_ACCEPTED)
};

export const conflict = (error) => {
    return ( error.status && error.status === HTTP_CONFLICT)
};

