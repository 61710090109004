import React from 'react'
import {Modal, Form, Button, message, Select} from 'antd'

import {useSmartState} from 'components/Hooks'

import 'pages/private/modal.less'


const EpisodeSelectModal = (props) => {

    const {availableEpisodes, visible, onSuccess, onCancel} = props
    const [form] = Form.useForm();
    const [episodeInfo, setEpisodeInfo] = useSmartState(null)

    const onSelectEpisode = (episode, value) => {
        setEpisodeInfo({title: value.children, id: episode})
    }

    const onClearEpisode = (episode, value) => {
        setEpisodeInfo(null)
    }

    const onSubmit = () => {
        if (episodeInfo?.id) {
            onSuccess(parseInt(episodeInfo.id))
        }
    }

    const onClose = () => {
        message.destroy()
        onCancel()
    }

    const afterClose = () => {
        form.resetFields()
    }

    return <Modal
        className='modal'
        width={400}
        destroyOnClose={true}
        closeable={false}
        visible={visible}
        afterClose={afterClose}
        title={"We can't find any available episodes with this title. Select the episode to add the content from the list below."}
        footer={[
            <Button key="submit" size='large' className='ant-button-modal' type="primary" onClick={(e) => {
                e.stopPropagation();
                onSubmit()
            }}>
                OK
            </Button>,
        ]}
        onCancel={onClose}
    >
        <Form
            form={form}
            size='large'
            layout="vertical"
            name="episodeSelect"
        >


            <Form.Item name='availableEpisodes'>
                <Select onSelect={onSelectEpisode} className='rounded'
                        allowClear
                        onClear={onClearEpisode}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder='Choose the episode'>
                    {availableEpisodes.map(episode => (
                        <Select.Option key={episode.id}>{episode.title}</Select.Option>
                    ))}
                </Select>
            </Form.Item>


        </Form>
    </Modal>
}

export default EpisodeSelectModal
