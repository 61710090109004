import _ from 'lodash';
import {destroy, update, append} from 'api/state'

const initialState = {
    status: 'idle',
    message: null,
    error: null,
    boards: [],
    currentBoard: null
};


export const boardActionTypes = {
    boardRequestInProgress: 'BOARD_REQUEST_IN_PROGRESS',
    boardRequestFailure: 'BOARD_REQUEST_FAILURE',
    boardRequestClear: 'BOARD_REQUEST_CLEAR',
    boardListSuccess: 'BOARDS_LIST_SUCCESS',
    boardListFailure: 'BOARDS_LIST_FAILURE',
    boardCreateRequest: 'BOARD_CREATE_REQUEST',
    boardCreateSuccess: 'BOARD_CREATE_SUCCESS',
    boardCreateFailure: 'BOARD_CREATE_FAILURE',
    boardUpdateRequest: 'BOARD_UPDATE_REQUEST',
    boardUpdateSuccess: 'BOARD_UPDATE_SUCCESS',
    boardUpdateFailure: 'BOARD_UPDATE_FAILURE',
    boardDestroyRequest: 'BOARD_DESTROY_REQUEST',
    boardDestroySuccess: 'BOARD_DESTROY_SUCCESS',
    boardDestroyFailure: 'BOARD_DESTROY_FAILURE',
    currentBoard: 'CURRENT_BOARD'
};

// reducer
const boardsState = (state = initialState, action) => {
    switch (action.type) {
        case boardActionTypes.currentBoard:
            if (_.isEqual(state.currentBoard, action.payload)) {
                return state
            }
            return {
                ...state,
                currentBoard: action.payload
            };
        case boardActionTypes.boardRequestInProgress:
            return {
                ...state,
                status: 'loading'
            };
        case boardActionTypes.boardRequestClear:
            return {
                ...state,
                status: 'idle',
                error: null,
                message: null
            };
        case boardActionTypes.boardRequestFailure:
            return {
                ...state,
                status: 'error',
                error: action.payload.error
            };
        case boardActionTypes.boardListSuccess:
            if (state.boards.length === 0 && action.payload.length === 0){
                return state
            }

            return {
                ...state,
                status: 'success',
                boards: action.payload
            };
        case boardActionTypes.boardCreateSuccess:
            return {
                ...state,
                status: 'success',
                boards: append(state.boards, action.payload)
            };
        case boardActionTypes.boardUpdateSuccess:
            return {
                ...state,
                status: 'success',
                boards: update(state.boards, action.payload)
            };
        case boardActionTypes.boardDestroySuccess:
            return {
                ...state,
                status: 'success',
                boards: destroy(state.boards, action.payload)
            };
        default:
            return state;
    }
};

export default boardsState;
