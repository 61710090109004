const initialState = {
    invitation: null
};

export const podcastCollaborateActions = {
    collaborateInvite: 'PODCAST_COLLABORATE_INVITE',
    collaborateAccept: 'PODCAST_COLLABORATE_ACCEPT'
};


export const podcastCollaborateActionTypes = {
    collaborateInviteRequest: 'PODCAST_COLLABORATE_INVITE_REQUEST',
    collaborateInviteSuccess: 'PODCAST_COLLABORATE_INVITE_SUCCESS',
    collaborateInviteFailure: 'PODCAST_COLLABORATE_INVITE_FAILURE',
    collaborateReset: 'PODCAST_COLLABORATE_RESET',

    collaborateDeleteRequest: 'PODCAST_COLLABORATE_DELETE_REQUEST',
    collaborateDeleteSuccess: 'PODCAST_COLLABORATE_DELETE_SUCCESS',
    collaborateDeleteFailure: 'PODCAST_COLLABORATE_DELETE_FAILURE',

    collaborateAcceptRequest: 'PODCAST_COLLABORATE_ACCEPT_REQUEST',
    collaborateAcceptSuccess: 'PODCAST_COLLABORATE_ACCEPT_SUCCESS',
    collaborateAcceptFailure: 'PODCAST_COLLABORATE_ACCEPT_FAILURE',

    collaborateDeclineRequest: 'PODCAST_COLLABORATE_DECLINE_REQUEST',
    collaborateDeclineSuccess: 'PODCAST_COLLABORATE_DECLINE_SUCCESS',
    collaborateDeclineFailure: 'PODCAST_COLLABORATE_DECLINE_FAILURE'
}

// reducer
const podcastCollaborateState = (state = initialState, action) => {
    switch (action.type) {
        case podcastCollaborateActionTypes.collaborateReset:
            return {
                ...state,
                invitation: null,
                success: null,
                error: null,
                isLoading: false
            }
        case podcastCollaborateActionTypes.collaborateInviteSuccess:
            return {...state,
                invitation: action.payload, success: 'Invitation'};
        case podcastCollaborateActionTypes.collaborateAcceptSuccess:
            return {...state,
                invitation: action.payload
            };
        default:
            return state;
    }
};



export default podcastCollaborateState;






