import React from 'react'
import {PrivateRoute} from 'routes'

import PodcastsList from './Podcasts'
import EpisodesList from './Episodes'
import Episode from './Episode'
import PodcastTokenProcessor from './components/TokenProcessor'

export const podcastsPaths = {
    podcasts: '/podcasts',
    episode: "/podcasts/:id/episodes/:id",
    podcast: "/podcasts/:id",
    claims: "/podcasts/claims/token/:token",
    collaborate: "/podcasts/invitations/token/:token"
};

export const podcastsRoutes = (
    [
        <PrivateRoute key={podcastsPaths.podcasts} exact path={podcastsPaths.podcasts} component={PodcastsList}/> ,
        <PrivateRoute key={podcastsPaths.podcast} exact path={podcastsPaths.podcast} component={EpisodesList}/>,
        <PrivateRoute key={podcastsPaths.episode} exact path={podcastsPaths.episode} component={Episode}/>,
        <PrivateRoute key={podcastsPaths.claims} path={podcastsPaths.claims} component={PodcastTokenProcessor}/>,
        <PrivateRoute key={podcastsPaths.collaborate} path={podcastsPaths.collaborate} component={PodcastTokenProcessor}/>
    ]
);


