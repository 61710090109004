import React from 'react'
import { Route } from 'react-router-dom'

import LoginForm from './Login'
import RegisterForm from './Register'
import RequestPasswordForm from './RequestPassword'
import ResetPasswordForm from './ResetPassword'


export const userPaths = {
    register: '/register',
    requestPassword: '/forgotPassword',
    login: '/login',
    resetPassword: '/admin/reset_password'

};

export const userRoutes = (
    [
        <Route key={userPaths.register} path={userPaths.register} component={RegisterForm} /> ,
        <Route key={userPaths.requestPassword} path={userPaths.requestPassword} component={RequestPasswordForm}/>,
        <Route key={userPaths.resetPassword} path={userPaths.resetPassword} component={ResetPasswordForm}/>,
        <Route key={userPaths.login} path={userPaths.login} component={LoginForm}/>,
    ]
);




