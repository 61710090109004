import React from 'react'
import { PrivateRoute } from 'routes'

import AnalyticsForm from './Analytics'

export const analyticsPaths = {
    analytics: '/analytics'
};

export const analyticsRoutes = (
      [
        <PrivateRoute key={analyticsPaths.analytics} path={analyticsPaths.analytics} component={AnalyticsForm} />
      ]
);


