import React from 'react'
import {List} from "antd";
import _ from 'lodash'
import {useDrop} from "react-dnd";
import MetaContentItem from '../DndMetaContentItem/DndMetaContentItem.component';
import './DndMetaContentList.styles.css'

const emptyText = {
    emptyText: 'No content defined'
}



const DndMetaContentListComponent = (props) => {
    const {contents, menu, selectAll, handleSelected} = props
    const [items, setItems] = React.useState(contents)
    console.log('select all', selectAll)


    const node = React.useRef()
    const [selected, setSelected] = React.useState([])

    if (items?.length !== contents?.length)
    {
        setItems(contents);
    }

    React.useEffect(() => {
        setItems(contents);
    }, [contents])

    React.useEffect(() => {
        handleSelected && handleSelected(selected);
    }, [selected]);

    React.useEffect(() => {
        if(selectAll && selected.length === 0){
            setSelected(contents)
        }
    }, [selectAll]);



    const updateSelected = (id, append) => {

        if (!append) {
            setSelected([id])
        }
        else
        {
           let index = _.findIndex(selected, id);
           if (index > -1) {
               let removed = JSON.parse(JSON.stringify(selected) )
               removed.splice(index, 1)
               setSelected(removed)
           }
           else
           {

               setSelected( [...selected, id] )
           }

        }
    }


    const isSelected = (id) => {
        // return selected ? (selected.id === content.id) : false
        return _.findIndex(selected, (e) => {
            return e === id;
        }) > -1
    }

    const handleClick = function (e, selected) {
        if (!selected) {
            setSelected([])
            return
        }
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
    };

    const onSelect = function(event, id){
        handleClick(event, true)
        updateSelected(id, event.shiftKey)
        event.stopPropagation()
    }

    const moveHandler = function(dragIndex, hoverIndex){
        const dragItem = items[dragIndex];
        if (dragItem) {
            setItems((prevState => {
                const copiedStateArray = [...prevState];

                // remove item by "hoverIndex" and put "dragItem" instead
                const prevItem = copiedStateArray.splice(hoverIndex, 1, dragItem);

                // remove item by "dragIndex" and put "prevItem" instead
                copiedStateArray.splice(dragIndex, 1, prevItem[0]);

                return copiedStateArray;
            }));
        }
    };

    const [{canDrop, isOver}, drop] = useDrop({
        accept: 'META_CONTENT',

        drop: () => ({source: 'META_CONTENT'}),
        hover: (item, monitor) => {
        },

        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver() ,
        }),
    })


    return (
        <div ref={node}>
            <List locale={emptyText}  onClick={handleClick}
                  grid={{gutter: 20}}
                  dataSource={items}
                  renderItem={(id, index) => (
                      <List.Item onClick={(e) => onSelect(e, id)}>
                          <MetaContentItem menu={menu} id={id}
                                           moveHandler={moveHandler}
                                           index={index} selected={isSelected(id)}/>
                      </List.Item>

                  )}
            />
        </div>
    )
}

DndMetaContentListComponent.whyDidYouRender=false
export default DndMetaContentListComponent

