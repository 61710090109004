import Row from "antd/es/row";
import Col from "antd/es/col";

import React from "react";
import {useSelector} from "react-redux";


export const ClaimsConfirm = (props) => {
    const rssEmail = useSelector(store => store.podcastClaimsState.rssEmail);
    let mailTo = 'mailto:' +  rssEmail
    return (

        <Row className='public' type='flex' align='top' justify='center'
             style={{backgroundColor: 'white', minHeight: '60%'}}>
            <Col span={8} align={'center'}>
                <div>
                    <p>Thank you! We have sent an email verification to the email address in the RSS feed. Be sure
                        to check your spam folder if you don't receive it within a few minutes.</p>
                    <a href={mailTo}> {rssEmail} </a>
                </div>
            </Col>
        </Row>
    )


}




