import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Alert from 'antd/es/alert';
import Spin from 'antd/es/spin';
import { Steps } from 'antd'
import Tooltip from 'antd/es/tooltip'

import {MailOutlined, CloseCircleOutlined} from '@ant-design/icons'
import React, {useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";

import {createLoadingSelector, createErrorMessageSelector, createSuccessSelector} from 'api/redux/selectors'
import {podcastClaimsActions, claimsService} from "./index";
import {podcastClaimsActionTypes} from "./api/claimsState";

import "../public.less";
import {ClaimsConfirm} from "./ClaimsConfirm";
import {ClaimsAssign} from "./ClaimsAssign";


const claimsLoadingSelector = createLoadingSelector([podcastClaimsActions.podcastClaims]);
const claimsErrorSelector = createErrorMessageSelector([podcastClaimsActions.podcastClaims]);
const claimsSuccessSelector = createSuccessSelector([podcastClaimsActions.podcastClaims]);

const assignLoadingSelector = createLoadingSelector([podcastClaimsActions.assignClaims]);
const assignErrorSelector = createErrorMessageSelector([podcastClaimsActions.assignClaims]);
const assignSuccessSelector = createSuccessSelector([podcastClaimsActions.assignClaims]);


const ClaimsWizard = (props) => {
    const [current, setCurrent] = useState(0);
    const [rssEmail, setRSSEmail] = useState(null);

    const [rssUrl, setRSSUrl] = useState(null);
    const [completedStep, setCompletedStep] = useState(null);

    const emailNotFound = useSelector(state => state.podcastClaimsState.emailNotFound);
    const urlInProgress = useSelector(state => state.podcastClaimsState.rssInProgress);
    const dispatch = useDispatch();

    const reset = () => {
        setCurrent(0)
        setRSSUrl(null)
        setRSSEmail(null)
        dispatch(claimsService.reset())
    }

    if (props.claimsSuccessMessage && !completedStep) {
        setCompletedStep('claimSearch');
        setCurrent(1)
    }

    if (props.assignSuccessMessage && completedStep === 'claimSearch') {
        setCompletedStep('claimAssign');
        setCurrent(2)
    }


    const onFinishStep1 = values => {
        setRSSEmail(values.email)
        setRSSUrl(values.rssUrl)
        if (values.rssUrl) {
            dispatch(claimsService.forUrl(values.email, values.rssUrl))
        } else {
            dispatch(claimsService.forEmail(values.email))
        }
    };

    return (

        <div style={{height: '100vh', backgroundColor: '#315663'}}>
            <Row type='flex' align='middle' justify='center' style={{backgroundColor: 'white', minHeight: '20%'}}>
                <Col style={{display: 'flex;', alignItems: 'center', justifyContent: 'center'}} span={6}>
                    <h1 className='public' style={{textAlign: 'center', fontSize: '42px'}}>Verify Your Podcast</h1>
                    <Steps current={current}>
                        <Steps.Step icon={current >= 0 ? <span className='dot-complete'/> : <span className='dot'/>}></Steps.Step>
                        <Steps.Step icon={current >= 1 ? <span className='dot-complete'/> : <span className='dot'/>}></Steps.Step>
                        <Steps.Step icon={current >= 2 ? <span className='dot-complete'/> : <span className='dot'/>}></Steps.Step>
                    </Steps>
                </Col>
            </Row>


            {completedStep == null && <ClaimsSearch>{props}</ClaimsSearch>}
            {completedStep === 'claimSearch' && <ClaimsAssign isLoading={props.assignLoading}/>}
            {completedStep === 'claimAssign' && <ClaimsConfirm />}


        </div>
    )


    function ClaimsSearch(props) {
        let theseprops = props
        return (
            <Row type='flex' align='top' justify='center' style={{backgroundColor: 'white', minHeight: '60%'}}>
                <Col span={12} align={'center'}>
                    <div>
                        <p style={{textAlign: 'center'}}>Enter the email address in your podcast's RSS feed</p>
                        <Form onFinish={onFinishStep1} className='public' size='large'
                              initialValues={{email: rssEmail, rssUrl: rssUrl}}>
                            {props.children.claimsErrorMessage &&
                            <Alert message={props.children.claimsErrorMessage} style={{width: '70%'}}
                                   className={'alert'} type="error" closable/>
                            }
                            <Spin spinning={props.children.claimsLoading} size='large' delay={500}>
                                <Form.Item name='email'
                                           rules={[{required: true, message: 'A valid email address is required.'},
                                               {type: 'email', message: 'Invalid email'}]}>

                                    <Input style={{width: '70%'}}
                                           className='rounded'
                                           prefix={<MailOutlined/>}
                                           disabled={emailNotFound}
                                           placeholder="RSS Email"
                                    />

                                </Form.Item>
                            </Spin>

                            {emailNotFound &&
                            <Form.Item>
                                <center><p>We were unable to locate any unclaimed podcasts with this email.
                                    You can enter the URL of your podcast as it appears in the Apple Store (for example:
                                    <b> https://podcasts.apple.com/us/podcast/My Podcast Ttile/id123456</b> )
                                    or the URL of the podcast's RSS feed itself (for example: <b> https://My Podcast Providers/podcast/rss</b>).

                                    <Tooltip title='Reset'>
                                        <Button type="link" style={{color: '#315663'}}
                                                onClick={reset}
                                                icon={<CloseCircleOutlined/>} sixe='small' shape="circle"> </Button>
                                    </Tooltip>
                                </p></center>
                            </Form.Item>
                            }

                            {emailNotFound &&
                            <Form.Item name='rssUrl'
                                       rules={[{required: emailNotFound, message: 'The url is required.'},
                                           {type: 'url', message: 'Invalid URL'}]}>
                                <Input style={{width: '70%'}} className='rounded' placeholder="Apple Store or RSS URL"/>
                            </Form.Item>
                            }
                            <Form.Item>
                                <Button style={{width: '20%'}} type='primary' htmlType="submit">
                                    SUBMIT
                                </Button>
                            </Form.Item>

                            <p>If the email address is associated with multiple podcasts,
                                you'll be able to select multiple podcasts.</p>
                            <p>If you don't know the email address used in your RSS feed
                                you can contact your hosting company, or open your RSS feed in a browser and search for
                                the email.</p>

                            <p style={{textAlign: 'center'}}><a href={'mailto:support@vodacast.com'}> Please contact
                                support@vodacast.com if you need help.</a></p>
                        </Form>
                    </div>

                </Col>
            </Row>
        )
    }


}

const mapStateToProps = state => {

    return {
        claimsLoading: claimsLoadingSelector(state),
        claimsErrorMessage: claimsErrorSelector(state),
        claimsSuccessMessage: claimsSuccessSelector(state),
        assignLoading: assignLoadingSelector(state),
        assignErrorMessage: assignErrorSelector(state),
        assignSuccessMessage: assignSuccessSelector(state)
    }
};

export default connect(mapStateToProps)(ClaimsWizard);


