import {serverError, unauthorized, notFound} from 'api/service'
import operations from 'api/superAgentWrapper'
import {
    userAuthActionTypes
} from './state';
import {storeToken} from "../../../../api/token";



const userSignin = (user) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: userAuthActionTypes.userSignin})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.post('admin/sign_in', user)
            storeToken(res);
            dispatch({type: userAuthActionTypes.userSigninSuccess, payload: res.body})
        } catch (error) {
            console.log("An error occurred authorizing the user ", error);
            if (unauthorized(error)) {
                dispatch({
                    type: userAuthActionTypes.userSigninFailure,
                    payload: {message: 'Invalid email or password.'}
                })
            } else {
                let errorState = serverError(error, 'Invalid email or password.');
                dispatch({type: userAuthActionTypes.userSigninFailure, payload: {message: errorState.message}})
            }

        }
    }
}


const resetPassword = (reset) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: userAuthActionTypes.resetPasswordRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.put('admin/reset_password', reset)
            storeToken(res);
            dispatch({type: userAuthActionTypes.resetPasswordSuccess, payload: {message: 'Success'} })
        } catch (error) {
            console.log("An error occurred attempting to send the password instructions ", error);
            if (notFound(error)) {
                dispatch({
                    type: userAuthActionTypes.resetPasswordFailure,
                    payload: {message: 'We were unable to find this email address in our database.'}
                })
            } else {
                let errorState = serverError(error);
                dispatch({type: userAuthActionTypes.resetPasswordFailure, payload: {message: errorState.message}})
            }


        }
    }
};

const forgotPassword = (email) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: userAuthActionTypes.forgotPasswordRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            await operations.post('admin/forgot_password', email)
            dispatch({type: userAuthActionTypes.forgotPasswordSuccess, payload: {message: 'Success'} })
        } catch (error) {
            console.log("An error occurred attempting to send the password instructions ", error);
            if (notFound(error)) {
                dispatch({
                    type: userAuthActionTypes.forgotPasswordFailure,
                    payload: {message: 'We were unable to find this email in our database.'}
                })
            } else {
                let errorState = serverError(error, 'Invalid email.');
                dispatch({type: userAuthActionTypes.forgotPasswordFailure, payload: {message: errorState.message}})
            }


        }
    }
};

const userRegistration = (user) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: userAuthActionTypes.userRegistrationRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.post('admin/admin_users', {admin_user: user})
            storeToken(res);
            dispatch({type: userAuthActionTypes.userRegistrationSuccess, payload: res.body})
        } catch (error) {
            console.log("An error occurred registering the user ", error);
            let errorState = serverError(error, 'We are unable to register you at this time, this email may already exist in the database.');
            dispatch({type: userAuthActionTypes.userRegistrationFailure, payload: {message: errorState.message}})
        }
    }
};


export default {
    userSignin,
    forgotPassword,
    resetPassword,
    userRegistration
}
