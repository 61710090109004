import React from 'react'
import {Col, Row, Space} from "antd";

const AnalyticsForm = () => {
    return (
        <div style={{margin: '10px', height: '100vh'}}>
            <Row>
                <Col>
                    <Space direction='vertical'>
                        <p style={{textAlign: 'left', fontSize: '16px'}}>Analytics</p>
                    </Space>
                </Col>
            </Row>
        </div>
    )
}

export default AnalyticsForm;
