import React, {useEffect, useState} from 'react'
import userManagementService from "./api/userManagementService";
import {Table, Tag, Space, message, Row, Button, Modal, Tabs} from "antd";
import {Text} from "../../../components/shared/Text";
import {PlusOutlined} from "@ant-design/icons";
import CollaborateModal from "../podcasts/components/CollaborateModal";
import _ from 'lodash';

const UserManagement = () => {
    const [podcastCollaborators, setPodcastCollaborators] = useState([])
    const [boardCollaborators, setBoardCollaborators] = useState([])
    const [inviteVisible, setInviteVisible] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    // tracking collaborator with podcastId to be removed, used within confirmation modal
    const [podcastCollaboratorId, setPodcastCollaboratorId] = useState(null)
    const [podcastId, setPodcastId] = useState(null)
    // retain list of podcasts
    const [podcastList, setPodcastList] = useState([])
    const [boardList, setBoardList] = useState([])

    const localUser = (JSON.parse(localStorage.getItem('persist:user')))

    const updatePodcastCollaborators = () => {
        const userEmail = JSON.parse(localUser['user'])['email']
        let tempPodcastList = [];
        let tempPodcastCollabList = [];
        let tempPodcastInviteList = [];
        userManagementService.fetchPodcastCollaborators()
            .then((res) => {
                res.podcasts.forEach(podcast => {
                    const localPodcastTitle = podcast.podcast_title;
                    const localPodcastId = podcast.podcast_id;
                    tempPodcastList.push({podcastTitle: localPodcastTitle, podcastId: localPodcastId})
                    podcast.collaborators.forEach(collaborator => {
                        if (collaborator.email === userEmail) return;
                        tempPodcastCollabList.push({...collaborator, podcastTitle: localPodcastTitle, podcastId: localPodcastId})
                    })
                    podcast.pending_invites.forEach(invite => {
                        tempPodcastInviteList.push({...invite, podcastTitle: localPodcastTitle, invitation_status: "PENDING", podcastId: localPodcastId})
                    })
                })
                if (res.success === false) {
                    message.error(res.message, 5)
                }
            }).then(() => {
                setPodcastList(tempPodcastList)
                setPodcastCollaborators(tempPodcastCollabList.concat(tempPodcastInviteList))
            });
    }

    const updateBoardCollaborators = () => {
        const userEmail = JSON.parse(localUser['user'])['email']
        let tempBoardList = [];
        let tempBoardCollabList = [];
        let tempBoardInviteList = [];
        userManagementService.fetchBoardCollaborators()
            .then((res) => {
                res.boards.forEach(board => {
                    const localBoardTitle = board.board_name;
                    const localBoardId = board.board_id;
                    tempBoardList.push({boardTitle: localBoardTitle, boardId: localBoardId})
                    board.collaborators.forEach(collaborator => {
                        if (collaborator.email === userEmail) return;
                        tempBoardCollabList.push({...collaborator, boardTitle: localBoardTitle, boardId: localBoardId})
                    })
                    board.pending_invites.forEach(invite => {
                        tempBoardInviteList.push({...invite, boardTitle: localBoardTitle, board_invitation_status: "PENDING", boardId: localBoardId})
                    })
                })
                if (res.success === false) {
                    message.error(res.message, 5)
                }
            }).then(() => {
                setBoardList(tempBoardList)
                setBoardCollaborators(tempBoardCollabList.concat(tempBoardInviteList))
            });
    }

    useEffect(() => {
        updatePodcastCollaborators()
        updateBoardCollaborators()
    }, [])

    const confirmDeleteCollaborator = (podcastCollaboratorId, podcastId) => {
        setShowConfirmationModal(true)
        setPodcastCollaboratorId(podcastCollaboratorId)
        setPodcastId(podcastId)
    }

    const handleRemove = () => {
        userManagementService.removeCollaborator(collaboratorId, podcastId)
            .then((res) => {
                if (res.success) {
                    message.success("Collaborator successfully remove", 3)
                    setShowConfirmationModal(false)
                    setPodcastCollaboratorId(null)
                    setPodcastId(null)
                }
                if (res.success === false) {
                    message.error(res.message, 5)
                    setShowConfirmationModal(false)
                    setPodcastCollaboratorId(null)
                    setPodcastId(null)
                }
            }).then(() => updatePodcastCollaborators())
    }

    const handleCancel = () => {
        setPodcastCollaboratorId(null)
        setPodcastId(null)
        setShowConfirmationModal(false)
    }
    const { TabPane } = Tabs;
    const boardColumns = [
        {
            title: 'Name',
            dataIndex: 'first_name',
            rowKey: 'name',
            render: (firstname, user) => <Text>{firstname ? firstname + ' ' + user.last_name : user.name}</Text>
        },
        {
            title: 'Board',
            dataIndex: 'boardTitle',
            rowKey: 'boardTitle',
            render: text => <Text>{text}</Text>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            rowKey: 'email',
            render: text => <Text>{text}</Text>
        },
        {
            title: 'Invitation Status',
            dataIndex: 'board_invitation_status',
            rowKey: 'board_invitation_status',
            render: status => (
                <Tag color={status === 'ACCEPTED' ? 'green' : 'geekblue'}>
                    {status === 'ACCEPTED_EXISTS' ? 'ACCEPTED EXISTS' : status}
                </Tag>
                     )
        }
    ]
    const podcastColumns = [
        {
            title: 'Name',
            dataIndex: 'first_name',
            rowKey: 'name',
            render: (firstname, user) => <Text>{firstname ? firstname + ' ' + user.last_name : user.name}</Text>
        },
        {
            title: 'Podcast',
            dataIndex: 'podcastTitle',
            rowKey: 'podcastTitle',
            render: text => <Text>{text}</Text>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            rowKey: 'email',
            render: text => <Text>{text}</Text>

        },
        {
            title: 'Invitation Status',
            dataIndex: 'podcast_invitation_status',
            rowKey: 'podcast_invitation_status',
            render: status => (
                <Tag color={status === 'ACCEPTED' ? 'green' : 'geekblue'}>
                    {status === 'ACCEPTED_EXISTS' ? 'ACCEPTED EXISTS' : status}
                </Tag>
                     )
        },
        {
           title: 'Relation',
           dataIndex: 'primary',
            rowKey: 'primary',
           render: primary => <Text>{primary === undefined ? 'N/A' : primary === true ? 'Co-Owner' : 'Collaborator'}</Text>
        },
        {
            title: 'Actions',
            rowKey: 'actions',
            render: (_, collaborator) => (
                ['ACCEPTED', 'ACCEPTED_EXISTS'].includes(collaborator.invitation_status)
                &&
                <a onClick={() => confirmDeleteCollaborator(collaborator.id, collaborator.podcastId)}>Remove</a>
            )
        }
    ]

    return (
        <>
        <Space direction='vertical' size='large'>
        <Text variant='title'>User Management</Text>
            <Row>
                <Button onClick={() => setInviteVisible(true)}>Invite Collaborator <PlusOutlined type='right'/></Button>
            </Row>
            <div />
        </Space>


        <Tabs defaultActiveKey="1">
            <TabPane tab="Podcasts" key="1">
                <Table columns={podcastColumns} dataSource={podcastCollaborators} rowKey={() => _.uniqueId() }/>
            </TabPane>
            <TabPane tab="Boards" key="2">
                <Table columns={boardColumns} dataSource={boardCollaborators} rowKey={() => _.uniqueId() }/>
            </TabPane>
        </Tabs>



            {inviteVisible && <CollaborateModal 
                podcasts={podcastList}
                visible={inviteVisible}
                onSuccess={() => {
                    updateBoardCollaborators()
                    updatePodcastCollaborators()
                    setInviteVisible(false)
                }}
                onCancel={() => setInviteVisible(false)}
            />}
            {showConfirmationModal && <Modal
                    title='Confirm Collaborator Removal'
                    visible={showConfirmationModal}
                    onOk={() => handleRemove()}
                    okButtonProps={{type: 'default'}}
                    okText='Confirm'
                    onCancel={() => handleCancel()}
                    centered
                    closable={false}
            >
                Are you sure you wish to remove this collaborator from your podcast?
            </Modal>
            }

        </>
    )
}

export default UserManagement