import React  from 'react'
import {_breadcrumbs} from "components/BreadCrumbs";
import {useSelector} from "react-redux";

const PodcastBreadcrumb =  ({ match }) =>  {
    let podcast = useSelector(store => store.podcastState.currentPodcast);
    return podcast && podcast.title
}


const EpisodeBreadcrumb =  ({ match }) =>  {
    let episode = useSelector(store => store.episodeState.currentEpisode);
    return episode &&  'Episode: ' + episode.title
}

const breadcrumbs = [
    {path: '/podcasts', breadcrumb: 'Podcasts'},
    {path: "/podcasts/:id", breadcrumb: PodcastBreadcrumb },
    {path: "/podcasts/:id/episodes/:id", breadcrumb: EpisodeBreadcrumb }
]

export const Breadcrumbs = () => {
    let Breadcrumbs = _breadcrumbs(breadcrumbs)
    return (
        <Breadcrumbs />
    )
}
