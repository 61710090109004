import request from 'superagent'
import config from 'utils/config'
import {getValidToken} from './token'

const unauthorizedRedirect = (req) => {
    req.on('response', (res) => {
        if (res.status === 401) {
            window.location.href = '/login'
        }
    })
}

const get = (url, params = {}) => {
    params.key = config.apiKey
    return request
        .get(`${config.host}/${url}`)
        .use(unauthorizedRedirect)
        .query(params)
        .set('Authorization', `Bearer ${getValidToken()}`)
        .accept('application/json');
};

const httpDelete = (url, params = {}) => {
    params.key = config.apiKey;
    return request
        .delete(`${config.host}/${url}`)
        .use(unauthorizedRedirect)
        .send(params)
        .set('Authorization', `Bearer ${getValidToken()}`)
        .accept('application/json');
};

const post = (url, params = {}) => {
    params.key = config.apiKey
    return request
        .post(`${config.host}/${url}`)
        .use(unauthorizedRedirect)
        .send(params)
        .query({key: config.apiKey})
        .set('Authorization', `Bearer ${getValidToken()}`)
        .accept('application/json');
};

const put = (url, params = {}) => {
    params.key = config.apiKey
    return request
        .put(`${config.host}/${url}`)
        .use(unauthorizedRedirect)
        .send(params)
        .set('Authorization', `Bearer ${getValidToken()}`)
        .accept('application/json');
};

const patch = (url, params = {}) => {
    params.key = config.apiKey
    return request
        .patch(`${config.host}/${url}`)
        .use(unauthorizedRedirect)
        .send(params)
        .set('Authorization', `Bearer ${getValidToken()}`)
        .accept('application/json');
};

const multi = (url, file, params = {}) => {
    params.key = config.apiKey

    return request
        .post(`${config.host}/${url}`)
        .use(unauthorizedRedirect)
        .field('content', JSON.stringify(params.content) )
        .attach('file', file, file.name)
        .query({key: config.apiKey})
        .set('Authorization', `Bearer ${getValidToken()}`)
        .accept('application/json');
}


export default {
    get,
    post,
    multi,
    put,
    patch,
    httpDelete
}
