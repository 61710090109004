import React from 'react'
import {Card, Tooltip} from 'antd'
import {ImageComponent} from 'components/Image/Image.component'
import {useHover} from 'components/Hooks'
import {cardStyle, cardSelectedStyle, cardBodyStyle as bodyStyle} from 'pages/styles'

import ItemMenu from 'pages/common/ItemMenu'



const EpisodeItem = (props) => {
    const {content, item} = props
    const selected = false
    const [hovered, eventHandlers] = useHover();
    const itemObj = {
        id: item.id,
        image_url: item.image_url
    }

    return (

        < Card
            {...eventHandlers}
            display={{backgroundColor: '#e0e0eb'}}
            size='small'
            hoverable
            bodyStyle={bodyStyle}
            style={selected === false ? cardStyle : cardSelectedStyle}
            cover={<ImageComponent imageList={[itemObj]}
                                   action={ItemMenu({...props, hover: hovered})}/>}

        >
            <Tooltip title={content.title}>
                <Card.Meta {...content} />
            </Tooltip>
        </Card>)

}

export default EpisodeItem

