import {STATUS} from "react-joyride";
import {message} from "antd";

export const setTourViewed = (status, initialTour = false) => {
    if (initialTour) {
        localStorage.setItem("tour", "1");
    }
    let nextPhase = parseInt(localStorage.getItem("tourPhase")) + 1
    localStorage.setItem("tourPhase", nextPhase.toString())
    if (status === STATUS.SKIPPED) {
        message.success('You can restart the tour at any time from the home page.', 3)
    }
};