import React, { useEffect, useState, createRef, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import _ from 'lodash';
import {Upload, Button, Input, message, Row, Col, Layout, Spin, Tooltip, Alert} from 'antd';
import { UploadOutlined, CopyOutlined } from '@ant-design/icons';
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.less';
import { EpisodeTimeline } from './components/EpisodeTimeline'
import { contentActions, contentActionTypes } from './api/episodeState'
import contentService from './api/episodeContentService'
import episodeService from './api/episodeService'
import { TimelineToolbar } from './components/TimelineToolbar/TimelineToolbar.component'
import { createStatusSelectors } from 'api/redux/selectors'
import List from "./components/EpisodeContentList";
import uploadfile from './api/upload'
import EpisodeMetaContentModal from './components/EpisodeMetaContentModal'
import EpisodeSelectModal from './components/EpisodeSelect'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSmartState } from 'components/Hooks'
import Feedback from 'pages/common/Feedback'
import { episodeActionTypes } from './api/episodeState'
import { Breadcrumbs } from './components/Breadcrumb';
import EpisodeTour from "../../../components/Tour/EpisodeTour";
import {createSelector} from "reselect";



function audioSource(uri) {
    if (uri) {
        return 'Source: ' + decodeURI(uri)
    } else {
        return 'No Audio defined - you can upload a local audio file or load from a remote URL'
    }
}

function available(episode) {
    return episode.status === null
}

const Episode = (props) => {
    let contents = useSelector(store => store.episodeState.content);
    let currentContent = useSelector(store => store.episodeState.currentContent);
    // let episodes =  useSelector(store => store.episodeState.episodes)
    let availableEpisodes = useSelector(store => store.episodeState.episodes).filter(available)
    const status = useSelector(store => store.episodeState.status);
    const feedback = useSelector(store => store.episodeState.message);

    const [uploadStatus, setUploadStatus] = useState('')
    const [saved, setSaved] = useState(false)
    const [contentUpdated, setContentUpdated] = useState(Date.now())
    const currentEpisode = useSelector(store => store.episodeState.currentEpisode);
    const currentPodcast = useSelector(store => store.podcastState.currentPodcast);
    const [localContents, setLocalContents] = useState([])
    const sortedContents = _.orderBy(_.filter(contents, content => content.starts_at), ({starts_at}) => starts_at || 100000, ['asc']);
    const unassignedContents = _.filter(contents, content => !content.starts_at)
    
    const player = createRef()
    let dispatch = useDispatch();
    const playbackPosition = useRef(player.current?.audio?.current.currentTime || 0)
    
    let isLoading = status === 'loading'
    let isSuccess = status === 'success'
    let isError = status === 'error'
    const feedbackRef = useRef(false)

    function webFeed(encode) {
        if (currentEpisode?.webfeed) {
            return encode === true ? encodeURI(currentEpisode?.webfeed) : currentEpisode?.webfeed
        }

        return null
    }
        
    useEffect(() => {
        dispatch(contentService.fetch(currentEpisode))
    }, [currentEpisode, dispatch, contentUpdated]);


    const onEdit = (content) => {
        setContentUpdated(Date.now())
        dispatch(contentService.current(content))
        currentContent = content
        setDetailVisible(true)
    }

    const onDelete = (content) => {
        dispatch(contentService.destroy(currentEpisode, content))
        setContentUpdated(Date.now())
    }

    const itemMenu = {edit: onEdit, delete: onDelete}

    const onListenFn = (position) => {
        playbackPosition.current = position.target.currentTime
    }

    const getPlaybackPosition = () => {
        return playbackPosition.current
    }

    const validateAudio = (audioUrl) => {
        if (!audioUrl) {
            message.error('Please supply an audio url to load', 2)

            return false;
        }

        let url

        try {
            url = new URL(audioUrl);
        } catch (_) {
            message.error('Please supply a valid audio url to load', 2)

            return false;
        }

        if (url.origin.startsWith('file')) {
            message.error('Opening local files is security issue for most browsers. If you would like to use a local file please upload it first', 5)

            return false;
        }

        if (url.protocol === ('http:')) {
            message.error('Please use a secure protocol (https:...) - non secure audio urls are a security issue', 5)

            return false;
        }

        updateAudio(audioUrl)
    }

    const handleSortAsc = () => {
        const ascSorted = [...contents].sort(function(a, b) {
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        })
        setLocalContents(ascSorted)
    }

    const handleSortDesc = () => {
        const descSorted = [...contents].sort(function(a, b) {
            if (b.title < a.title) {
                return -1;
            }
            if (b.title > a.title) {
                return 1
            }
            return 0;
        })
        setLocalContents(descSorted)
    }

    const showSortedContent = () => {
        if(localContents.length){
            return localContents
        }
        return contents
    }

    const updateAudio = (resumableUrl) => {
        if (resumableUrl) {
            let url = resumableUrl.indexOf('?') === -1 ? resumableUrl : resumableUrl.substring(0, resumableUrl.indexOf('?'))

            dispatch(episodeService.update({...currentEpisode, url: url}))
        }
    }

    const uploadFile = ({onSuccess, onError, onProgress, file}) => {
        episodeService.audio_url(currentEpisode, file).then((response) => {
            let url = response.resumable_url

            uploadfile(url, file, onProgress).then((success) => {
                onSuccess('ok')
                updateAudio(url)
            }, (error) => {
                onError(error);
            })
        }, (error) => {
            onError(error)
        })
    }

    const triggerSuccess = () => {
        setUploadStatus('success')
        setTimeout(() => { setUploadStatus('')}, 2000)
    }

    const uploadButton = () => {
        return (
            <Col flex={1}>
            <Upload
                customRequest={uploadFile}
                accept={'audio/*'}
                showUploadList={false}
                onChange={({ file }) =>
                    {
                        if (file.status == 'uploading') {
                            setUploadStatus('uploading')
                        }
                        if (file.status == 'done') {
                            triggerSuccess()
                        }
                    }
                }>
                <Button className='rounded' icon={<UploadOutlined/>}>
                    Upload Local Audio
                </Button>
            </Upload>
                <Row align='center'>
                    {uploadStatus == 'uploading' && <Row align='center'><Alert message={`${uploadStatus.toUpperCase()}!`} type='info' /></Row>}
                    {uploadStatus && uploadStatus !== 'uploading' && <Alert message={`${uploadStatus.toUpperCase()}!`} type='success' showIcon/>}
                </Row>
            </Col>
        )
    }

    const copyToClipboard = (e) => {
        message.success('The Url was copied to the Clipboard', 1)
        let text = webFeed(true)
        const ta = document.createElement("textarea");
        ta.innerText = text;
        document.body.appendChild(ta);
        ta.select();
        document.execCommand("copy");
        ta.remove();

    };

    const [detailVisible, setDetailVisible] = useSmartState(false);

    const onCancelFn = function onModalCancel() {
        detailVisible && setDetailVisible(false)
        selectVisible && setSelectVisible(false)
    }

    const onUpdateFn = function onModalUpdate(content) {
        dispatch({type: contentActionTypes.contentUpdateSuccess, payload: content})
    }

    const [selectVisible, setSelectVisible] = useSmartState(false);

    const canPublish = () => {
        if (currentEpisode.discovery_id) {
            publish()

            setSaved(true)
        } else {
            setSelectVisible(true)
        }
    }

    const onEpisodeSelected = (id) =>{
        let episode = availableEpisodes.find(episode => episode.id === id)
        dispatch(episodeService.identify(currentEpisode, episode.id)).then(response => {

            if (response === 'success') {
                publish()
            }  else {
                message.error('Unable to update and publish the episode')
            }
        })
    }

    const feedBack = () => {
        if (!isError && !isSuccess) {
            feedbackRef.current = false

            return null
        }

        if (!feedbackRef.current) {
            feedbackRef.current = true

            return <Feedback singleton={feedbackRef} error={isError} success={isSuccess} content={feedback}
                             action={episodeActionTypes.episodesRequestReset}/>
        }
    }

    const reset = () => {
        dispatch(contentService.fetch(currentEpisode))
        setSaved(false)
    }

    const publish = (episode) => {
        dispatch(contentService.update_all(currentEpisode, contents)).then(response => {
            if (response === 'success') {
                dispatch(episodeService.publish(episode || currentEpisode , contents))
            }
        })
    }

    const save = () => {
        dispatch(contentService.update_all(currentEpisode, contents))
        setSaved(true)
    }

    const operations = {
        publish: canPublish,
        reset: reset,
        save: save
    }
    

    return (
        <DndProvider backend={HTML5Backend}>
            <EpisodeTour />
            <Layout style={{minHeight: '100vh'}}>
                <Layout.Content>
                    <Breadcrumbs />
                    <div style={{margin: '10px', height: '80vh'}}>
                        {detailVisible && <EpisodeMetaContentModal
                            episode={currentEpisode}
                            content={JSON.parse(JSON.stringify(currentContent))}
                            visible={detailVisible}
                            onSuccess={onUpdateFn}
                            onCancel={onCancelFn}
                        />}

                        {selectVisible && <EpisodeSelectModal
                            currentEpisode={{currentEpisode}}
                            availableEpisodes={availableEpisodes}
                            visible={selectVisible}
                            onSuccess={onEpisodeSelected}
                            onCancel={onCancelFn}
                        />}

                        <Row align={'space-between'}>

                            {/*{ feedBack() }*/}

                            <Col span={18}>
                                <Row>
                                    { currentEpisode?.status == 'PUBLISHED' &&
                                        <div style={ {textAlign: 'center', padding: '20px', width: '100%', } }>
                                            Sharable link of your episode feed:
                                            <span>
                                                <i>
                                                    <b>
                                                        <a target='blank' href={webFeed(true)}>
                                                            { webFeed() }
                                                        </a>
                                                    </b>
                                                </i>
                                            </span>

                                            <Tooltip title='Copy the URL to the clipboard' placement='bottom'>
                                                <CopyOutlined onClick={copyToClipboard} />
                                            </Tooltip>
                                        </div>
                                    }

                                    <p>
                                        The audio player and timeline help position content in your episode - upload
                                        an audio file, or provide a link, then drag and drop content to the timeline.
                                    </p>

                                    <AudioPlayer ref={player}
                                                 autoPlay={false}
                                                 style={{}}
                                                 src={currentEpisode.url}
                                                 onListen={onListenFn}
                                                 customProgressBarSection={
                                                     [
                                                         RHAP_UI.CURRENT_TIME,
                                                         RHAP_UI.PROGRESS_BAR,
                                                         RHAP_UI.CURRENT_LEFT_TIME,
                                                     ]
                                                 }
                                                 customAdditionalControls={
                                                     [uploadButton(),
                                                         <Input.Search
                                                             placeholder='Enter a valid URL to load audio from'
                                                             enterButton='Load URL' style={{marginLeft: '4px', width: '100%'}}
                                                             allowClear
                                                             className='rounded' onSearch={validateAudio}/>]
                                                 }
                                                 header={audioSource(currentEpisode.url)} className='rounded' />
                                </Row>

                                <Spin size='large' spinning={isLoading} delay={200}>


                                    <button onClick={handleSortAsc} style={{marginTop: '30px', borderRadius: '15px', marginLeft: '15px'}}>Sort by ascending</button>
                                    <button onClick={handleSortDesc} style={{marginBottom: '70px', borderRadius: '15px', marginLeft: '15px'}}> Sort by descending</button>
                                
                                    <List contents={showSortedContent()} menu={itemMenu}
                                          getPlaybackPosition={getPlaybackPosition}
                                    />
                                    



                                    
                                </Spin>
                            </Col>

                        </Row>

                    </div>

                    <TimelineToolbar saved={saved} operations={operations} />
                </Layout.Content>
                <Col>
                    <Layout.Sider style={{height: '100%', overflow: 'auto', position: 'fixed',  right: 0}} width={300} className='episode-timeline'>
                        <EpisodeTimeline style={{marginLeft: '10px'}}
                                         contents={sortedContents}
                                         getPlaybackPosition={getPlaybackPosition}
                        />
                    </Layout.Sider>
                </Col>
            </Layout>
        </DndProvider>
    )

}

export default createStatusSelectors([contentActions.contentFetch, contentActions.contentDelete], Episode)
