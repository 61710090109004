import React, { useEffect, useState } from 'react'
import {Card, Tooltip} from 'antd'

import {cardStyle, cardSelectedStyle, cardBodyStyle as bodyStyle} from 'pages/styles'
import { useHover } from "components/Hooks";
import { ImageComponent } from 'components/Image/Image.component'
import ItemMenu from 'pages/common/ItemMenu'

import { useDrag, useDrop } from "react-dnd";

import metaContentService from "../api/metaContentService";
import defaultImage from 'assets/default_board.png'


const BoardItem = (props) => {
    const{ content, selected, moveHandler, item, index} = props
    const [hovered, eventHandlers] = useHover();

    let ref = React.useRef()
    const [, drop] = useDrop({
        accept: 'BOARD',
        hover(item, monitor) {
            if (!ref.current || !moveHandler) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.left - hoverBoundingRect.right) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.x - hoverBoundingRect.right;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveHandler(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });

    const [{isDragging}, drag] = useDrag({
        type: 'BOARD',
        item: item,
        end: (board, monitor) => {
            const dropResult = monitor.getDropResult()
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [metaContents, setMetaContents] = useState(null);

    // Fetch meta_content from API endpoint using hook
    useEffect(() => {
        metaContentService.fetchBoardMetaContent(item.id)
            .then( data => {
                // Create a new object array from metaContent
                const images = data.metaContent.map(content => (
                    {
                        id: content.id,
                        image_url: content.image_url ?? defaultImage
                    }
                ));

                // Update state
                // returns only the first 4
                // as we limit the amount of
                // cover images.
                setMetaContents(images.slice(0, 4));
            })
    }, []);

    drag(drop(ref))

    return (
        <div ref={ref} style={{ width: '200px' }}>
            <Card
                {...eventHandlers}
                siz='small'
                hoverable
                bodyStyle={bodyStyle}
                style={selected === false ? cardStyle : cardSelectedStyle}
                cover={<ImageComponent imageList={metaContents ? metaContents : []}
                                       action={ItemMenu({...props, hover: hovered})}/>}
            >
                <Tooltip title={content.title}>
                    <Card.Meta { ... content }/>
                </Tooltip>
            </Card>
        </div>
    )
}

export default BoardItem

