import React from "react";
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Alert from 'antd/es/alert';
import Checkbox from 'antd/es/checkbox';
import {MailOutlined, LockOutlined} from '@ant-design/icons'

import {service, userAuthActions} from './index'
import {useDispatch} from "react-redux";

import {createStatusSelectors} from 'api/redux/selectors'


import {Redirect} from "react-router-dom";
import {PublicHeader} from "./Header";
import {actionFromProps, fromLocation} from "./utils";

const title = 'Create an account'
const RegisterForm = (props) => {
    const dispatch = useDispatch()
    const [form]  = Form.useForm()
    console.log(props)

    const register = values => {
        form.setFieldsValue(values)
        if (userAction) {
            service.userRegistration({...values, ...userAction})
        }
        else
        {
            dispatch(service.userRegistration(values))
        }
    }

    const userAction = actionFromProps(props.location.state)
    const {from} = fromLocation(userAction, props.location.state)

    return (props.success ? (
        <Redirect to='/' /> ) : (
        <div style={{height: '100vh', backgroundColor: '#315663'}}>
            <PublicHeader title={title} />
            {<RegisterUser>{props}</RegisterUser>}
        </div>
    ));

    function RegisterUser(props) {

        return (
            <Row type='flex' align='top' justify='center' style={{backgroundColor: 'white', minHeight: '60%'}}>
                <Col flex='600px' align={'center'}>
                    <div>
                        <Form className='public' size='large' form={form} onFinish={register}>

                            <Form.Item>

                                { props.children.error &&
                                <Alert message={props.children.error} type="error" className={'alert'} closable />
                                }

                                <p className='public'>Already have an account? Click <a href='/'> <u>here</u>
                                </a> to log in.</p>
                            </Form.Item>
                            <Form.Item>

                                <Form.Item name='first_name' style={{float: 'left', width: '45%'}}
                                           className='rounded'
                                           rules={[
                                    {required: true, message: 'Please enter a first name.'}]}>
                                    <Input
                                        className='rounded'
                                        placeholder="First Name"/>
                                </Form.Item>

                                <Form.Item name='last_name' style={{float: 'right', width: '45%'}} rules={[
                                    {required: true, message: 'Please enter a last name.'}]}>
                                    <Input
                                        className='rounded'
                                        placeholder="Last Name"/>
                                </Form.Item>
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name='email' style={{float: 'left', width: '45%'}} rules={[
                                    {type: 'email', message: 'Please enter a valid email address'},
                                    {required: true, message: 'An email address is required.'}]}>

                                    <Input className='rounded'
                                           prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                           placeholder="Email"
                                    />

                                </Form.Item>
                            </Form.Item>
                            <Form.Item name='passwords'>


                                <Form.Item name='password' style={{float: 'left', width: '45%'}}
                                           rules={[{required: true, message: 'A password is required.'}]}>

                                    <Input.Password
                                        className='rounded'
                                        prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                        placeholder="Password"
                                    />
                                </Form.Item>

                                <Form.Item name='password_confirm' style={{float: 'right', width: '45%'}}
                                           rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject('The passwords do not match!');
                                        },
                                    }),
                                ]}>

                                    <Input.Password
                                        className='rounded'
                                        prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                        placeholder="Confirm Password"
                                    />
                                </Form.Item>
                                
                            </Form.Item>
                            
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    SUBMIT
                                </Button>
                            </Form.Item>
                            <Form.Item name="tandc" noStyle >
                                <Checkbox checked={true} >Click here to indicate that you have read and agree to the terms presented in the <a href='/'><u>Terms and Conditions</u> </a> agreement and the <a href='/'><u>Privacy Policy</u></a></Checkbox>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default createStatusSelectors([userAuthActions.userRegistration], RegisterForm)


