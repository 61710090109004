import {append, destroy, update} from 'api/state'

const initialState = {
    status: 'idle',
    message: null,
    currentEpisode: null,
    currentContent: null,
    episodes: [],
    content: []
};

const success = {
    status: 'success',
}

export const episodeActions = {
    episodesFetch: 'EPISODES_FETCH',
    episodeFetch: 'EPISODE_FETCH',
    episodeUpdate: 'EPISODE_UPDATE',
    episodeCreate: 'EPISODE_CREATE',
    episodesSynchronize: 'EPISODES_SYNC',
    episodeDestroy: 'EPISODE_DESTROY',
};

export const contentActions = {
    contentFetch: 'CONTENT_FETCH',
    contentDelete: 'CONTENT_DESTROY',
};


export const episodeActionTypes = {

    episodesRequestInProgress: 'EPISODES_REQUEST',
    episodesRequestFailure:  'EPISODES_FAILURE',
    episodesRequestReset:  'EPISODES_RESET',
    episodesFetchRequest: 'EPISODES_FETCH_REQUEST',
    episodesFetchSuccess: 'EPISODES_FETCH_SUCCESS',
    episodesFetchFailure: 'EPISODES_FETCH_FAILURE',
    episodesPublishSuccess: 'EPISODE_PUBLISH_SUCCESS',
    episodeIdentifySuccess: 'EPISODE_IDENTIFY_SUCCESS',

    episodeFetchRequest: 'EPISODE_FETCH_REQUEST',
    episodeFetchSuccess: 'EPISODE_FETCH_SUCCESS',
    episodeFetchFailure: 'EPISODE_FETCH_FAILURE',
    episodesSyncRequest: 'EPISODES_SYNC_REQUEST',
    episodesSyncSuccess: 'EPISODES_SYNC_SUCCESS',
    episodesSyncFailure: 'EPISODES_SYNC_FAILURE',
    episodeUpdateRequest: 'EPISODE_UPDATE_REQUEST',
    episodeUpdateSuccess: 'EPISODE_UPDATE_SUCCESS',
    episodeUpdateFailure: 'EPISODE_UPDATE_FAILURE',
    episodeCreateRequest: 'EPISODE_CREATE_REQUEST',
    episodeCreateSuccess: 'EPISODE_CREATE_SUCCESS',
    episodeCreateFailure: 'EPISODE_CREATE_FAILURE',
    episodeDestroyRequest: 'EPISODE_DESTROY_REQUEST',
    episodeDestroySuccess: 'EPISODE_DESTROY_SUCCESS',
    episodeDestroyFailure: 'EPISODE_DESTROY_FAILURE',
    currentEpisode: 'CURRENT_EPISODE'
};

export const contentActionTypes = {
    contentRequestInProgress: 'CONTENT_REQUEST',
    contentRequestFailure:  'CONTENT_FAILURE',
    contentSaveSuccess: 'CONTENT_SAVE_SUCCESS',
    contentFetchRequest: 'CONTENT_FETCH_REQUEST',
    contentFetchSuccess: 'CONTENT_FETCH_SUCCESS',
    contentFetchFailure: 'CONTENT_FETCH_FAILURE',
    contentAddRequest: 'CONTENT_ADD_REQUEST',
    contentAddSuccess: 'CONTENT_ADD_SUCCESS',
    contentAddFailure: 'CONTENT_ADD_FAILURE',
    contentDeleteRequest: 'CONTENT_DESTROY_REQUEST',
    contentDeleteSuccess: 'CONTENT_DESTROY_SUCCESS',
    contentDeleteFailure: 'CONTENT_DESTROY_FAILURE',
    contentUpdateRequest: 'CONTENT_UPDATE_REQUEST',
    contentUpdateSuccess: 'CONTENT_UPDATE_SUCCESS',
    contentUpdateAllSuccess: 'CONTENT_UPDATE_ALL_SUCCESS',
    contentUpdateFailure: 'CONTENT_UPDATE_FAILURE',
    currentContent: 'CURRENT_CONTENT'
}

// reducer
const episodeState = (state = initialState, action) => {
    switch (action.type) {
        case episodeActionTypes.episodesRequestInProgress:
            return {
                ...state, status: 'loading'
            };
        case contentActionTypes.contentRequestInProgress:
            return {
                ...state, status: 'loading'
            };
        case episodeActionTypes.episodesRequestFailure:
            return {
                ...state,
                status: 'error',
                message: action.payload.error
            };
        case contentActionTypes.contentRequestFailure:
            return {
                ...state,
                status: 'error',
                message: action.payload.error
            };
        case episodeActionTypes.episodesRequestReset:
            return {
                ...state, status: 'idle', message: null
            };
        case episodeActionTypes.currentEpisode:
            return {
                ...state,
                currentEpisode: action.payload
            };
        case episodeActionTypes.episodesPublishSuccess:
            return {
                ...state,
                status: 'success',
                episodes: update(state.episodes, action.payload.episode),
                currentEpisode: action.payload.episode,
                message: action.payload.message
            };
        case episodeActionTypes.episodesFetchSuccess:
            console.log('===> episode fetch success ===')
            return {
                ...state,
                success,
                episodes: action.payload
            };
        case episodeActionTypes.episodeFetchSuccess:
            return {
                ...state,
                success,
                episodes: action.payload
            };
        case episodeActionTypes.episodeUpdateSuccess:
            return {
                ...state,
                success,
                episodes: update(state.episodes, action.payload),
                currentEpisode: action.payload
            };
        case episodeActionTypes.episodeIdentifySuccess:
            let deduped = { ...state, episodes: destroy(state.episodes, {id: action.payload.discard} )}
            return {
                ...state,
                status: 'success',
                episodes: update(deduped.episodes, action.payload.episode),
                currentEpisode: action.payload.episode
            };
        case episodeActionTypes.episodeDestroySuccess:
            return {
                ...state,
                success,
                currentEpisode: null,
                // if the episode has a discovery id it has been published to us by the discovery service
                // so a 'destroy' doesn't actually delete it - it will simply clear the meta content and change the status
                episodes: action.payload?.discovery_id ? update(state.episodes, action.payload) : destroy(state.episodes, action.payload)
            };
        case episodeActionTypes.episodesSyncSuccess:
            return {
                ...state,
                success,
                episodes: action.payload
            };
        case episodeActionTypes.episodeCreateSuccess:
            return {
                ...state,
                success,
                episodes: append(state.episodes, action.payload)
            };
        case contentActionTypes.currentContent:
            return {...state, currentContent: action.payload}
        case contentActionTypes.contentSaveSuccess:
            return {
                ...state,
                status: 'success',
                message: action.payload.message
            }
        case contentActionTypes.contentUpdateSuccess:
            return {
                ...state, success, content: update(state.content, action.payload), currentContent: action.payload
            }
        case contentActionTypes.contentDeleteSuccess:
            return {
                ...state,
                success,
                content: destroy(state.content, action.payload)
            };
        case contentActionTypes.contentFetchSuccess:
            return {
                ...state,
                success,
                content: action.payload
            };
        default:
            return state;
    }
};


const inProgress = (episodes) => {
    return episodes.filter(episode => episode.status === 'IN_PROGRESS')
}

const submitted = (episodes) => {
    return episodes.filter(episode => episode.status === 'PUBLISHED')
}

const available = (episodes) => {
    return episodes.filter(episode => episode.status === null)
}

export const episodeStatus = {
    inProgress: inProgress,
    submitted: submitted,
    available: available
}


export default episodeState;






