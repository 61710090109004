import operations from 'api/superAgentWrapper'
import {storeToken} from "../../../../api/token";
import {serverError} from "../../../../api/service";

async function fetchPodcastCollaborators() {
    try {
        let res = await operations.get('admin/admin_users/podcast_collaborators')
        storeToken(res);
        return {success: true, podcasts: res.body}
    } catch (error) {
        console.log("Error fetching podcast collaborators", error)
        let errorState = serverError(error, 'We were unable to fetch podcast collaborators.');
        return {success: false, message: errorState.message}
    }
};
async function fetchBoardCollaborators() {
    try {
        let res = await operations.get('admin/admin_users/board_collaborators')
        storeToken(res);
        return {success: true, boards: res.body}
    } catch (error) {
        console.log("Error fetching board collaborators", error)
        let errorState = serverError(error, 'We were unable to fetch board collaborators.');
        return {success: false, message: errorState.message}
    }
};

async function removePodcastCollaborator(userId, podcastId) {
    try {
        let res = await operations.httpDelete(`admin/admin_users/${userId}/podcasts/${podcastId}/remove_collaborator`)
        storeToken(res);
        return {success: true}
    } catch (error) {
        console.log("Error removing collaborator", error)
        let errorState = serverError(error, "We were unable to remove the collaborator. Please try again");
        return {sucess: false, message: errorState.message}
    }
}

export default {
    fetchBoardCollaborators,
    fetchPodcastCollaborators,
    removePodcastCollaborator
}