import _ from "lodash";
import { createSelector } from 'reselect'

const initialState = {
    podcasts: [],
    status: 'idle',
    error: null,
    currentPodcast: null,
    recentPodcasts: []
};

export const podcastActions = {
    podcastsFetch: 'PODCASTS_FETCH',
    podcastsUpdate: 'PODCASTS_UPDATE',
    podcastsClaim: 'PODCASTS_CLAIM'
};

export const podcastActionTypes = {
    podcastsRequestClear: 'PODCASTS_CLEAR',
    podcastsRequestInProgress: 'PODCASTS_REQUEST',
    podcastsFetchSuccess: 'PODCASTS_FETCH_SUCCESS',
    podcastsRequestFailure: 'PODCASTS_FAILURE',
    podcastsUpdateSuccess: 'PODCASTS_UPDATE_SUCCESS',
    currentPodcast: 'CURRENT_PODCAST',
    recentPodcasts: 'RECENT_PODCAST',
    syncPodcast: 'SYNC_PODCAST'
};

// reducer
const podcastState = (state = initialState, action) => {
    switch (action.type) {
        case podcastActionTypes.currentPodcast:
            if (_.isEqual(state.currentPodcast?.id, action.payload)) {
                return state
            }
            return {...state,
                currentPodcast: state.podcasts.find(podcast => podcast.id === action.payload)};
        case podcastActionTypes.recentPodcasts:
            let recent = state.recentPodcasts
            return {...state,
                recentPodcasts: appendRecent(state, action.payload)};
        case podcastActionTypes.podcastsRequestInProgress:
            return {
                ...state,
                status: 'loading'
            };
        case podcastActionTypes.podcastsRequestClear:
            return {
                ...state,
                status: 'idle',
                error: null,
                message: null
            };
        case podcastActionTypes.podcastsRequestFailure:
            return {
                ...state,
                status: 'error',
                error: action.payload.error
            };
        case podcastActionTypes.podcastsFetchSuccess:
            return {...state,
                status: 'success',
                podcasts: action.payload, claimsComplete: true};
        case podcastActionTypes.podcastsUpdateSuccess:
            return {...state,
                status: 'success',
                podcasts: syncPodcast(state, action.payload)};
        case podcastActionTypes.syncPodcast:
            return {...state,
                currentPodcast: action.payload,
                podcasts: syncPodcast(state, action.payload)};
        default:
            return state;
    }
};

const syncPodcast = (state = initialState, podcast) => {
    let index = state.podcasts.findIndex(p => p.id === podcast.id)
    let podcasts = state.podcasts.slice()
    if (index !== -1) {
        podcasts[index] = podcast
    }
    return podcasts
}

const appendRecent = (state, podcast) =>
{
    let index = state.recentPodcasts.findIndex(p => p.id === podcast.id)
    let recentPodcasts = state.recentPodcasts.slice()
    if (index === -1) {
        recentPodcasts.push(podcast)
        if (recentPodcasts.length > 3) {
            recentPodcasts.pop()
        }
    }
    return recentPodcasts
}


export const isAuthor = (podcastId, user) => {
    return true
    // let index = podcast.authors.findIndex(author => author.email === user.email)
    // return index !== -1
}

export const isCollaborator = (podcast, user) => {
    return false
    // let index = podcast.collaborators.findIndex(collaborator => collaborator.email === user.email)
    // return index !== -1
}

export const selectPodcastIds = createSelector (
    state => state.podcasts,
    podcasts => podcasts.map(podcast => podcast.id)
)



export default podcastState;






