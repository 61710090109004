import operations from 'api/superAgentWrapper'
import {metaContentActionTypes} from './metaContentState';
import {serverError} from "api/service";
import {storeToken} from 'api/token';

const current = (content) => {
    return async dispatch => {
        dispatch({type: metaContentActionTypes.currentMetaContent, payload: content})
    }
};

const clear = () => {
    return async dispatch => {
        dispatch({type: metaContentActionTypes.clearMetaContent, payload: {}})
    }
};


const fetch = (op) => {
    return async dispatch => {
        let endpoint = op.board ? `admin/boards/${op.board.id}/contents` : `admin/vodacast_episodes/${op.episode.id}/contents`

        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: metaContentActionTypes.contentListRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.get(endpoint)
            storeToken(res);
            dispatch({type: metaContentActionTypes.contentListSuccess, payload: res.body})
        } catch (error) {
            console.log("'Error retrieving podcasts", error)
            let errorState = serverError(error, 'We were unable to retrieve the list of contents.');
            dispatch({type: metaContentActionTypes.contentListFailure, payload: {message: errorState.message}})

        }
    }
};




const destroy = (content) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: metaContentActionTypes.contentDestroyRequest})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.httpDelete(`admin/meta_content/${content.id}`)
            storeToken(res);
            dispatch({type: metaContentActionTypes.contentDestroySuccess, payload: res.body})
        } catch (error) {
            console.log("'Error retrieving podcasts", error)
            let errorState = serverError(error, 'We were unable to delete the content.');
            dispatch({type: metaContentActionTypes.contentDestroyFailure, payload: {message: errorState.message}})
        }
    }
};

async function upsert(content) {
    let updating = content.id
    try {
        let res = updating ? await operations.put(`admin/meta_content/${content.id}`, {content: content}) : await operations.post(`admin/meta_content`, {content: content})
        storeToken(res);
        let message = updating ? 'The content has been updated' : 'The content has been created'
        return {success: true, message: message, content: res.body}
    } catch (error) {
        console.log('Error upserting the content', error)
        let message = updating ? 'We were unable to update the content' : 'We were unable to create the content'
        let errorState = serverError(error, message);
        return {success: false, message: errorState.message}

    }
}


async function create(content) {
    try {
        let res = await operations.post(`admin/meta_content`, {content: content})
        storeToken(res);
        return {success: true, message: 'The content has been created', content: res.body}
    } catch (error) {
        console.log("'Error creating podcast", error)
        let errorState = serverError(error, 'We were unable to create the content.');
        return {success: false, message: errorState.message}

    }
}

async function update(content) {
    try {
        let res = await operations.put(`admin/meta_content/${content.id}`, {content: content})
        storeToken(res);
        return {success: true, message: 'The content has been updated', content: res.body}
    } catch (error) {
        console.log("'Error updating content", error)
        let errorState = serverError(error, 'We were unable to update the content.');
        return {success: false, message: errorState.message}

    }
}



async function store(file, content) {
    const fileInfo = file.originFileObj ?? file
    try {
        let res = await operations.multi(`admin/meta_content/upload`, fileInfo, {content: content})
        storeToken(res);
        return {success: true, message: 'The content has been updated', content: res.body}
    } catch (error) {
        console.log("'Error updating content", error)
        let errorState = serverError(error, 'We were unable to update the content.');
        return {success: false, message: errorState.message}
    }
}

async function preview(link) {
    try {
        let res = await operations.post(`admin/meta_content/preview`, {link: link})
        storeToken(res);
        return {success: true, message: 'The content has been located', preview: res.body}
    } catch (error) {
        console.log("'Error previewing content", error)
        return {success: false, message: 'We were unable to preview the content.'}
    }
}

async function fetchBoardMetaContent(boardId) {
    try {
        let res = await operations.get(`admin/boards/${boardId}/meta_content`);
        storeToken(res);

        return { success: true, message: 'Board has meta content available', metaContent: res.body }
    } catch (error) {
        console.log('There was an error retrieving meta content', error);

        return { success: false, message: 'Unable to retrieve meta content' }
    }
}

export default {
    upsert,
    store,
    clear,
    create,
    destroy,
    fetch,
    preview,
    update,
    current,
    fetchBoardMetaContent
}
