import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Steps } from 'antd'
import { useDrop } from "react-dnd";
import './timeline.less'
import _ from 'lodash'
import timeutils from "pages/helpers/timeutils";
import { canDropBackground } from  'pages/helpers/constants'
import EpisodeContentItem from './EpisodeContentItem'
import contentService from "../api/episodeContentService";
import { useDispatch } from "react-redux";
import episodeContentService from '../api/episodeContentService';


function toTimeline(content, index, callbacks) {
    let starts_at = timeutils.secondsToStr(content.starts_at)

    return <Steps.Step key={index}
                       title={starts_at}
                       description={<EpisodeContentItem content={{title: content.title, description: `Appearing at ${starts_at}`}} index={index} callbacks={callbacks} timePicker={true} item={{...content, source: 'TIMELINE', index: index}} selected={false} />} />}

const timelineModel = (contents, callbacks) => {
    return _.map(contents, (content, index) => toTimeline(content, index, callbacks))
}

export const EpisodeTimeline = (props) => {
    const currentEpisode = useSelector(store => store.episodeState.currentEpisode);
    const { contents } = props
    const [items, setItems] = useState(contents)
    let dispatch = useDispatch()
    const setStartsAt = (item) => {
        dispatch(contentService.edit(item)).then(() => {
            contentService.upsert(currentEpisode, item)
        })
    }
    
    useEffect(() => {
        setItems(contents)
    }, [contents])
    
    const reorderTimes = (index) => {
        let previous = 0
        
        if (items[index-1]) {
            previous = items[index-1].starts_at
        }
        
        let next = items[index].starts_at + 1
        
        if (items[index+1]){
            next =items[index+1].starts_at
        }
        
        let current = ( previous + next) / 2
        
        setStartsAt({...items[index], starts_at: current})
    }

    const handleMove = (dragIndex, hoverIndex) => {
        const dragItem = items[dragIndex];

        if (dragItem) {
            setItems((prevState => {
                const copiedStateArray = [...prevState];

                // remove item by "hoverIndex" and put "dragItem" instead
                const prevItem = copiedStateArray.splice(hoverIndex, 1, dragItem);

                // remove item by "dragIndex" and put "prevItem" instead
                copiedStateArray.splice(dragIndex, 1, prevItem[0]);

                return copiedStateArray;
            }));
        }
    };

    const handleDelete = (item) => { setStartsAt({...item, starts_at: null}) }

    const handleInsert = (item) => {
        let index = _.findIndex(items, function(items) { return items.id === item.id })
        reorderTimes(index)

        document.querySelector('[data-attr="Save Your Work"]').click()
        console.log('BOARD HAS SAVED!')
    }

    const [{canDrop, isOver}, drop] = useDrop({
        accept: ['CONTENT', 'TIMELINE'],
        drop: () => ({source: 'TIMELINE'}),
        hover: (item, monitor) => {},
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver() ,
        })
    })

    const getBackgroundColor = () => {
        if (isOver) {
            if (canDrop) {
                return canDropBackground;
            }
        } else {
            return "";
        }
    };

    let model = timelineModel(items,
            {
                handleMove: handleMove,
                handleDelete: handleDelete,
                handleInsert: handleInsert,
                setTime: setStartsAt
            }
        )

    return (
        <div ref={drop} style={{margin: '10px', width: "100%", backgroundColor: getBackgroundColor()}}>
            <div style={{fontSize: '25px'}}>
                {'Episode Feed'}
            </div>
            <Steps style={{color: 'white', height: '100vh'}}
                   progressDot
                   direction={'vertical'}
                   current={0}
            >
                {model}
            </Steps>
        </div>
    )
}

