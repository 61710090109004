import React, {useState} from 'react';
import {Form, Input, Button, message} from 'antd';
import podcastService from "../api/podcastService";
import {FacebookFilled, InstagramFilled, TwitterSquareFilled} from "@ant-design/icons";

const SocialMedia = ({ podcast = {}, onSuccess  }) => {
    const [form] = Form.useForm();
    const [disabledButton, setDisabledButton] = useState(true);

    const onSubmit = () => {
        setDisabledButton(true);

        form.validateFields().then((values) => {
            podcastService.update({
                id: podcast.id,
                twitter_url: values.twitter_url,
                instagram_url: values.instagram_url,
                facebook_url: values.facebook_url
            }).then((updated) => {
                if (updated.success === true) {
                    message.success(updated.message, 5)
                    onSuccess(updated.podcast)
                } else {
                    message.error(updated.message, 5)
                }
            })
        })
    }

    // Removes social media URL and updates the current podcast
    const removeSocialMedia = (socialMedia) => {
        const item = {};
        item[socialMedia] = null;
        item.id = podcast.id;
        podcastService.update(item);
        onSubmit();
    }

    const handleKeyDown = (event) => {
        if (event) {
            setDisabledButton(false);
        }
    }

    return (
        <Form
            form={form}
            size='large'
            layout="inline"
            initialValues={{
                instagram_url: podcast.instagram_url,
                facebook_url: podcast.facebook_url,
                twitter_url: podcast.twitter_url
            }}
        >
            <Form.Item name='facebook_url' >
                <Input
                    className='socialInputs'
                    allowClear
                    onChange={(event) => {
                        if (event.target.value === '') {
                            removeSocialMedia('facebook_url')
                        }
                    }}
                    prefix={<FacebookFilled style={{color: 'rgba(0,0,0,.25)'}}/>}
                    placeholder="Facebook"
                    onKeyDown={handleKeyDown}
                />
            </Form.Item>

            <Form.Item name='instagram_url'>
                <Input
                    className='socialInputs'
                    allowClear
                    onChange={(event) => {
                        if (event.target.value === '') {
                            removeSocialMedia('instagram_url')
                        }
                    }}
                    prefix={<InstagramFilled style={{color: 'rgba(0,0,0,.25)'}}/>}
                    placeholder="Instagram"
                    onKeyDown={handleKeyDown}
                />
            </Form.Item>

            <Form.Item name='twitter_url'>
                <Input
                    allowClear
                    onChange={(event) => {
                        if (event.target.value === '') {
                            removeSocialMedia('twitter_url')
                        }
                    }}
                    className='socialInputs'
                    prefix={<TwitterSquareFilled style={{color: 'rgba(0,0,0,.25)'}}/>}
                    placeholder="Twitter"
                    onKeyDown={handleKeyDown}
                />
            </Form.Item>
            <Button key="submit" size='large' className='ant-button-modal' type="primary" onClick={() => onSubmit()} id={'submit'} disabled={disabledButton}>
                Submit
            </Button>
        </Form>
    )
}

export default SocialMedia;

