import _ from 'lodash';
import {destroy, update, append} from 'api/state'

const initialState = {
    contents: [],
    currentMetaContent: null
};

export const metaContentActions = {
    list: 'CONTENTS_LIST',
    fetch: 'CONTENT_FETCH',
    create: 'CONTENT_CREATE',
    update: 'CONTENT_EDIT',
    preview: 'CONTENT_PREVIEW',
    destroy: 'CONTENT_DESTROY'
};

export const metaContentActionTypes = {
    contentListRequest: 'CONTENTS_LIST_REQUEST',
    contentListSuccess: 'CONTENTS_LIST_SUCCESS',
    contentListFailure: 'CONTENTS_LIST_FAILURE',       
    contentFetchRequest: 'CONTENT_FETCH_REQUEST',
    contentFetchSuccess: 'CONTENT_FETCH_SUCCESS',
    contentFetchFailure: 'CONTENT_FETCH_FAILURE',    
    contentCreateRequest: 'CONTENT_CREATE_REQUEST',
    contentCreateSuccess: 'CONTENT_CREATE_SUCCESS',
    contentCreateFailure: 'CONTENT_CREATE_FAILURE',    
    contentUpdateRequest: 'CONTENT_UPDATE_REQUEST',
    contentUpdateSuccess: 'CONTENT_UPDATE_SUCCESS',
    contentUpdateFailure: 'CONTENT_UPDATE_FAILURE',
    contentDestroyRequest: 'CONTENT_DESTROY_REQUEST',
    contentDestroySuccess: 'CONTENT_DESTROY_SUCCESS',
    contentDestroyFailure: 'CONTENT_DESTROY_FAILURE',
    currentMetaContent: 'CURRENT',
    clearMetaContent: 'CLEAR'
};

// reducer
const metaContentState = (state = initialState, action) => {
    switch (action.type) {
        case metaContentActionTypes.currentMetaContent:
            if (_.isEqual(state.currentMetaContent, action.payload))
            {
                return state
            }
            return {...state,
                currentMetaContent: action.payload};
        case metaContentActionTypes.clearMetaContent:
            return {...state,
                contents: [], currentMetaContent: null};
        case metaContentActionTypes.contentListSuccess:
            if (state.contents.length === 0 && action.payload.length === 0){
                return state
            }
            return {...state,
                contents: action.payload};
        case metaContentActionTypes.contentCreateSuccess:
            return {...state,
                contents: append(state.contents, action.payload)};
        case metaContentActionTypes.contentUpdateSuccess:
            return {...state,
                contents: update(state.contents, action.payload)};
        case metaContentActionTypes.contentDestroySuccess:
            return {...state,
                contents: destroy(state.contents, action.payload)};
        default:
            return state;
    }
};

export default metaContentState;
