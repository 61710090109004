import operations from 'api/superAgentWrapper'
import {boardActionTypes} from './boardsState';
import {serverError} from "api/service";
import {storeToken} from 'api/token';

const current = (board) => {
    return async dispatch => {
        dispatch({type: boardActionTypes.currentBoard, payload: board})
    }
};

const fetch = (user) => {
    return async dispatch => {
        function onSuccess(response) {
            storeToken(response);
            dispatch({type: boardActionTypes.boardListSuccess, payload: response.body})
        }

        function onError(error) {
            console.log("'Error retrieving the boards", error)
            let errorState = serverError(error, 'We were unable to retrieve the list of boards.');
            dispatch({type: boardActionTypes.boardRequestFailure, payload: {error: errorState.message}})
        }

        dispatch({type: boardActionTypes.boardRequestInProgress})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            onSuccess(await operations.get('admin/boards', {user: user.id}))
        } catch (error) {
            onError(error)
        }
    }
};

const destroy = (board) => {
    return async dispatch => {
        function onSuccess(response) {
            storeToken(response);
            dispatch({type: boardActionTypes.boardDestroySuccess, payload: response.body})
        }

        function onError(error) {
            console.log("'Error deleting the board", error)
            let errorState = serverError(error, 'We were unable to delete the board.');
            dispatch({type: boardActionTypes.boardRequestFailure, payload: {error: errorState.message}})
        }

        dispatch({type: boardActionTypes.boardRequestInProgress})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            onSuccess(await operations.httpDelete(`admin/boards/${board.id}`))
        } catch (error) {
            onError(error)
        }
    }
};

async function upsert(board) {
    let updating = board.id
    try {
        let res = updating ? await operations.put(`admin/boards/${board.id}`, {board: board}) : await operations.post(`admin/boards`, {board: board})
        storeToken(res);
        let message = updating ? 'The board has been updated' : 'The board has been created'
        return {success: true, message: message, board: res.body}
    } catch (error) {
        console.log('Error upserting the board', error)
        let message = updating ? 'We were unable to create the information' : 'We were unable to create the board'
        let errorState = serverError(error, message);
        return {success: false, message: errorState.message}
    }
}


export default {
    upsert,
    destroy,
    fetch,
    current
}
