import React from 'react';
import Tooltip from "antd/es/tooltip";
import './Toolbar.styles.css';


export const Button = (button) => {
    return <Tooltip title={button.tooltip}>
                <a onClick={button.onClick}>
                    {
                        button.dark ?
                            <div style={{margin: '10px 15ox 10px 15px', background: '#799BAF', hover: 'opacity: 0.3'}} className='circle'>
                                <button.logo fill='white' className='svg'/>
                            </div> :
                            <div style={{margin: '15px 20px', hover: 'opacity: 0.3'}} className='circle'>
                                <button.logo className='svg'/>
                            </div>
                    }
                </a>
           </Tooltip>
}


export const Toolbar = (buttons) => {
    return <div className='toolbar-container'>
              {buttons.children}
           </div>
}





