import { Form, Row, Col, Input, Button, Space, Spin, Table, message } from 'antd'
import {useDispatch, useSelector, batch} from "react-redux";
import React, {useState} from "react";
import {claimsService} from "./index";
import Highlighter from 'react-highlight-words';
import {MailOutlined, SearchOutlined} from '@ant-design/icons';

import _ from 'lodash';


export const ClaimsAssign = (props) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState()


    const claims = useSelector(store => store.podcastClaimsState.claims);
    const rssEmail = useSelector(store => store.podcastClaimsState.rssEmail);
    const dispatch = useDispatch();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };
    let searchInput = null;

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined style={{color: '#536A7'}}/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Podcast(s)',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
            ...getColumnSearchProps('title'),
        }
    ];


    const onChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onChange
    }


    const onFinishAssign = values => {
        if (claims.length === 1) {
            batch(() => {
                dispatch(claimsService.assign(_.map(claims, 'id'), rssEmail, values.originating_email))
                dispatch(claimsService.synchronize(_.map(claims, 'id')))
            })
        } else {
            if (selectedRowKeys.length === 0) {
                message.error("Please select at least one Podcast", 2)
            } else {
                batch(() => {
                    dispatch(claimsService.assign(selectedRowKeys, rssEmail, values.originating_email))
                    dispatch(claimsService.synchronize(selectedRowKeys))
                })
            }
        }
    };


    return (
        <Row type='flex' align='top' justify='center' style={{backgroundColor: 'white', minHeight: '60%'}}>
            <Col span={10} align={'center'}>
                <div>

                    <Form className='public' onFinish={onFinishAssign}>
                        {claims.length === 1 ?
                            <p> The following podcast is linked to <a href={'mailto:' + rssEmail}> {rssEmail} </a></p> :
                            <p> The following podcasts are linked to <a href={'mailto:' + rssEmail}> {rssEmail} </a></p>
                        }
                        <Spin spinning={props.isLoading} size='large' delay={500}>
                            <Form.Item>
                                {claims.length !== 1 ?
                                    <Table size='small'
                                           scroll={{y: 120}}
                                           columns={columns}
                                           bordered
                                           rowSelection={rowSelection}
                                           dataSource={claims}
                                           pagination={{defaultPageSize: 20, hideOnSinglePage: true}}/>
                                    :
                                    <p><Input disabled size='large' value={claims[0].title} style={{width: '70%'}}/></p>
                                }
                            </Form.Item>
                        </Spin>
                        {/*<Form.Item name='originating_email'*/}
                        {/*           rules={*/}
                        {/*               [{required: true, message: 'A valid email address is required.'},*/}
                        {/*                   {type: 'email', message: 'Invalid email'}]}>*/}

                        {/*    <Input style={{width: '70%'}}*/}
                        {/*           prefix={<MailOutlined/>}*/}
                        {/*           placeholder="Send emails to this email **TESTING ONLY**"*/}
                        {/*    />*/}

                        {/*</Form.Item>*/}


                        <Form.Item>
                            <Button type="primary" size='large' htmlType="submit">
                                SUBMIT
                            </Button>
                        </Form.Item>

                        <p>If the podcast you would like to claim is not shown, make sure the email address in the
                            RSS feed matches the email you entered</p>

                        <p style={{textAlign: 'center'}}><a href={'mailto:support@vodacast.com'}> Please contact
                            support@vodacast.com if you need help.</a></p>
                    </Form>
                </div>

            </Col>
        </Row>
    )

}

