import React from 'react'
const { useState, useMemo } = React;

export const useHover = () => {
    const [hovered, setHovered] = useState(false);

    const eventHandlers = useMemo(() => ({
        onMouseOver() { setHovered(true); },
        onMouseOut() { setHovered(false); }
    }), []);

    return [hovered, eventHandlers];
}

export const useSmartState = (defState) =>{
    const [state, setState] = React.useState(defState)

    function smartSetState(newState){
        if(state !== newState){
            setState(newState)
        }
    }

    return [state, smartSetState]
}

export const useJSONifyState = (defState) =>{
    const [state, setState] = React.useState(defState)

    function JSONifySetState(newState){
        if (JSON.stringify(state) !==JSON.stringify(newState)) {
            setState(newState)
        }
    }

    return [state, JSONifySetState]
}

export const useForceUpdate = () =>{
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
}

