import React from 'react'
import {Modal, Form, Spin, Input, Button, message} from 'antd'

import '../../modal.less'
import service from "../api/boardsService";


const BoardModal = ({board = {}, create = true, visible = false, onCancel, onSuccess}) => {

    const [form] = Form.useForm();
    let spin = false;
    form.setFieldsValue(board);


    const onUpsert = () => {
        form.validateFields().then((values) => {

            service.upsert({
                id: board.id,
                name: values.name,
                description: values.description
            }).then((response) => {
                if (response.success === true) {
                    message.success(response.message, 5, onClose)
                    onSuccess({ ...response.board, create: board.create})
                } else {
                    message.error(response.message, 5)
                }
            })
        })
            .catch((info) => {
                console.log('Contributor Validation Failed:', info);
            });
    }


    const onClose = () => {
        form.resetFields()
        message.destroy()
        onCancel()
    }

    let title = board.create ? 'New Board' : `${board.name}`

    return (board === null ? null :

        <Modal
            className='modal'
            width={400}
            closable={false}
            visible={visible}
            title={title}
            footer={[
                <Button key="submit" size='large' className='ant-button-modal' type="primary"
                        onClick={() => onUpsert()}>
                    SUBMIT
                </Button>,
            ]}
            onCancel={onClose}
        >
            <Form
                className='board-modal'
                form={form}
                size='large'
                layout="vertical"
                name="createBoardModal"
            >

                <Spin size='large' spinning={spin} delay={500}>
                    <Form.Item name='name'
                               rules={[{required: true, message: 'Please enter the name of your board'}]}>
                        <Input
                            className='rounded'
                            placeholder="Board Name"
                        />
                    </Form.Item>

                    <Form.Item name='description'>
                        <Input.TextArea rows={4}
                                        className='rounded'
                                        placeholder="Description (optional)"
                        />
                    </Form.Item>
                </Spin>

            </Form>
        </Modal>)
}

export default BoardModal
