import React from 'react'
import { PrivateRoute } from 'routes'

import Board from './Board'
import PlaningLibrary from './PlanningLibrary'

export const boardsPaths = {
    library: '/library',
    boards: '/library/boards/:id'
};

export const boardsRoutes = (

       [
           <PrivateRoute key={boardsPaths.library} exact path={boardsPaths.library} component={PlaningLibrary} />,
           <PrivateRoute key={boardsPaths.boards} path={boardsPaths.boards} component={Board} />
       ]

);


