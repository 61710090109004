import React from "react";

import Row from 'antd/es/row';
import Col from 'antd/es/col';

export const PublicHeader  = (props) => {
    return (

            <Row type='flex' align='bottom' justify='center' style={{backgroundColor: 'white', minHeight: '20%'}}>
                <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} span={6}>
                    <h1 className='public' style={{textAlign: 'center'}}>{props.title}</h1>
                    {props.component}
                </Col>
            </Row>


    )
}
