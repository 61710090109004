import React from 'react'
import { Route } from 'react-router-dom'

import ClaimsWizard from './ClaimsWizard'

export const podcastClaimsPaths = {
    claims: '/verifyyourpodcast'
};

export const podcastClaimsRoutes = (
    [
        <Route key={podcastClaimsPaths.claims} exact path={podcastClaimsPaths.claims} component={ClaimsWizard} />
    ]

);


