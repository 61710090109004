import React from 'react';
import { Button } from 'antd';
import { useHistory } from "react-router";
import AdOptIn from "./components/AdOptIn";
import './Settings.css';

const Settings = () => {
    let history = useHistory();

    const onSelect = () => {
        history.push('/change-password');
    };

    return (
        <section style={{width: '20rem'}}>
            <h2>Settings</h2>
            <section className={'update-password-container'}>
                <h3 style={{paddingTop: '5px'}}>Update password</h3>
                <Button className={'ant-button-modal password-form'}
                        onClick={onSelect}>Go</Button>
            </section>
            <AdOptIn/>
        </section>
    )
};

export default Settings;