import operations from 'api/superAgentWrapper'
import {serverError} from 'api/service'
import {
    tokenActionTypes,
    userActionTypes
} from './state';
import {storeToken} from "api/token";

const update = (id) => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: userActionTypes.userDetail})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            const res = await operations.put(`admin/admin_users/${id}`, {})
            dispatch({type: userActionTypes.userDetailUpdateSuccess, payload: res.body})
        } catch (error) {
            console.log("An error occurred retrieving the user information ", error)
            let errorState = serverError(error, 'An error occurred retrieving the user information .');
            dispatch({type: userActionTypes.userDetailUpdateFailure, payload: {message: errorState.message}})
        }
    }
};

const collaborators = () => {
   return async dispatch => {
       try {
           const res = await operations.get(`admin/admin_users/collaborators`, {})
           dispatch({type: userActionTypes.userCollaboratorsSuccess, payload: res.body})
       } catch (error) {
           console.log("An error occurred retrieving the list of collaborators ", error)
       }
   }
}


const userSignout = () => {
    return async dispatch => {
        // First dispatch: the app state is updated to inform
        // that the API call is starting.
        dispatch({type: userActionTypes.userSignout})
        // The function called by the thunk middleware can return a value,
        // that is passed on as the return value of the dispatch method.
        // In this case, we return a promise to wait for.
        // This is not required by thunk middleware, but it is convenient for us.
        try {
            await operations.httpDelete('admin/sign_in.json')
        } catch (error) {
            console.log("An error occurred attempting to logout of the app ", error)
        }
        dispatch({type: userActionTypes.userSignoutSuccess, payload: {}})
    }
};

const processToken = (token)  => {
    return async dispatch => {
        function onSuccess(response) {
            storeToken(response);
            dispatch({ type: tokenActionTypes.tokenActionSuccess,   payload: response })
        }

        function onError(error) {
            console.log('There was an error processing the outstanding token ', error)
        }

        try {
            onSuccess(await operations.post('admin/process_token', {token: token}))
        } catch (error) {
            onError(error)
        }
    }
}

export default {
    collaborators,
    processToken,
    update,
    userSignout
}
