import _ from 'lodash';
import {connect} from "react-redux";


export const createStatusSelectors = (actions, component) => {
    const loadingSelector = createLoadingSelector(actions);
    const errorSelector = createErrorMessageSelector(actions);
    const successSelector = createSuccessSelector(actions);

    const mapStateToProps = state => {
        return {
            isLoading: loadingSelector(state),
            error: errorSelector(state),
            success: successSelector(state)
        }
    };

    return  connect(mapStateToProps)(component)

}


export const createLoadingSelector = (actions) => (state) => {
    // returns true only when all actions is not loading
    return _(actions)
        .some((action) => _.get(state, `loadingReducer.${action}`));
};

export const createErrorMessageSelector = (actions) => (state) => {
    // returns the first error messages for actions
    // * We assume when any request fails on a page that
    //   requires multiple API calls, we shows the first error
    return _(actions)
        .map((action) => _.get(state, `errorReducer.${action}`))
        .compact()
        .first() || '';
}

export const createSuccessSelector = (actions) => (state) => {
    // returns the first error messages for actions
    // * We assume when any request fails on a page that
    //   requires multiple API calls, we show the first error
    return _(actions)
        .some((action) => _.get(state, `successReducer.${action}`));
}
