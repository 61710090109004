import React from 'react';
import {Img} from 'react-image'
import {Skeleton} from 'antd'

export const styles = {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: '15px'
}

export const Image = ({imageList = [], action}) => {

    if (action) {
        return <div className='ant-card-cover'><Img loader={<Skeleton.Image/>} src={imageList}>
        </Img>
            {action}
        </div>
    } else {
        return <Img loader={<Skeleton.Image/>} src={imageList}/>
    }
}
