import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector, shallowEqual} from "react-redux";

import {Button, Col, Row} from "antd";

import {ReactComponent as AddLogo} from "../../../assets/Add_New.svg";
import {ReactComponent as BoardLogo} from "../../../assets/Create_Feed.svg";

import {Button as ToolbarButton, Toolbar} from "../../../components/Toolbar/Toolbar.component"

import service from "./api/metaContentService";

import List from "./components/DndMetaContentList/DndMetaContentList.component";
import useFilter from "pages/common/Filter/Filter.component";

import MetaContentModal from './components/MetaContentModal'
import EpisodeContentModal from './components/EpisodeContentModal'
import {DEFAULT_META_CONTENT} from 'pages/helpers/constants'
import {useSmartState} from 'components/Hooks'

import {metaContentActionTypes} from "./api/metaContentState";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {shallowCopy as copyContent} from 'pages/helpers/utils'
import {createSelector} from "reselect";
import { Breadcrumbs } from './components/Breadcrumb';
import BoardTour from "../../../components/Tour/BoardTour";



const backend = HTML5Backend

const contentsSelector = state => state.metaContentState.contents
const userSelector = state => state.userState.user
const boardsSelector = state => state.boardsState.currentBoard

const selectContentIds = createSelector(
    contentsSelector,
    (contents) => contents.map(content => content.id)
    )
    
const Board = () => {
    let board = useSelector(boardsSelector);
    const contents = useSelector(contentsSelector);
    const [localContents, setLocalContents] = useState([])
    const contentIds = useSelector(selectContentIds, shallowEqual);
    
    
    const selectAllRef = useRef(false)
    
    const dispatch = useDispatch();
    
    let focused = useRef(DEFAULT_META_CONTENT)
    
    const selectedRef = React.useRef(null);
    
    function setSelected(selected) {
        selectedRef.current = selected
    }
    
    function setSelectAll(all) {
        selectAllRef.current = all
    }
    
    const handleSelected = React.useCallback(selected => {
        setSelected(selected);
    }, [])
    
    
    const [detailVisible, setDetailVisible] = useSmartState(false);
    const [episodeVisible, setEpisodeVisible] = useSmartState(false);
    
    
    const onUpsertFn = function onModalUpsert(content) {
        content.create ? dispatch({type: metaContentActionTypes.contentCreateSuccess, payload: content}) :
        dispatch({type: metaContentActionTypes.contentUpdateSuccess, payload: content})
    }
    
    const onCancelEpisodeFn = function onEpisodeCancel() {
        setEpisodeVisible(false)
        setSelectAll(false)
        
    }
    
    const onCancelFn = function onModalCancel() {
        setDetailVisible(false)
        focused.current = DEFAULT_META_CONTENT
    }
    
    const onDelete = React.useCallback((content) => {
        dispatch(service.destroy(content))
    }, [])
    
    const onEdit = React.useCallback((content) => {
        content.create = false;
        focused.current = content
        setDetailVisible(true)
    }, [])
    
    const [filterValue, filterComponent] = useFilter('Filter by name or title')
    
    const doFilter = () => {

        if (!filterValue) {
            if (localContents.length) {
                return localContents.map(content => content.id)
            }
            return contentIds

        }
        return (contents.filter(meta => meta.title.toLowerCase().includes(filterValue.toLowerCase()) ||
        meta.content_name.toLowerCase().includes(filterValue.toLowerCase()))).map(content => content.id);
    }
    
    
    useEffect(() => {
        dispatch(service.fetch({board: board}))
    }, [board, dispatch]);
    
    useEffect(() => {
        return () => {
            dispatch(service.clear())
            setSelected(null);
            setSelectAll(false)
        };
    }, [])
     
    function handleSortAsc(){
        const ascSorted = [...contents].sort(function(a, b) {
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
          
            // names must be equal
            return 0;
          })
          setLocalContents(ascSorted)
    }
    
    function handleSortDesc(){

        const descSorted = [...contents].sort(function(a, b) {
            if (a.title < b.title) {
              return 1;
            }
            if (a.title > b.title) {
              return -1;
            }
          
            // names must be equal
            return 0;
          })
          setLocalContents(descSorted)
    }
    


    const menu = {edit: onEdit, delete: onDelete}

    const contentList = function () {
        return (
            <List contents={doFilter()} menu={menu} handleSelected={handleSelected} selectAll={selectAllRef.current}/>
        )
    }

    const selectedIds = selectedRef.current?.length > 0 ? selectedRef.current : contentIds

    function metaContentModal() {
        return (
            <MetaContentModal
                boardId={board.id}
                content={focused.current.create ? copyContent(DEFAULT_META_CONTENT) : copyContent(focused.current)}
                visible={detailVisible}
                onSuccess={onUpsertFn}
                onCancel={onCancelFn}
            />
        )
    }

    return (

        <div style={{margin: '10px', height: '100vh'}}>
            <BoardTour />
            {detailVisible && metaContentModal()}


            {episodeVisible && <EpisodeContentModal visible={episodeVisible}
                                                    onSuccess={onCancelEpisodeFn}
                                                    onCancel={onCancelEpisodeFn}
                                                    content={selectedIds}
                // closeable={true}
            >

            </EpisodeContentModal>}
            <div style={{padding: '8px'}}>
                <Breadcrumbs />
            </div>

            <Row>
                <Col>
                    <p style={{textAlign: 'left', marginTop: '5px', fontSize: '16px'}}><i>Planning boards help organize content for your episodes - use the buttons at the bottom of the page to start creating or editing content.</i></p>
                    <p style={{textAlign: 'left', marginTop: '5px', fontSize: '16px'}}>Board: {board.name} </p>
                    <p></p>
                    <p style={{textAlign: 'left'}}>
                        {filterComponent}
                        <button onClick={handleSortAsc} style={{marginBottom: '70px', borderRadius: '15px', marginLeft: '15px'}}>Sort by ascending</button>
                        <button onClick={handleSortDesc} style={{marginBottom: '70px', borderRadius: '15px', marginLeft: '15px'}}>Sort by descending</button>
                    </p>
                </Col>
            </Row>

            <DndProvider backend={backend}>
                {contentList()}
            </DndProvider>

            <Toolbar>
                <Row>
                    <div className='add-content'>
                        <ToolbarButton tooltip='New Content'
                           onClick={() => {
                               focused.current.create = true;
                               setDetailVisible(true)
                           }}
                           logo={AddLogo}
                        />
                    </div>
                    <div className='publish-content'>
                        <ToolbarButton tooltip='Add selected content to a new Episode, if none selected adds the entire board'
                           onClick={() => {
                               setSelectAll(true);
                               setEpisodeVisible(true)
                           }} logo={BoardLogo}
                        />
                    </div>
                </Row>
                {/*<ToolbarButton tooltip='Edit Content' onClick={() => {*/}
                {/*    focused.current.create = false;*/}
                {/*    wrapDetailVisible(true)*/}
                {/*}} logo={EditLogo}/>*/}
                {/*<ToolbarButton tooltip='Delete' onClick={() => onDelete(focused.current)} logo={DeleteLogo}/>*/}
            </Toolbar>

        </div>
    )
}

Board.whyDidYouRender = false
export default Board;
