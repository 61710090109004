import React, {useState, useEffect} from 'react'
import {Modal, Form, Spin, Input, Select, Button, message, Row, Col, TimePicker} from 'antd'

import service from "../api/episodeContentService";
import defaultImage from "../../../../assets/default_board.png";

import timeutils from 'pages/helpers/timeutils';

import 'pages/private/modal.less'

const CONTENT_TYPES = [{
    id: 'IMAGE_UPLOAD', name: 'Image Upload'
},
    {id: 'LINK', name: 'Link'},
    {id: 'IMAGE_LINK', name: 'Image Link'},
    {id: 'VIDEO_LINK', name: 'Video'}]

const selectOptions = () => {
    let options = []
    CONTENT_TYPES.map(function (option) {
        options.push(<Select.Option key={option.id} value={option.id}>{option.name}</Select.Option>)
    })
    return options
}


const EpisodeMetaContentModal = (props) => {

    const {episode = {}, content, visible, onCancel, onSuccess} = props
    const [form] = Form.useForm();

    const [spinning, setSpinning] = useState(false)
    const [selected, setSelected] = useState(null)
    // either react or the browser is caching the old image , have to do this to work around it
    const [image, setImage] = useState(content?.image_url || defaultImage)

    const isUpload = () => {
        return 'IMAGE_UPLOAD' === selected
    }
    const isPreview = () => {
        return ['IMAGE_LINK', 'VIDEO_LINK', 'LINK'].includes(selected)
    }


    useEffect(() => {
        if (content?.image_url) {
            setImage(content.image_url);
        }
    }, [content.image_url])


    useEffect(() => {
        if (content?.starts_at){
          content.starts_at =  timeutils.secondsToDate(content.starts_at)
        }

        form.setFieldsValue(content)
        if (content) {
            setSelected(content.content_type);
        }
    }, [visible, form, content.id])

    form.setFields([{
        name: 'selected',
        value: selected
    }])

    const onSubmit = () => {
        setSpinning(true)
        let data = {}
        form.validateFields().then((values) => {
            data.id = content.id
            data.content_name = values.content_name
            data.image_url = content.image_url
            data.video_url = values.video_url
            data.description = content.description
            data.title = values.title
            data.content_type = values.selected
            data.url = values.url
            data.starts_at = values.starts_at ? timeutils.dateToSeconds(values.starts_at) : null


            service.upsert(episode, data).then((response) => {
                if (response.success === true) {
                    message.success(response.message, 5, onClose)
                    onSuccess({...response.content, create: content.create})
                } else {
                    message.error(response.message, 5)
                }
            })


        })
            .catch((info) => {
                console.log('Submit Failed:', info);
            });
        setSpinning(false)
    }


    const onClose = () => {
        setImage(defaultImage)
        message.destroy()
        onCancel()
    }

    const afterClose = () => {
        form.resetFields()
        setSelected(null)
        setSpinning(false)
        setImage(defaultImage)
    }


    const buttons = [<Button key="submit" size='large' className='ant-button-modal' type="primary"
                             onClick={() => onSubmit()}>
        Save
    </Button>]


    let preview = isPreview()
    let upload = isUpload()

    return (content === null || visible === false ? null :
            <Modal
                className='modal'
                width={600}
                closable={false}
                visible={visible}
                afterClose={afterClose}
                title={<div><i> {`${content.content_name || content.title}`}</i></div>}
                footer={buttons}
                destroyOnClose={true}
                onCancel={onClose}
            >
                <Form
                    form={form}
                    size='large'
                    layout="horizontal"
                    name="contentModal"
                >

                    <Spin size='large' spinning={spinning} delay={100}>
                        <Row type='flex' align='middle' justify='center'>
                            <Col flex='200px'>

                                <Form.Item name='image_url'>
                                    <img style={{
                                        borderRadius: '2em',
                                        alignSelf: 'center',
                                        width: '90%'
                                    }} src={content.image_url || defaultImage}/>
                                </Form.Item>

                            </Col>
                            <Col flex='auto'>

                                <Form.Item name='selected'>
                                    <Select className='rounded' disabled={false}
                                            placeholder='Select the Content Type'>
                                        {selectOptions()}
                                    </Select>
                                </Form.Item>

                                <Form.Item name='url' hidden={!preview}>
                                    <Input className='rounded' disabled={false} placeholder="Source URL"/>
                                </Form.Item>

                                <Form.Item name='content_name'>
                                    <Input
                                        className='rounded'
                                        disabled={false}
                                        placeholder="Enter a name for the content"
                                    />
                                </Form.Item>

                                <Form.Item name='title'>
                                    <Input
                                        disabled={false}
                                        className='rounded'
                                        placeholder="Caption "
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>

                            <Form.Item name='starts_at' label={<label style={{ color: "white" }}>Set the time in Episode when the content will appear</label>} >
                            <TimePicker placeholder='Set Offset' className='rounded'/>
                        </Form.Item></Row>
                    </Spin>

                </Form>
            </Modal>
    )
}

EpisodeMetaContentModal.whyDidYouRender = true
export default EpisodeMetaContentModal
