import React from "react";

import {itemStyle} from "../styles";
import {Button, Popconfirm} from "antd";
import {DeleteOutlined, EditOutlined, QuestionCircleOutlined} from "@ant-design/icons";

export const ItemMenu = (props) => {
    const {item, menu, hover, content} = props

    if (!menu) {
        return null
    }

    let styles = hover ? itemStyle : {display: 'none'}

    return (

        <div style={styles}>
            {menu.edit &&
                <Button icon={<EditOutlined/>} onClick={(e) => {
                    e.stopPropagation();
                    menu.edit(item)
                }} style={{margin: '2px'}} shape='circle'/>
            }

            {menu.delete &&
                <Popconfirm style={{borderRadius: '2em'}} title={<i>Are you sure you want to delete {content.title}?</i>}
                            okText='Delete'
                            cancelButtonProps={{style: {borderRadius: '2em'}}}
                            okButtonProps={{style: {fontSize: '14px'}}}
                            icon={<QuestionCircleOutlined style={{color: '#315663'}}/>}
                            onCancel={(e) => {
                                e.stopPropagation()
                            }}
                            onConfirm={(e) => {
                                e.stopPropagation();
                                menu.delete(item)
                            }}>
                    <Button icon={<DeleteOutlined/>} onClick={(e) => {
                        e.stopPropagation()
                    }} style={{margin: '2px'}} shape='circle'/>
                </Popconfirm>
            }
        </div>

    )
}

ItemMenu.whyDidYouRender = false
export default ItemMenu
