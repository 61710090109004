import React, {useReducer, useEffect, useState, useContext} from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import {useHistory } from "react-router";
import {setTourViewed} from "./helpers";
import {TourContext} from "../../pages/private/PrivateLayout";

const TOUR_STEPS = [
    {
        target: ".library",
        content: "Welcome to the Hub - let's build a Planning Board in the Planning Library.",
        // This makes the tour to start automatically without clicking,
        disableBeacon: true,
        disableOverlay: true,
        disableOverlayClose: false,
        hideCloseButton: false,
        placement: 'right',
        spotlightClicks: true
    },
    {
        target: ".toolbar-container",
        disableOverlay: true,
        content:
            "Click the plus button to start creating a board. Once you've created a board, select it to start adding content."
    }
];

const INITIAL_STATE = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "START":
            return { ...state, run: true, ...action.payload };
        case "RESET":
            return { ...state, stepIndex: 0 };
        case "STOP":
            return { ...state, run: false };
        case "NEXT_OR_PREV":
            return { ...state, ...action.payload };
        case "RESTART":
            return {
                ...state,
                stepIndex: 0,
                run: true,
                loading: false,
                key: new Date()
            };
        default:
            return state;
    }
};

// Tour component
const Tour = () => {
    const { tourContextState, setTourContextState } = useContext(TourContext)
    const history = useHistory()
    // Tour state is the state which control the JoyRide component
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

    useEffect(() => {
        if (!tourContextState) {
            localStorage.setItem("tourPhase", '1')
            dispatch({type: "START"});
        }

        function checkTourState() {
            const item = localStorage.getItem("tour")

            if (!item) {
                localStorage.setItem("tourPhase", '1')
                dispatch({type: "START"});
            }
        }
        /* localStorage dependency doesn't work like normal
           need a listener to check in case someone selects 'reset tour'
           from home screen. */
        window.addEventListener('storage', checkTourState)

        return () => {
            window.removeEventListener('storage', checkTourState)
        }

    }, [tourContextState]);

    const callback = data => {
        const { action, index, type, status } = data;

        if (
            // If close button clicked, then close the tour
            action === ACTIONS.CLOSE ||
            // If skipped or end tour, then close the tour
            (status === STATUS.SKIPPED && tourState.run) ||
            status === STATUS.FINISHED
        ) {
            setTourViewed(status, true);
            dispatch({ type: "STOP" });
            setTourContextState(1)
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            if (index === 0) {
                let pushHistoryPromise = new Promise(function(resolve, reject) {
                    if (type === EVENTS.STEP_AFTER) {
                        history.push('/library')
                        resolve()
                    }

                })

                pushHistoryPromise.then(() =>
                    dispatch({
                        type: "NEXT_OR_PREV",
                        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
                    })
                )
            }
            else (
                dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
                })
            )

            // Check whether next or back button click and update the step.
            dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
            })
        }
    };

    const startTour = () => {
        // Start the tour manually
        dispatch({ type: "RESTART" });
    };

    return (
        <JoyRide
            {...tourState}
            callback={callback}
            showSkipButton={true}
            styles={{
                tooltipContainer: {
                    textAlign: "left"
                },
                buttonBack: {
                    marginRight: 10
                }
            }}
            locale={{
                last: "Got It"
            }}
        />
    );
};

export default Tour;