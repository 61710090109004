import {useDispatch} from "react-redux";
import {message} from "antd";


export const ErrorMessage = (props) => {
    let {error, action } = props

    let dispatch = useDispatch()

    function reset() {
        dispatch({type: action, payload: {}})
    }

    if (!error) { return null}
    return  message.error(error, 2, reset)

}

export default ErrorMessage


