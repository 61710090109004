import React, {useState} from 'react'
import {Modal, Form, Spin, Input, Button, message, Select} from 'antd'
import episodeService from "../api/episodeService";
import { useSmartState } from 'components/Hooks'

import 'pages/private/modal.less'
import {useSelector} from "react-redux";
import {createSelector} from "reselect";


function available(episode) {
    return episode.status === null
}

const episodesSelector = state => state.episodeState.episodes
const availableEpisodesSelector = state => state.episodeState.availableEpisodes

const EpisodeCreateModal = (props) => {

    const { episode, visible, onSuccess, onCancel } = props
    const [form] = Form.useForm();
    const [episodeInfo, setEpisodeInfo] = useSmartState(null)

    const onSelectEpisode = (episode, value) => {
        setEpisodeInfo({title: value.children, id: episode})
    }

    const onClearEpisode = (episode, value) => {
        setEpisodeInfo(null)
    }

    const podcast = useSelector(store => store.podcastState.currentPodcast);

    let availableEpisodes = useSelector(episodesSelector).filter(available)
    if (episode.id) {
        availableEpisodes = []
    }

    function getTitle(values){
        return values.title || episodeInfo?.title
    }

    const onSubmit = () => {
        form.validateFields().then((values) => {
            episodeService.upsert({
                podcast_id: podcast.id,
                id: episode.id || episodeInfo?.id,
                title: getTitle(values),
            }).then((updated) => {
                if (updated.success === true) {
                    message.success(updated.message, 5, onClose)
                    onSuccess(updated.episode)
                } else {
                    message.error(updated.message, 5)
                }
            })
        })
    }

   const onClose = () => {
       message.destroy()
       onCancel()
   }

    const afterClose = () => {
        form.resetFields()
    }

    return <Modal
        className='modal'
        width={400}
        destroyOnClose={true}
        closeable={false}
        visible={visible}
        afterClose={afterClose}
        title={episode.id === null ? 'New In Progress' : `Update ${episode.title}`}
        footer={[
            <Button key="submit" size='large' className='ant-button-modal' type="primary" onClick={() => onSubmit()}>
                {episode.id === null ? 'Create' : 'Update'}
            </Button>,
        ]}
        onCancel={onClose}
    >
        <Form
            form={form}
            size='large'
            layout="vertical"
            name="episodeCreate"
        >
            {/*<Spin size='large' spinning={props.children.isLoading} delay={500}>*/}
            <Form.Item name='title'
                       rules={[{required: !episodeInfo, message: 'Please enter a name for the episode'}]}
                       initialValue={episode.title}>
                <Input
                    className='rounded'
                    allowClear
                    placeholder="Enter the Episode Title (as it appears on Apple Podcasts)"
                />
            </Form.Item>


            {availableEpisodes?.length > 0 && <Form.Item name='availableEpisodes'>
                <Select onSelect={onSelectEpisode} className='rounded'
                        allowClear
                        onClear={onClearEpisode}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        // filterOption={true}
                        placeholder='Or select an existing episode'>
                    {availableEpisodes.map(episode => (
                        <Select.Option key={episode.id}>{episode.title}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            }

            {/*</Spin>*/}

        </Form>
    </Modal>
}

export default EpisodeCreateModal
