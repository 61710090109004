import React from 'react'
import { PrivateRoute } from 'routes'

import UserManagement from './UserManagement'

export const userManagementPaths = {
    userManagement: '/user-management'
};

export const userManagementRoutes = (
    [
        <PrivateRoute
            key={userManagementPaths.userManagement}
            exact
            path={userManagementPaths.userManagement}
            component={UserManagement}
        />
    ]
)