import '../../modal.less'

import React, {useEffect} from 'react'
import {useSelector} from "react-redux";
import {useSmartState} from 'components/Hooks'

import {Modal, Form, Spin, Input, Select, Button, message, Row, Col, Tooltip, Card} from 'antd'

import service from "../api/metaContentService";
import defaultImage from "../../../../assets/default_board.png";
import {fileUploadTypes} from 'pages/helpers/constants'



import {useFileUpload} from 'components/FileUploadHook'


const CONTENT_TYPES = [
    {id: 'LINK', name: 'Link'},
    {id: 'IMAGE_LINK', name: 'Image Link'},
    {id: 'IMAGE_UPLOAD', name: 'Image Upload'},
    // commented until the apps are ready for them
    // {id: 'VIDEO_LINK', name: 'Video Link'},
    // {id: 'VIDEO_UPLOAD', name: 'Video Upload'}
    ]

const selectOptions = () => {
    let options = []
    CONTENT_TYPES.map(function (option) {
        options.push(<Select.Option key={option.id} value={option.id}>{option.name}</Select.Option>)
    })
    return options
}

const hasCaption = (caption) => {
    if (caption === null) {
        return false
    }
}

const isUpload = (file) => {
    return file?.originFileObj
}

const isPreview = (selected) => {
    return ['VIDEO_LINK', 'IMAGE_LINK', 'LINK'].includes(selected)
}

const selectContentById = (state, id) => {
    return state.metaContentState.contents.find(content => content.id === id)
}

const MetaContentModal = (props) => {

    const {boardId, visible, content, onCancel, onSuccess} = props
    const [form] = Form.useForm();

    const [spinning, toggleSpin] = React.useState(false)
    const [selected, setSelected] = useSmartState(null)
    const [previewCompleted, setPreviewCompleted] = React.useState(false)

    const [fileList, setFileList, setAccept, upLoadComponent] = useFileUpload([])
    // either react or the browser is caching the old image , have to do this to work around it
    const [image, setImage] = React.useState(content?.image_url || defaultImage)


    const isUpload = () => {
        if (selected === 'IMAGE_UPLOAD') {
            setAccept(fileUploadTypes.IMAGE.accept)
            return true
        }

        // if (selected === 'VIDEO_UPLOAD') {
        //     setAccept(fileUploadTypes.VIDEO.accept)
        //     return true
        // }
        //
        // return false
    }


    let preview = isPreview(selected)
    const upload = isUpload()


    React.useEffect(() => {
        if (content?.store) {
            setFileList([{
                uid: '-1',
                name: content.store.name,
                status: 'done',
                url: content.store.url
            }])
        }
    }, [content?.store])


    React.useEffect(() => {
        form.setFieldsValue(content)

        if (content) {
            form.setFields([{
                name: 'alternate_image',
                value: content.image_url
            }])
            setSelected(content.content_type);
        }
    }, [form, content?.id])


    form.setFields([{
        name: 'selected',
        value: selected
    }])

    const fetchImage = () => {
        toggleSpin(true)
        let url = form.getFieldValue('alternate_image')
        service.preview(url).then((result) => {
            if (result.success === true) {
                let preview = result.preview
                preview.image = {src: preview?.image?.src || preview.url}


                if (content.image_url !== preview?.image?.src) {
                    content.image_url = preview?.image?.src
                    setImage(content.image_url)
                }

            } else {
                message.error(result.message, 5)
            }
            toggleSpin(false)
        }).catch((info) => {
            console.log('Unable to find the image', info);
            toggleSpin(false)
        });
    }


    const onPreview = () => {
        form.validateFields(['url']).then((values) => {
            toggleSpin(true)
            service.preview(values.url).then((result) => {
                if (result.success === true) {
                    let preview = result.preview
                    if (selected === 'IMAGE_LINK') {
                        preview.image = {src: preview?.image?.src || preview.url}
                    }

                    console.log('alternateImage is ----->', form.getFieldValue('alternate_image'), '<---')
                    if (form.getFieldValue('alternate_image') === null || form.getFieldValue('alternate_image') === '') {
                        console.log('content.image_url -----', content.image_url)
                        console.log('preview?.image?.src -----', preview?.image?.src)
                        if (content.image_url !== preview?.image?.src) {
                            content.image_url = preview?.image?.src
                            setImage(content.image_url)
                        }
                    }


                    content.title = preview.title
                    content.description = preview.description
                    content.video_url = preview.video && preview.video.src
                    content.description = preview.description


                    if (!form.getFieldValue('title')) {
                        form.setFields([{
                            name: 'title',
                            value: content.title
                        }])
                    }

                    if (!form.getFieldValue('content_name')) {
                        form.setFields([{
                            name: 'content_name',
                            value: content.title
                        }])
                    }
                    setPreviewCompleted(true)

                } else {
                    message.error(result.message, 5)
                }

                toggleSpin(false)
            })
        })
            .catch((info) => {
                console.log('Preview Failed:', info);
                toggleSpin(false)
            });

    }

    const onSubmit = () => {
        toggleSpin(true)
        let data = {board_id: boardId}
        form.validateFields().then((values) => {
            data.id = content.id
            data.content_name = values.content_name || values.title
            data.image_url = content.image_url
            data.video_url = values.video_url
            data.description = content.description
            data.title = values.title
            data.content_type = values.selected
            data.url = values.url


            if (isUpload(fileList[0])) {
                service.store(fileList[0], data).then((response) => {
                    if (response.success === true) {
                        message.success(response.message, 5, onClose)
                        onSuccess({...response.content, create: content.create})
                    } else {
                        message.error(response.message, 5)
                    }
                })
            } else {
                service.upsert(data).then((response) => {
                    if (response.success === true) {
                        message.success(response.message, 2, onClose)
                        onSuccess({...response.content, create: content.create})
                    } else {
                        message.error(response.message, 3)
                    }
                })
            }

        })
            .catch((info) => {
                console.log('Preview Failed:', info);
            });
        toggleSpin(false)
    }


    const onClose = () => {
        setFileList([])
        message.destroy()
        onCancel()
    }

    const afterClose = () => {
        form.resetFields()
        setSelected(null)
        toggleSpin(false)
        setFileList([])
    }


    const saveButton = <Button key="submit"
            className='ant-button-modal'
            type="primary"
            style={{borderRadius: '2em'}}
            onClick={() => onSubmit()}>Save</Button>

    return <Modal
        className='modal'
        width={600}
        closable={false}
        visible={visible}
        afterClose={afterClose}
        title={<div><i> {content.create ? 'New Content' : `${content.content_name}`}</i></div>}
        footer={saveButton}
        destroyOnClose={true}
        onCancel={onClose}
    >
        <Form
            form={form}
            size='large'
            layout="horizontal"
            name="contentModal"
        >

            <Spin size='large' spinning={spinning} delay={100}>
                <Row type='flex' align='middle' justify='center'>
                    <Col style={{marginRight: 60, marginLeft: 60}}>
                        <Form.Item name='file' hidden={!upload} rules={[({getFieldValue}) => ({
                            validator(_, value) {
                                if (upload && fileList.length > 0) {
                                    return Promise.resolve();
                                } else if (preview) {
                                    return Promise.resolve();
                                }
                                // Promise.reject('Please upload an image');
                            },
                        }),]}>
                            {upLoadComponent}
                        </Form.Item>
                        <Form.Item name='image_url' hidden={!preview}>
                            <img alt={'preview'} style={{
                                borderRadius: '2em',
                                alignSelf: 'center',
                                width: '90%'
                            }} src={content.image_url || defaultImage}/>
                        </Form.Item>

                    </Col>
                    <Col flex='auto'>
                        <Form.Item name='selected'
                                   rules={[{required: true, message: 'Please select a content type'}]}>
                            <Select className='rounded'
                                    onChange={value => setSelected(value)}
                                    placeholder='Select the Content Type'>
                                {selectOptions()}
                            </Select>
                        </Form.Item>
                        <Form.Item name='url' hidden={!preview}
                                   rules={[{required: preview, message: 'Please enter a valid URL.'}]}>
                            <Input allowClear className='rounded' placeholder="Source URL" onBlur={onPreview}/>
                        </Form.Item>
                        <Tooltip title="If the image obtained by the preview isn't what you wanted, enter a url for an image
                                                and hit the search icon to fetch it" placement='leftBottom'>
                            <Form.Item name='alternate_image'
                                       hidden={!preview || !previewCompleted || 'IMAGE_LINK' === selected}>
                                <Input.Search allowClear className='rounded' onSearch={fetchImage}
                                              placeholder="Alternate ImageComponent"/>
                            </Form.Item>
                        </Tooltip>

                        {/*<Form.Item name='content_name'>*/}
                        {/*    <Input*/}
                        {/*        className='rounded'*/}
                        {/*        allowClear*/}
                        {/*        placeholder="Enter a name for the content"*/}
                        {/*    />*/}
                        {/*</Form.Item>*/}

                        <Form.Item name='title' rules={[{required: hasCaption, message: 'Please enter a caption.'}]}>
                            <Input
                                className='rounded'
                                allowClear
                                placeholder="Caption"
                            />
                        </Form.Item>
                    </Col>

                </Row>
            </Spin>

        </Form>
    </Modal>

}

MetaContentModal.whyDidYouRender = true
export default MetaContentModal
