import React from 'react';
import Tooltip from "antd/es/tooltip";
import {Popconfirm} from 'antd'
import {ReactComponent as DeleteLogo} from "assets/x.svg";
import {ReactComponent as SaveLogo} from "assets/Save_For_Later.svg";
import {ReactComponent as PublishLogo} from "assets/Check.svg";
import {QuestionCircleOutlined} from "@ant-design/icons";
import './TimelineToolbar.styles.css';

const ToolButton = (button) => {
    return <Tooltip title={button.tooltip}>

                    {
                        <div id='button.tooltip'
                             style={{marginLeft: '10px', marginRight: '10px', marginBottom: '30px', background: '#315663', hover: 'opacity: 0.3'}}
                             className='circle2'
                             onClick={button.onClick}
                             data-attr={button.tooltip}
                        >
                            <button.logo className='svg'/>
                        </div>
                    }

            </Tooltip>
}

const confirmPublish = (props) => {
    const { operations } = props

    return(
        <Popconfirm style={ {borderRadius: '2em'} } title='Any unsaved content will be saved before publishing. Do you wish to continue?' okText='Publish'
                    cancelButtonProps={ {style: {borderRadius: '2em'}} }
                    okButtonProps={ {style: {fontSize: '14px'}} }
                    icon={<QuestionCircleOutlined style={{ color: '#315663' }} />}
                    onConfirm={ (e) => { operations.publish() } } >

             <ToolButton logo={PublishLogo} tooltip='Publish' />
        </Popconfirm>
    )
}

const buttons = (props) => {
    const { saved, operations } = props

    // TODO: Will have to test that we no longer need these buttons.
    // return (
    //     [
    //         saved ? < ToolButton logo={PublishLogo} tooltip='Publish' onClick={operations.publish} /> : confirmPublish(props),
    //         < ToolButton logo={SaveLogo} tooltip='Save Your Work' onClick={operations.save} />,
    //         < ToolButton logo={DeleteLogo} tooltip='Reset' onClick={operations.reset}/>
    //     ]
    // )

    return <div>
                { saved ? <ToolButton logo={PublishLogo} tooltip='Publish' onClick={operations.publish} /> : confirmPublish(props) }
                <ToolButton logo={SaveLogo} tooltip='Save Your Work' onClick={operations.save} />
           </div>
}


export const TimelineToolbar = (props) => {
    return <div className='timeline-toolbar-container'>
               { buttons(props) }
           </div>
}






