import React from 'react';

export const Text = ({
    children, size = 'medium',
    color = 'default',
    variant = 'body',
    bold = false,
    leftPadding = false
}) => {
    const fontSize = (size) => {
        switch (size) {
            case 'medium':
                return 12;
            case 'large':
                return 16;
            case 'small':
                return 10;
            case 'tiny':
                return 8;
            default:
                return 12;
        }
    }
    const fontColor = (color) => {
        switch (color) {
            case 'default':
                return '#000000';
            case 'white':
                return '#f8f8f8';
            default:
                return '#0000000';
        }
    }

    const fontWeight = (bold) => {
        return bold ? 700 : 400;
    }

    if (variant === 'title') {
        return (
            <h2 style={{color: `${fontColor(color)}`, fontWeight: fontWeight(bold), paddingLeft: leftPadding ? '16px' : '0px'}}>
                {children}
            </h2>
        )
    }

    return (
        <p style={{fontSize: `${fontSize(size)}px`, textAlign: 'left', color: `${fontColor(color)}`}}>{children}</p>
    )
}