import React from "react";
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Alert from 'antd/es/alert';
import Spin from 'antd/es/spin';
import {MailOutlined} from '@ant-design/icons'
import {useDispatch} from "react-redux";

import {service, userAuthActions} from './index'
import {createStatusSelectors} from 'api/redux/selectors'
import {PublicHeader} from "./Header";


const RequestPasswordForm = (props) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const title = 'Forgot Password'

    const requestPassword = values => {
        form.setFieldsValue({email: values.email})
        dispatch(service.forgotPassword(values))
    };

    return (
        <div style={{height: '100vh', backgroundColor: '#315663'}}>
            <PublicHeader title={title} />
            {<RequestPassword>{props}</RequestPassword>}
        </div>
    );

    function RequestPassword(props) {
         return (
            <Row type='flex' align='top' justify='center' style={{backgroundColor: 'white', minHeight: '60%'}}>
                <Col flex='450px' align={'center'}>

                    <div>
                        <Form size='large' className='public' onFinish={requestPassword} form={form}>
                            <Spin size='large' spinning={props.children.isLoading} delay={500}>
                            <Form.Item>
                                { props.children.success &&
                                    <Alert message="Password Reset Instructions have been mailed to your email address"
                                           type="success" closable className={'alert'} />
                                 }
                                { props.children.error &&
                                <Alert message={props.children.error} type="error" className={'alert'} closable />
                                }

                                <p>Enter the email address that you used to register.
                                    We'll send you an email with your username and a link to reset your password</p>
                            </Form.Item>
                            </Spin>
                            <Form.Item name='email' rules={[
                                {type: 'email', message: 'Please enter a valid email address'},
                                {required: true, message: 'An email address is required.'}
                            ]}>

                                <Input id='email' value='junk' size='large'
                                       className='rounded'
                                       prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                       placeholder="Email"
                                />

                            </Form.Item>
                            <Form.Item>
                                <Button type="link" style={{width: '20%', float: 'left'}} href="/login">Cancel</Button>
                                <Button type="primary" style={{width: '40%', float: 'right'}} htmlType="submit">
                                    Reset
                                </Button>

                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        )
    }
};

export default createStatusSelectors([userAuthActions.forgotPassword], RequestPasswordForm)

