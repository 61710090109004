import React  from 'react'
import {_breadcrumbs} from "components/BreadCrumbs";
import {useSelector} from "react-redux";



const PlanningBoardBreadcrumb =  ({ match }) =>  {
    let board = useSelector(state => state.boardsState.currentBoard);
    return board && board.name
}

export const breadcrumbs = [
    {path: '/library', breadcrumb: 'Planning Library'},
    {path: '/library/boards/:id', breadcrumb: PlanningBoardBreadcrumb}
]

export const Breadcrumbs = () => {
    let Breadcrumbs = _breadcrumbs(breadcrumbs)
    return (
        <Breadcrumbs />
    )
}
