import React from 'react'
import {Modal, Form, Spin, Input, Button, message, Select} from 'antd'
import {MailOutlined, UserAddOutlined, NotificationOutlined} from "@ant-design/icons";
import collaborateService from "../api/collaborateService";

import 'pages/private/modal.less'

const CollaborateModal = ({podcasts =[], visible = false, onCancel, onSuccess}) => {
    const [form] = Form.useForm();
    let spin = false;
    const { Option } = Select;

    const onInvite = () => {
        form.validateFields().then((values) => {
            collaborateService.invite({
                podcast_id: values.podcast,
                name: values.contributor,
                email: values.email
            }).then((invitation) => {
                if (invitation.success === true) {
                    message.success(invitation.message, 5, onClose)
                    onSuccess()
                } else {
                    message.error(invitation.message, 5)
                }
            })
        })
            .catch((info) => {
                console.log('Contributor Validation Failed:', info);
            });
    }

    const podcastOptions =
        podcasts.map((podcast) => {
            return <Option value={podcast.podcastId}>{podcast.podcastTitle}</Option>
        })


    const onClose = () => {
        form.resetFields()
        message.destroy()
        onCancel()
    }

    return <Modal
        className='modal'
        width={400}
        closable={false}
        visible={visible}
        title={<i>Invite a Contributor</i>}
        footer={[
            <Button key="submit" size='large' className='ant-button-modal' type="primary" onClick={() => onInvite()}>
                Invite
            </Button>,
        ]}
        onCancel={onClose}
    >
        <Form
            form={form}
            size='large'
            layout="vertical"
            name="inviteModal"
        >

            <Spin size='large' spinning={spin} delay={500}>
                <Form.Item name='contributor'
                           rules={[{required: true, message: 'Please enter the name of your collobarator'}]}>
                    <Input
                        className='rounded'
                        prefix={<UserAddOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                        placeholder="Name of Contributor"
                    />
                </Form.Item>

                <Form.Item name='email'
                           rules={[{required: true, message: 'Please enter a valid email address.'}]}>
                    <Input
                        className='rounded'
                        prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item name='podcast'
                           rules={[{required: true, message: 'Selection is needed'}]}>
                    <Select
                        className='rounded'
                        placeholder='Select a Podcast'
                    >
                        {podcastOptions}
                    </Select>
                </Form.Item>
            </Spin>

        </Form>
    </Modal>
}

CollaborateModal.whyDidYouRender = true
export default CollaborateModal
