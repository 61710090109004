import React, {useContext} from 'react';
import { Row, Space } from 'antd';
import { Text } from '../shared/Text';
import {TourContext} from "../../pages/private/PrivateLayout";

const IntroCard = () => {
    const { resetTour } = useContext(TourContext)

    const LinkTo = ({ url, label }) => {
        return (
            <a href={`http://${url}`} target='_blank' style={{textDecoration: 'underline', color: '#fff'}}>{label}</a>
        )
    }

    const handleReset = () => {
        resetTour();
        window.location.reload();
    }

    return (
        <Space justify='start' style={{backgroundColor: '#315663'}} direction='vertical'>
            <Row style={{marginTop: '16px'}}>
            <Text variant='title' color='white' bold leftPadding>Introduction</Text>
            <Text color='white' size='large'>
                Vodacast is an interactive podcasting platform, providing podcasters the opportunity to enhance their episodes with rich, digital content feeds meant to compliment the audio.
                Interactive episodes allow podcasters to tell deeper, digital stories and enrich the look and feel of their brands. Podcasters curate digital content to supplement episodes, so fans
                will enjoy immersive storytelling experiences. To restart your app tour, click <a onClick={() => handleReset()} style={{textDecoration: 'underline', color: '#fff'}}>here</a>.
            </Text>
            </Row>
            <Row>
            <Text variant='title' color='white' bold leftPadding>Episode Feeds</Text>
            <Text color='white' size='large'>
                Episode feeds are hand-curated, digital content (e.g. website URLs, videos, images etc.)
                 meant to accompany the podcaster's audio-only episodes and provide listeners deeper audio/digital podcasting experiences.
            </Text>
            </Row>
            <Row>
            <Text variant='title' color='white' bold leftPadding>The Vodacast Hub</Text>
            <Text color='white' size='large'>
                The Hub is Vodacast's admin plaform where podcasters can claim their podcasts, create accounts and start building digital content for episodes.
                 The design of the Hub is intended to help podcasters organize, research and publish content for upcoming episodes.
                <br />
                <br />
                Our goal is to continually upgrade the Hub with new features and tools based on feedback from the podcasting community.
                We welcome any new ideas on how we can improve the admin experience - for support or feature suggestions please reach out to <a href='mailto:support@vodacast.com' style={{textDecoration: 'underline', color: '#fff'}}>support@vodacast.com</a>.
                <br />
                <br />
                You can also join our <LinkTo label='Facebook' url='facebook.com/VodacastPodcasts/' /> group or follow us on <LinkTo label='Twitter' url='twitter.com/vodacastapp' />.
            </Text>
            </Row>
            <Row>
            <Text variant='title' color='white' bold leftPadding>How Does Vodacast Benefit Podcasters?</Text>
            <Text color='white' size='large'>
                Vodacast allows podcasters to create marketing and revenue value through many customary strategies, as well as creating new revenue opportunities.
                <br />
                <br />
                1. Drive additional donations/subscriptions by linking to Patreon sites.
                <br />
                2. Direct traffic to merchandise, paid events, affiliate networks (Amazon etc.).
                <br />
                3. Push fans to your digital brand network through blog posts, social, Youtube channels.
                <br />
                4. Up-sell digital ads to complement your audio ad reads.
                <br />
                5. "Net new" revenue opportunities are coming in the near future.
                <br />
            </Text>
            </Row>

        </Space>

    )
}

export default IntroCard;