import React, {useEffect, useState} from 'react'
import {Modal, Form, Spin, Input, Button, message, Select} from 'antd'
import '../../modal.less'
import episodeService from "pages/private/podcasts/api/episodeService";
import podcastService from "pages/private/podcasts/api/podcastService";

import {useSelector, useDispatch} from "react-redux";



const EpisodeContentModal = (props) => {

    let user = useSelector(store => store.userState.user)
    const podcasts = useSelector(state => state.podcastState.podcasts);
    const episodes = useSelector(state => state.episodeState.episodes).sort((a,b) => b.time_at - a.time_at);

    const [form] = Form.useForm();

    const {visible, content, onSuccess, onCancel} = props
    const [podcastId, setPodcastId] = useState(null)
    const [episodeId, setEpisodeId] = useState(null)


    const onSelectPodcast = (selected) => {
        if (selected !== podcastId) {
            setPodcastId(selected)
        }
    }
    const onSelectEpisode = (selected, value) => {
        if (selected !== episodeId) {
            setEpisodeId(selected)
        }
    }

    const onClearEpisode = () => {
        if(episodeId) {
            setEpisodeId(null)
        }
    }

    const dispatch = useDispatch()
    useEffect(() => {
        if (podcasts.length === 0) {
            podcastService.fetch(user)
            dispatch(podcastService.fetch(user))
        }
    }, [])

    useEffect(() => {
        if (podcastId) {
            dispatch(episodeService.fetch({id: podcastId}))
        }
    }, [podcastId])

    const onSubmit = () => {
        form.validateFields().then((values) => {
            episodeService.upsert({
                podcast_id: podcastId,
                id: episodeId,
                title: values.title,
                content_ids: content
            }).then((updated) => {
                if (updated.success === true) {
                    message.success(updated.message, 5, onClose)
                    onSuccess(updated.episode)
                } else {
                    message.error(updated.message, 5)
                }
            })
        })
    }

    const onClose = () => {
        message.destroy()
        onCancel()
    }

    const afterClose = () => {
        form.resetFields()
    }

    return <Modal
        className='modal'
        width={400}
        destroyOnClose={true}
        closeable={false}
        visible={visible}
        afterClose={afterClose}
        title='Add Content to a New or Existing Episode'
        footer={[
            <Button key="submit" size='large' className='ant-button-modal' type="primary" onClick={() => onSubmit()}>
                Add
            </Button>,
        ]}
        onCancel={onClose}
    >
        <Form
            form={form}
            size='large'
            layout="vertical"
            name="episodeCreate"
        >
            <Form.Item name='podcast'>
                <Select onChange={onSelectPodcast} className='rounded'
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder='Select a podcast'>
                    {podcasts.map(podcast => (
                        <Select.Option key={podcast.id}>{podcast.title}</Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {podcastId ? (<Form.Item name='episodes'>
                <Select onChange={onSelectEpisode} onClear={onClearEpisode} className='rounded'
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder='Select an existing episode'>
                    {episodes.map(episode => (
                        episode.status ? <Select.Option key={episode.id}>{episode.title}</Select.Option> :
                            <Select.Option style={ {fontStyle: 'italic'}} key={episode.id}>{episode.title}</Select.Option>

                    ))}
                </Select>
            </Form.Item>)
            : null}
            
            {podcastId ? <Form.Item name='title'
                rules= {[{required: !episodeId, message: 'Please add a title'}]}
            >
                <Input
                    className='rounded' allowClear disabled={episodeId}
                    placeholder="New Title (as it appears on Apple Podcast)"
                />
            </Form.Item>
            : null}

        </Form>
    </Modal>
}

EpisodeContentModal.whyDidYouRender = true
export default EpisodeContentModal
