import React from "react";

import {combineReducers, createStore, applyMiddleware} from 'redux'

import thunk from 'redux-thunk'
import logger from 'redux-logger'
import {Provider} from 'react-redux'

import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import {persistStore, persistReducer} from 'redux-persist';
import {PersistGate} from 'redux-persist/lib/integration/react';

import {userState} from '../pages/public/user/index'
import {podcastClaimsState} from 'pages/public/claims/index'
import {podcastCollaborateState}  from 'pages/private/podcasts/index'
import {podcastState, episodeState}  from 'pages/private/podcasts/index'
import {boardsState} from 'pages/private/boards/index'
import {metaContentState} from 'pages/private/boards/index'

import { transactionFeedbackReducers } from '../api/index'
import Routes from "routes/Routes";


// use this one if you want the entire state of the app persisted
// between browser sessions

// const persistConfig = {
//     key: 'root',
//     storage: storage,
//     stateReconciler: autoMergeLevel2
// };

const userStatePersistConfig = {
    key: 'user',
    storage: storage,
    whitelist: ['user'],
    stateReconciler: autoMergeLevel1
};

const episodeConfig = {
    key: 'episode',
    storage: storage,
    whitelist: ['currentEpisode'],
    stateReconciler: autoMergeLevel1
};


const podcastConfig = {
    key: 'podcast',
    storage: storage,
    whitelist: ['currentPodcast', 'recentPodcasts'],
    stateReconciler: autoMergeLevel1
};

const boardConfig = {
    key: 'board',
    storage: storage,
    whitelist: ['currentBoard'],
    stateReconciler: autoMergeLevel1
};

const appReducer = combineReducers({
    // global states for the request
    errorReducer: transactionFeedbackReducers.errorReducer,
    loadingReducer: transactionFeedbackReducers.loadingReducer,
    successReducer: transactionFeedbackReducers.successReducer,
    metaContentState: metaContentState,
    boardsState: persistReducer(boardConfig, boardsState),
    podcastClaimsState: podcastClaimsState,
    podcastCollaborateState: podcastCollaborateState,
    podcastState: persistReducer(podcastConfig, podcastState),
    episodeState: persistReducer(episodeConfig, episodeState),
    userState: persistReducer(userStatePersistConfig, userState)
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_SIGNOUT_SUCCESS') {
        state = undefined
    }
    return appReducer(state, action)
}

const vodacastContext = createStore(rootReducer, {}, applyMiddleware(thunk, logger));


const persistedContext = persistStore(vodacastContext)

const withVodacastProvider = (Provider) => {
    class HOC extends React.Component {
        render() {
            document.title = 'Vodacast'
            return (

                <Provider {...this.props} store={vodacastContext}>
                    <PersistGate loading={null} persistor={persistedContext}>
                        <Routes/>
                    </PersistGate>
                </Provider>
            )
        }
    }

    return HOC;
};

export const VodacastContext = withVodacastProvider(Provider);


