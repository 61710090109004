import React from 'react'
import {List} from 'antd'
import PodcastItem from './PodcastItem'

import podcastService from '../api/podcastService'
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import PodcastListTour from "../../../../components/Tour/PodcastListTour";

const emptyText = {
    emptyText: 'No podcasts identified'
}

const selectPodcastById = (state, id) => {
    return state.podcasts.find(podcast => podcast.id === id)
}

const PodcastList = (props) => {
    let history = useHistory()
    const {podcastIds} = props
    const dispatch = useDispatch();


    const onSelect = (id) => {
        dispatch(podcastService.current(id))
        history.push(`/podcasts/${id}`)
    }

    return (
        <List
            locale={emptyText}
            grid={{gutter: 20}}
            dataSource={podcastIds}
            renderItem={id => (
                <List.Item onClick={() => onSelect(id) }>
                    <PodcastItem key={id} podcastId={id}/>
                </List.Item>
            )}
        />
    )
}


PodcastList.whyDidYouRender = true
export default PodcastList

