import React from 'react'
import {Collapse, Space, Row} from 'antd'
import {PlusOutlined, MinusOutlined} from "@ant-design/icons";
import {Text} from "../../../components/shared/Text";


const {Panel} = Collapse

const CollapsibleCard = ({header, text}) => {
    return (
        <Row>
            <Collapse
                style={{width: '800px', backgroundColor: '#315663'}}
                expandIcon={
                    (({ isActive }) => isActive ? <MinusOutlined style={{color: '#fff'}}/> : <PlusOutlined style={{color: '#fff'}}/>)
                }
                expandIconPosition='right'
                bordered={false}
            >
                <Panel header={<Text variant='title' color='white' bold>{header}</Text>}>
                    {typeof text === 'string' ? (
                        <Text color='white' size='large'>
                            {text}
                        </Text>
                    ): text}
                </Panel>
            </Collapse>
        </Row>
    )
}
const Resources = () => {
    return (
        <>
            <Row>
                <h2><b>Vodacast Hub Guide</b></h2>
            </Row>
            <CollapsibleCard
                header='Inviting Contributors to Shows'
                text=
                    "In the Vodacast Hub, click on 'Users' on the left rail.
                      Select the 'Invite Collaborator' button on the top of the page and enter the contributor's name,
                      email address, and which podcast you'd like to invite them to.
                      They'll receive an email to create an account and join your podcast."
            />
            <Space direction='vertical'><div /></Space>
            <CollapsibleCard
                header='Roles of Contributors'
                text=
                    "Contributors, at this time, have equal admin access to every part of the Hub for the shows they've been granted access to
                     - if you have multiple shows, you'll need to add each contributor individually. We intend to expand the role access
                      architecture over time."
            />
            <Space direction='vertical'><div /></Space>
            <CollapsibleCard
                header='Planning Library and Planning Boards'
                text=
                    {

                        <>
                        <Text color='white' size='large'>
                            A 'Planning Board' is a place to gather digital assets you've collected/researched for upcoming episodes.
                            <br />
                            <br />
                            1. You can name your Planning Boards, i.e. 'Crime Scene' or 'Episode 75: Rattlesnakes', for example.
                            <br />
                            <br />
                            2. Planning Boards warehouse digital images, web links, videos, etc., to be published in future episodes.
                            <br />
                            <br />
                            3. Currently, Planning Boards may not be shared with contributors, but this feature will be added soon.
                            <br />
                            <br />
                            The 'Planning Library' is a place to organize your Planning Boards.
                        </Text>
                        </>
                    }
            />
            <Space direction='vertical'><div /></Space>
            <CollapsibleCard
                header='Building a Planning Board'
                text=
                    "To create a Planning Board, click the Planning Library icon on the left rail - once there,
                     select the plus button at the bottom of the Hub. Once selected, you can name the board
                      and add a description if you'd like."
            />
            <Space direction='vertical'><div /></Space>
            <CollapsibleCard
                header='Adding Content to a Planning Board'
                text=
                    {
                        <>
                        <Text color='white' size='large'>
                            To add content to a Planning Board, simply click on the single plus icon at the bottom of the Hub.
                             The following digital assets are supported in Episode Feeds.
                            <br />
                            <br />
                            1. <b>Links</b> - copy and paste the link of a web URL in the source URL space.
                            <br />
                            2. <b>Image link</b> - images embedded in URLs are supported by right clicking the image
                             on your browser, selecting 'open image in a new tab', copy the image URL, paste into the source
                             URL space.
                            <br />
                            3. <b>Image files</b> - upload images to the Hub, but it's important that you own the copyright
                             for digital distribution.
                            <br />
                            <br />
                            <b>Previewing assets</b> - once you've pasted a URL into the source URL field, you can preview
                             the content by clicking the 'Preview' button. The Hub will fetch the header image from the URL
                             along with the headline. Note – in order for the content to be saved properly, you must hit the Preview button first.
                            <br />
                            <br />
                            <b>Editing assets</b> - feel free to update headlines or add images if you choose - to add new
                             images, simply right click a new image from your browser, select 'open image in a new tab', copy
                             the image URL, paste into the Alternate Image field, then click the magnifying glass on the right.
                             That will preview the content with the new image.
                            <br />
                            <br />
                            <b>Sites without images</b> - some websites may be all text with no images. If this is the case,
                             the Preview function won't fetch any images, and you'll need to supply your own image link
                             in the Alternate Image field as mentioned above. After you add the Alternate Image URL, click
                             the magnifying glass to the right to pull in the Alternate Image.
                        </Text>
                        </>
                    }
            />
            <Space direction='vertical'><div /></Space>
            <CollapsibleCard
                header='Pushing Content from a Planning Board to an Episode Board'
                text=
                    {
                        <>
                        <Text color='white' size='large'>
                            To add content to an Episode Board
                            <br />
                            1. Tap the right icon on a Planning Board. {/* image here */}
                            <br />
                            2. Select the episode from the drop down.
                            <br />
                            3. The content selected will be added to the Episode Board.
                            <br />
                            <br />
                            If you'd like to add content to an Episode Board that hasn't been published on your
                             RSS feed, you'll need to create an episode in the Hub.
                            <br />
                            <br />
                            1. Click the microphone on the left rail.
                            <br />
                            2. Select the podcast show.
                            <br />
                            3. Tap the plus button at the bottom. {/* image here */}
                            <br />
                            4. Enter in the name of the episode as it will appear in the RSS feed when it's published.
                            <br />
                            <br />
                            When new episodes are published on your RSS feed, the Hub will try to match the Episode Board
                             content with the recently published episode - it's important the episode
                             title <b>matches exactly</b> with the title of the board in the Hub.
                        </Text>
                        </>
                    }
            />
            <Space direction='vertical'><div /></Space>
            <CollapsibleCard
                header='Creating an Episode Feed'
                text=
                    {
                        <>
                        <Text color='white' size='large'>
                            <b>Drag & Drop</b>
                            <br />
                            1. Drag individual pieces of content to the Episode Feed (timeline).
                            <br />
                            2. Once content has been added to the feed, 'hover' over your entries, then
                             click the 'time' button to edit their place in the timeline.
                            <br />
                            <br />
                            <i>Publishing tip: use the selector to adjust hours, minutes and seconds, or you
                             can click the 'time' button to manually edit.</i>
                            <br />
                            <br />
                            <b>Editing Episode Boards</b>
                            <br />
                            1. Select the edit button.
                            <br />
                            2. At the bottom of the content preview, add the time, click OK and hit save.
                            <br />
                            3. The content will migrate to the Episode Feed (timeline) at the time(s) you've selected.
                            <br />
                            <br />
                            <b>Publishing Episode Feeds</b>
                            <br />
                            Once you've completed an Episode Feed, click the circle with the check mark.
                             If the name of the episode matches the name of a newly published episode in your RSS feed,
                             the Hub will connect the feed to the podcast episode. If the name differs, a drop-down menu
                             will appear, and you can assign the Episode Feed to the RSS episode. At this time, the Hub
                             does not auto-match an Episode Feed to the episode in the RSS. Once the episode is live in
                             your RSS feed, you’ll need to sign into the Hub and publish the episode. This
                             auto-publishing feature will be coming in the future.
                            <br />
                            <br />
                            <b>Episode Links</b>
                            <br />
                            Once the Episode Feed has been published, you'll see a link at the top of the page. This
                             'Episode Feed Link' can be published in your show notes, allowing the digital content to
                             be syndicated across other podcast apps.
                        </Text>
                        </>
                    }
            />
            <Space direction='vertical'><div /></Space>
            <CollapsibleCard
                header='Browser Extensions for Online Research (COMING SOON)'
                text=
                    'A Vodacast Browser Extension is coming soon - intended to be a simple and efficient
                     way to research and gather immersive content for your episodes, and will be initially enabled
                      for Google Chrome.'/>
        </>
    )
}

export default Resources