import React, {useState} from 'react'
import {Card, List} from 'antd'
import EpisodeItem from './EpisodeItem'

import service from '../api/episodeService'
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

import _ from 'lodash';
import timeutils from "../../../helpers/timeutils";

const defaultEmptyText = {
    emptyText: 'No episodes In Progress'
}

const EpisodeList = (props) => {
    const {emptyText, episodes, menu, image, classname} = props
    let history = useHistory()
    const dispatch = useDispatch();

    const onSelect = (episode) => {
        dispatch(service.current(episode))
        history.push(`/podcasts/${episode.podcast_id}/episodes/${episode.id}`)
    }

    const sorted = _.orderBy(episodes, ({time_at}) => time_at, ['asc']);

    return (

            <List
                className={classname}
                locale={emptyText && {emptyText: emptyText} || defaultEmptyText}
                grid={{gutter: 20}}
                dataSource={sorted}
                renderItem={item => (
                    <List.Item onClick={() => onSelect(item)}>
                        <EpisodeItem content={{title: item.title,
                            description: timeutils.dateToCreated(item.time_at)}}
                                     menu={menu} item={{...item, image_url: image}}/>
                    </List.Item>
                )}
            />

    )
}

export default EpisodeList

