import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import {useHistory } from "react-router";
import {setTourViewed} from "./helpers";

const TOUR_STEPS = [
    {
        target: ".podcast-list",
        content: "Select your podcast from the list to see your related episodes.",
        // This makes the tour to start automatically without clicking,
        disableBeacon: true,
        disableOverlay: true,
        disableOverlayClose: false,
        hideCloseButton: false,
        spotlightClicks: true,
        placement: "bottom"
    }
];

const INITIAL_STATE = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: false,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "START":
            return { ...state, run: true, ...action.payload };
        case "RESET":
            return { ...state, stepIndex: 0 };
        case "STOP":
            return { ...state, run: false };
        case "NEXT_OR_PREV":
            return { ...state, ...action.payload };
        case "RESTART":
            return {
                ...state,
                stepIndex: 0,
                run: true,
                loading: false,
                key: new Date()
            };
        default:
            return state;
    }
};

// Tour component
const PodcastListTour = () => {
    // Tour state is the state which control the JoyRide component
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

    useEffect(() => {
        // Auto start the tour if the tour is not viewed before
        if (localStorage.getItem("tourPhase") === '3') {
            dispatch({ type: "START" });

        }
    }, []);

    const callback = data => {
        const { action, index, type, status } = data;
        if (
            // If close button clicked, then close the tour
            action === ACTIONS.CLOSE ||
            // If skipped or end tour, then close the tour
            (status === STATUS.SKIPPED && tourState.run) ||
            status === STATUS.FINISHED
        ) {
            setTourViewed();
            dispatch({ type: "STOP" });
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            // Check whether next or back button click and update the step.
            dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
            });
        }
    };

    return (
        <JoyRide
            {...tourState}
            callback={callback}
            showSkipButton={false}
            styles={{
                tooltipContainer: {
                    textAlign: "left"
                },
                buttonBack: {
                    marginRight: 10
                }
            }}
            locale={{
                last: "Got It"
            }}
        />
    );
};

export default PodcastListTour;