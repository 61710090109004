import React from 'react'
import {useDispatch, useSelector, shallowEqual} from "react-redux"
import {Row, Col, Spin} from 'antd'


import podcastActions from './api/podcastState'
import podcastService from './api/podcastService'

import PodcastList from './components/PodcastList'

import {ReactComponent as ClaimLogo} from 'assets/Claim_Podcast.svg'
import {createStatusSelectors} from 'api/redux/selectors'
import useFilter from "pages/common/Filter/Filter.component"
import ErrorMessage from 'pages/common/ErrorMessage'
import {podcastActionTypes} from "./api/podcastState";
import {createSelector} from "reselect";
import {arrayEqual} from 'pages/helpers/utils'
import { Breadcrumbs } from './components/Breadcrumb'
import PodcastListTour from "../../../components/Tour/PodcastListTour";

const podcastSelector = state => state.podcastState.podcasts
const userSelector = state => state.userState.user

const selectPodcastIds = createSelector(
    podcastSelector,
    (podcasts) => podcasts.map(podcast => podcast.id)
)

const authoredPodcasts = createSelector(
    podcastSelector,
    userSelector,
    (podcasts, user) => podcasts.filter(podcast => user.authored_podcast_ids?.includes(podcast.id))
)

const collaboratedPodcasts = createSelector(
    podcastSelector,
    userSelector,
    (podcasts, user) => podcasts.filter(podcast => user.collaborations_ids?.includes(podcast.id))
)

const selectAuthoredPodcastIds = createSelector(
    userSelector,
    (user) => (user.authored_podcast_ids)
)

const selectCollaboratoredPodcastIds = createSelector(
    userSelector,
    (user) => (user.collaborations_ids)
)


const Podcasts = (props) => {
    const user = useSelector(userSelector);
    const podcasts = useSelector(podcastSelector)
    const podcastIds = useSelector(selectPodcastIds, shallowEqual)
    const authors = useSelector(authoredPodcasts, shallowEqual)
    const collaborators = useSelector(collaboratedPodcasts, shallowEqual)

    const authorIds = useSelector(selectAuthoredPodcastIds, shallowEqual)
    const collaboratorIds = useSelector(selectCollaboratoredPodcastIds, shallowEqual)

    const error = useSelector(store => store.podcastState.error);
    const status = useSelector(store => store.podcastState.status);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (status === 'idle') {
            dispatch(podcastService.fetch(user))
        }
    }, []);

    const [filterValue, filterComponent] = useFilter('Filter Podcasts By Title')

    const filterAuthors = () => {
        if (!filterValue) {
            return authorIds
        }
        return authors.filter(podcast => podcast.title.toLowerCase().includes(filterValue)).map(podcast => podcast.id);
    }

    const filterCollaborators = () => {
        if (!filterValue) {
            return collaboratorIds
        }
        return collaborators.filter(podcast => podcast.title.toLowerCase().includes(filterValue)).map(podcast => podcast.id);
    }


    // const onRescind = (invitation) => { dispatch(collaborateService.rescind(invitation.podcast_id, invitation.email))}
    //
    // const collaborateMenu = { delete: onRescind}
    //
    // const onDecline = (invitation) => { dispatch(collaborateService.decline(invitation.podcast_id, invitation.email))}
    //
    // const invitationMenu = { delete: onDecline}

    return (
        <>
            <PodcastListTour />
            <div style={{margin: '10px', height: '100vh'}}>
                <div style={{padding: '10px'}}>
                    <Breadcrumbs />
                </div>
                <Row>
                    <Col flex='auto'>
                        <div style={{display: 'none', float: 'right'}}>
                            <ClaimLogo className="svg"/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={{textAlign: 'left', fontSize: '16px'}}>My Podcasts</p>
                        <p style={{textAlign: 'left'}}>
                            {filterComponent}
                        </p>
                        <Spin size='large' spinning={status === 'loading'} delay={200}>
                            {authors?.length > 0 &&  <PodcastList podcastIds={filterAuthors()}/> }
                        </Spin>
                    </Col>
                </Row>

                <Row>
                    <Col className='podcast-list'>
                        <ErrorMessage error={error} action={podcastActionTypes.podcastsRequestClear} />
                        <p style={{textAlign: 'left', fontSize: '16px'}}><i>My Podcast Collaborations</i></p>
                        {collaborators?.length > 0 && <PodcastList podcastIds={filterCollaborators()}/>}
                    </Col>
                </Row>
            </div>
        </>
    )
}

Podcasts.whyDidYouRender = true
export default createStatusSelectors([podcastActions.podcastsFetch], Podcasts)
