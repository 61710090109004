import operations from 'api/superAgentWrapper'
import { serverError } from 'api/service'
import { storeToken } from 'api/token.js'



async function invite (invitation) {
        try {
        const res = await operations.post(`admin/boards/invite`, {board_id: invitation.board_id, email: invitation.email, name: invitation.name})
        storeToken(res);
        return { success: true, message: 'The invitation has been sent' }
    } catch (error) {
        console.log ("'We were unable to send the invitation ", error)
        let errorState = serverError(error, 'We were unable to send the invitation.');
        return { success: false, message: errorState.message }

    }
};

export default {invite};