import React, {useEffect, useState} from 'react';
import {
    Route, Redirect
} from 'react-router-dom';
import {userPaths} from '../pages/public/user';
import {getValidToken} from 'api/token';


const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        isAuthenticated(props) === true ? <Component {...props} /> : <Redirect to={{ pathname: userPaths.login, state: { from: props.location }}}/>
    )}/>

);

const isAuthenticated = (props) => {
    let authenticated = getValidToken() !== null;

    if(!authenticated) {
        console.log('Not Authenticated = ', authenticated, props)
    }
    return authenticated;
}

export default PrivateRoute;