import operations from 'api/superAgentWrapper';
import {serverError} from "../../../../api/service";
import {storeToken} from "../../../../api/token";

async function update(pwd) {
    try {
        let res = await operations.put(`admin/update_password`, pwd);
        storeToken(res);
        return { success: true, message: 'The profile has been updated'};
    } catch (error) {
        let errorState = serverError(error, 'We were unable to update your profile.');
        return { success: false, message: errorState.message };
    }
};

async function optIn(userId, user) {
    try {
        let res = await operations.put(`admin/admin_users/${userId}`, user);
        storeToken(res);
        return { success: true, message: 'Your preferences has been updated', user: res.body};
    } catch (error) {
        let errorState = serverError(error, 'We were unable to update your profile.');
        return { success: false, message: errorState.message };
    }
};

export default {
    update,
    optIn
};