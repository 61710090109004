import React from 'react'
import EpisodeContentItem from './EpisodeContentItem'
import {List} from "antd";
import {useDrop} from "react-dnd";
import {useDispatch} from "react-redux";
import contentService from "../api/episodeContentService";
import {canDropBackground} from  'pages/helpers/constants'


const emptyText = {
    emptyText: 'No content for this episode'
}

const EpisodeContentList = (props) => {
    const {contents, menu, getPlaybackPosition} = props
    let dispatch = useDispatch()

    const setStartsAt = (item) => {
        dispatch(contentService.edit(item))
    }

    const handleInsert = (item) => {
        let pos = getPlaybackPosition()

        if (pos) {
            setStartsAt({...item, starts_at: Math.floor(pos)})
        } else {
            if (contents.length === 0){
                setStartsAt({...item, starts_at: 0})
            } else {
                let end = contents[contents.length - 1]
                setStartsAt({...item, starts_at: Math.floor(end.starts_at + 1)})
            }
        }
    }

    const [{canDrop, isOver}, drop] = useDrop({
        accept: 'TIMELINE',

        drop: () => ({ source: 'LIST' }),
        hover: (item, monitor) => {},
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver() ,
        }),
    })

    const getBackgroundColor = () => {
        if (isOver) {
            if (canDrop) {
                return canDropBackground;
            }
        } else {
            return "";
        }
    };

    return (
        <div ref={drop}>
            <List locale={emptyText}
                  style={{ margin: '10px', width: "100%", backgroundColor: getBackgroundColor() }}
                  grid={{gutter: 20}}
                  dataSource={contents}
                  renderItem={(item, index) => (
                      <List.Item>
                          <EpisodeContentItem content={{title: item.title || item.content_name}}
                                              item={{...item, source: 'CONTENT'}}
                                              callbacks={{ handleInsert: handleInsert }}
                                              menu={menu}
                                              index={index}
                          />
                      </List.Item>
                  )}
            />
        </div>
    )
}

export default EpisodeContentList

